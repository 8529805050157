import * as React from "react";
import {
  ListRow,
  ListRowTitle,
  ListRowInfo,
  ListRowInfoItem
} from "src/components/UI/List/";
import Button from "src/components/UI/Button/Button";
import { IRootState } from "src/store/reducers";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/pro-solid-svg-icons/faPhone";
import { connect } from "react-redux";
import { handleError } from "src/utils/errorHandler";
import { PhoneCapability, UserFeature } from "src/store/reducers/auth";
import { pickupQueueCall } from "src/store/actions/calls";
import CallDetailCounter, {
  CallDetailCounterType
} from "src/components/CallDetailCounter/CallDetailCounter";
import {
  InfiniteItemComponent,
  InfiniteItemComponentProps
} from "src/utils/InfiniteItemComponent";
import "./QueueDetailsCall.scss";
import Auxi from "src/hoc/Auxi/Auxi";
import { BridgeColor } from "src/utils/consts";

class QueueDetailsCall extends InfiniteItemComponent<
  IQueueDetailsCallProps,
  { visible: boolean }
> {
  state = {
    visible: false
  };

  render() {
    let $content = (
      <div className="queue-details-call__invisible">
        <div className="queue-details-call__invisible-name" />
        <div className="queue-details-call__invisible-buttons" />
      </div>
    );
    if (this.state.visible) {
      $content = (
        <Auxi>
          <ListRowTitle number={this.props.number}>
            {this.props.title}
          </ListRowTitle>
          <ListRowInfo>
            <ListRowInfoItem>
              <CallDetailCounter
                callId={this.props.callId}
                type={CallDetailCounterType.sourceDuration}
              />
            </ListRowInfoItem>
            <ListRowInfoItem isButton={true}>
              <Button
                icononly={true}
                small={true}
                color={BridgeColor.gs300}
                onClick={this.answerCall.bind(null, this.props.callId)}
                requiredFeature={UserFeature.pickupqueuecall}
                disabled={
                  this.props.callsIsLoading ||
                  !this.props.hasAnswerCapabilities ||
                  !this.props.online
                }
              >
                <FontAwesomeIcon icon={faPhone} />
              </Button>
            </ListRowInfoItem>
          </ListRowInfo>
        </Auxi>
      );
    }
    return <ListRow id={this.props.elementId}>{$content}</ListRow>;
  }

  private answerCall = (callId: string) => {
    this.props.onPickupQueueCall(this.props.queueId, callId).catch(handleError);
  };
}

interface IQueueDetailsCallComponentProps {
  queueId: string;
  callId: string;
  number: number;
  title: string;
}

interface IQueueDetailsCallProps
  extends IPropsFromState,
    IPropsFromDispatch,
    InfiniteItemComponentProps,
    IQueueDetailsCallComponentProps {}

interface IPropsFromDispatch {
  onPickupQueueCall: (queueId: string, callId: string) => Promise<any>;
}

interface IPropsFromState {
  callsIsLoading: boolean;
  online: boolean;
  hasAnswerCapabilities: boolean;
  elementId: string;
}

const mapStateToProps = (
  state: IRootState,
  componentProps: IQueueDetailsCallComponentProps
): IPropsFromState => {
  return {
    callsIsLoading: state.calls.actionsInProgress > 0,
    hasAnswerCapabilities:
      !!state.auth.phone &&
      state.auth.phone.capabilities.includes(PhoneCapability.answer),
    online: state.window.online,
    elementId: `queue-details-call--${componentProps.queueId}`
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IRootState, void, AnyAction>
): IPropsFromDispatch => {
  return {
    onPickupQueueCall: (queueId: string, callId: string) =>
      dispatch(pickupQueueCall(queueId, callId))
  };
};

export default connect<IPropsFromState, IPropsFromDispatch>(
  mapStateToProps,
  mapDispatchToProps
)(QueueDetailsCall);
