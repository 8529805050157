import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBackspace } from "@fortawesome/pro-solid-svg-icons/faBackspace";
import "./DialerInput.scss";
import { isPurePropsEqual } from "src/utils";

class DialerInput extends React.Component<IDialerInputProps> {
  $inputRef: HTMLDivElement | null;

  shouldComponentUpdate(nextProps: IDialerInputProps) {
    return !this.$inputRef || !isPurePropsEqual(this.props, nextProps);
  }

  componentDidMount() {
    // NOTE: focus input by default
    this.focusInput();
  }

  componentDidUpdate(prevProps: IDialerInputProps) {
    // NOTE: in case user changed value from outside
    // (e.g. dialpad), focus input on change
    if (this.props.value !== prevProps.value) {
      this.focusInput();
    }
  }

  render() {
    const cssClasses = ["dialer-input"];
    if (this.props.dark) {
      cssClasses.push("dialer-input--dark");
    }
    if (!this.props.value) {
      cssClasses.push("dialer-input--empty");
    }
    return (
      <div className={cssClasses.join(" ")}>
        <div className="dialer-input__editable">
          <div
            className="dialer-input__editable-inner"
            contentEditable={true}
            data-placeholder={this.props.placeholder}
            ref={ref => (this.$inputRef = ref)}
            onInput={this.onInputChange}
            onPaste={this.onInputPaste}
            onKeyPress={this.onInputKeypress}
            suppressContentEditableWarning={true}
          >
            {this.props.value}
          </div>
        </div>
        {this.props.value ? (
          <button
            className="dialer-input__clear-btn"
            onClick={this.onInputBackspace}
          >
            <FontAwesomeIcon icon={faBackspace} />
          </button>
        ) : null}
      </div>
    );
  }

  private onInputKeypress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (this.props.disable) {
      e.preventDefault();
      e.stopPropagation();
      return;
    }
    // NOTE: allow digit, #, + and * characters only
    // and don't * and # in the beginning of number
    // (issue: https://gitlab.iperitydev.com/compass/bridge/-/issues/205)
    if (/^[\d+]$/.test(e.key) || (this.props.value && /^[*#]$/.test(e.key))) {
      if (this.props.keypress) {
        this.props.keypress(e.key);
      }
      return;
    }
    e.preventDefault();
    e.stopPropagation();
    if (e.key === "Enter" && this.props.value) {
      this.props.submit();
    }
  };

  private onInputChange = (e: React.ChangeEvent<HTMLDivElement>) => {
    // NOTE: added or since textContent can be null
    this.props.changed(e.target.textContent || "");
  };

  private focusInput = () => {
    if (!this.$inputRef) {
      return;
    }
    this.$inputRef.focus();

    // NOTE: set cursor to the end of text,
    // browser sets it to the front by default
    // on editable elements
    const range = document.createRange();
    const sel = window.getSelection();
    range.selectNodeContents(this.$inputRef);
    range.collapse(false);
    if (sel) {
      sel.removeAllRanges();
      sel.addRange(range);
    }
  };

  private onInputBackspace = () => {
    if (this.props.value) {
      this.props.changed(
        this.props.value.slice(0, this.props.value.length - 1)
      );
    }
  };

  private onInputPaste = (e: React.ClipboardEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (this.props.disablePaste) {
      return;
    }
    const pastedText = e.clipboardData.getData("text/plain");
    const clearNumberParts = pastedText.match(/\d+|\+/g);
    if (!clearNumberParts) {
      return;
    }
    let clearNumber = clearNumberParts.join("");

    // https://gitlab.iperitydev.com/compass/bridge/-/merge_requests/543#note_74367
    // when the number is pasted in the dial pad, not adding any country
    // code unless the number is 10-digit 06-number. Does that make sense
    if (
      !this.props.value &&
      clearNumber.length === 10 &&
      clearNumber.indexOf("06") === 0
    ) {
      clearNumber = `+31${clearNumber.slice(1)}`;
    }
    this.props.changed(this.props.value + clearNumber);
  };
}

interface IDialerInputProps {
  value: string;
  changed: (value: string) => void;
  keypress?: (key: string) => void;
  submit: () => void;
  placeholder?: string;
  dark?: boolean;
  disable?: boolean;
  disablePaste?: boolean;
}

export default DialerInput;
