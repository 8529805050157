import * as React from "react";
import { List, ListEmptyMsg } from "src/components/UI/List/";
import { getCallPointTitle } from "src/utils/call";
import { IRootState } from "src/store/reducers";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { connect } from "react-redux";
import { pickupQueueCall } from "src/store/actions/calls";
import { sortBy, sortNumbersComparator } from "src/utils";
import QueueDetailsCall from "../QueueDetailsCall/QueueDetailsCall";

class QueueDetailsCallsList extends React.Component<
  IQueueDetailsCallsListProps
> {
  shouldComponentUpdate(nextProps: IQueueDetailsCallsListProps) {
    return (
      this.props.queueId !== nextProps.queueId ||
      this.props.className !== nextProps.className ||
      JSON.stringify(this.props.calls) !== JSON.stringify(nextProps.calls)
    );
  }

  render() {
    return (
      <List className={this.props.className}>
        {this.props.calls && this.props.calls.length ? (
          this.props.calls.map((call, idx) => (
            <QueueDetailsCall
              key={call.id}
              callId={call.id}
              queueId={this.props.queueId}
              number={idx + 1}
              title={call.title}
            />
          ))
        ) : (
          <ListEmptyMsg>No callers waiting in this queue.</ListEmptyMsg>
        )}
      </List>
    );
  }
}

interface IQueueDetailsCallsListProps
  extends IPropsFromState,
    IPropsFromDispatch,
    IQueueDetailsCallsListComponentProps {}

interface IQueueDetailsCallsListComponentProps {
  queueId: string;
  className?: string;
}

interface IPropsFromDispatch {
  onPickupQueueCall: (queueId: string, callId: string) => Promise<any>;
}

interface IPropsFromState {
  calls: Array<{
    id: string;
    title: string;
    sourceCreated: number;
  }>;
}

const mapStateToProps = (
  state: IRootState,
  componentProps: IQueueDetailsCallsListComponentProps
): IPropsFromState => {
  const queue = state.queues.items[componentProps.queueId];
  return {
    calls: queue
      ? sortBy(
          queue.getCalls(),
          call => call.source.timeCreated,
          sortNumbersComparator
        ).map(call => ({
          id: call.id,
          title: getCallPointTitle(call.source),
          sourceCreated: call.source.timeCreated
        }))
      : []
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IRootState, void, AnyAction>
): IPropsFromDispatch => {
  return {
    onPickupQueueCall: (queueId: string, callId: string) =>
      dispatch(pickupQueueCall(queueId, callId))
  };
};

export default connect<IPropsFromState, IPropsFromDispatch>(
  mapStateToProps,
  mapDispatchToProps
)(QueueDetailsCallsList);
