import * as React from "react";
import { faLongArrowLeft } from "@fortawesome/pro-regular-svg-icons/faLongArrowLeft";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "src/components/UI/Button/Button";
import "./ContainerHeader.scss";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { TrackCategory, TrackAction } from "src/utils/track";
import { Tooltip } from "../Tooltip/Tooltip";
import { BridgeColor } from "src/utils/consts";

interface IProps {
  title: string;
  titleIcon?: {
    icon: IconDefinition;
    color: string;
  };
  className?: string;
  enableBackBtn?: boolean;
  mobileOnlyBackBtn?: boolean;
  backBtnClicked?: () => void;
  backBtnIcon?: IconDefinition;
  backBtnTrack?: [TrackCategory, TrackAction];
  showTitleTooltip?: boolean;
}

class ContainerHeader extends React.Component<IProps, any> {
  public render() {
    let backBtn: React.ReactNode = null;

    if (this.props.enableBackBtn) {
      const classes = ["container-header__back-btn"];
      if (this.props.mobileOnlyBackBtn) {
        classes.push("container-header__back-btn--mobile-only");
      }
      backBtn = (
        <div className={classes.join(" ")}>
          <Button
            onClick={this.goBack}
            small={true}
            icononly={true}
            fill={"clear"}
            color={BridgeColor.gs800}
            track={this.props.backBtnTrack}
          >
            <FontAwesomeIcon
              icon={
                this.props.backBtnIcon
                  ? this.props.backBtnIcon
                  : faLongArrowLeft
              }
            />
          </Button>
        </div>
      );
    }
    const cssClasses = ["container-header"];
    if (this.props.className) {
      cssClasses.push(this.props.className);
    }

    const $title = (
      <div className="container-header__title-label">{this.props.title}</div>
    );
    return (
      <div className={cssClasses.join(" ")}>
        <div className="container-header__title">
          {backBtn}
          {this.props.titleIcon ? (
            <div
              className={`container-header__title-icon br-color--${
                this.props.titleIcon.color
              }`}
            >
              <FontAwesomeIcon icon={this.props.titleIcon.icon} />
            </div>
          ) : null}
          <Tooltip
            enabled={this.props.showTitleTooltip}
            annotation={true}
            content={this.props.title}
          >
            {$title}
          </Tooltip>
        </div>
        <div className="container-header__content">{this.props.children}</div>
      </div>
    );
  }

  private goBack = () => {
    if (this.props.backBtnClicked) {
      this.props.backBtnClicked();
    }
  };
}

export default ContainerHeader;
