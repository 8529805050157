import * as React from "react";
import Tippy, { TippyProps } from "@tippy.js/react";

const defaultTippyProps: Partial<TippyProps> = {
  enabled: true,
  arrow: true,
  delay: [1000, 0],
  theme: "bridge",
  boundary: "window",
  hideOnClick: true
};

export const Tooltip: React.FC<ITooltipProps> = props => {
  const [forceDisabled, setForceDisabled] = React.useState(false);
  const tippyProps: TippyProps = {
    ...defaultTippyProps,
    ...props,
    enabled: forceDisabled ? false : props.enabled || defaultTippyProps.enabled
  };
  // NOTE: annotation is not tippy param
  // removing it to prevent warnings
  delete (tippyProps as any).annotation;
  if (props.annotation) {
    // NOTE: #455 show tooltip only if text not fully visible
    tippyProps.onTrigger = e => {
      if (
        (e.reference as HTMLElement).offsetWidth ===
          (e.reference as HTMLElement).scrollWidth &&
        e.state.isEnabled
      ) {
        setForceDisabled(true);
        e.disable();
        const mouseLeaveCallback = () => {
          e.reference.removeEventListener("mouseleave", mouseLeaveCallback);
          e.enable();
          setForceDisabled(false);
        };
        e.reference.addEventListener("mouseleave", mouseLeaveCallback);
      }
    };
  }
  // NOTE: If the child element is disabled, it won't generate any events.
  // We wrap all children in a div so we get hover events.
  // https://github.com/atomiks/tippyjs/issues/104
  return (
    <Tippy {...tippyProps}>
      {"disabled" in props.children.props ? (
        <div className="tooltip__disabled-children-wrapper">
          {props.children}
        </div>
      ) : (
        props.children
      )}
    </Tippy>
  );
};

export interface ITooltipProps extends TippyProps {
  annotation?: boolean;
}
