import * as React from "react";
import "./ForwardModal.scss";
import Modal from "../UI/Modal/Modal";
import {
  CallForwardType,
  updateUserIdentity,
  selectCompassPreferences
} from "src/store/preferences";
import { useSelector } from "react-redux";
import { selectAllContacts } from "src/store/reducers/contacts";
import { sortContacts, filterContacts } from "src/utils/contact";
import Radio, { RadioOption } from "../UI/Radio/Radio";
import { BridgeColor } from "src/utils/consts";
import SearchInput from "../UI/SearchInput/SearchInput";
import { replaceAll } from "src/utils";
import InfoMessage from "../UI/InfoMessage/InfoMessage";
import { faExclamationCircle } from "@fortawesome/pro-solid-svg-icons/faExclamationCircle";
import { handleError } from "src/utils/errorHandler";
import { useReduxDispatch } from "src/utils/hooks";

const cleanPhoneNumber = (phoneNumber: string) => {
  let cleanNumber = replaceAll(phoneNumber, " ", "");
  if (cleanNumber[0] === "+") {
    cleanNumber = cleanNumber.replace("+", "00");
  }
  return cleanNumber;
};

const ForwardModal: React.FC<{
  isOpen: boolean;
  onRequestClose: () => void;
  forwardType?: CallForwardType;
}> = React.memo(({ isOpen, onRequestClose, forwardType }) => {
  const allContactsList = sortContacts(
    Object.values(useSelector(selectAllContacts))
  );
  const { identity } = useSelector(selectCompassPreferences);
  const savedPhoneNumber =
    identity && forwardType ? identity[forwardType] : undefined;
  const dispatch = useReduxDispatch();
  const [mode, setMode] = React.useState<"contact" | "number">("contact");
  const [searchQuery, setSearchQuery] = React.useState<string>();
  const [
    selectedPhoneNumberInvalid,
    setSelectedPhoneNumberInvalid
  ] = React.useState<boolean>();
  const [saveInProgress, setSaveInProgress] = React.useState<boolean>(false);
  const [selectedPhoneNumber, setSelectedPhoneNumber] = React.useState<
    string
  >();
  const $phoneNumberInput = React.useRef<HTMLInputElement>(null);
  const onSearchInput = React.useCallback(
    (query: string) => {
      setSearchQuery(query);
    },
    [setSearchQuery]
  );
  const onPhoneNumberInput = React.useCallback(() => {
    if (!$phoneNumberInput.current) {
      return;
    }
    const numberInputValue = $phoneNumberInput.current.value;
    if (numberInputValue && !/^\d+$/.test(cleanPhoneNumber(numberInputValue))) {
      setSelectedPhoneNumberInvalid(true);
      return;
    } else if (setSelectedPhoneNumberInvalid) {
      setSelectedPhoneNumberInvalid(false);
    }
    setSelectedPhoneNumber($phoneNumberInput.current.value);
  }, [
    setSelectedPhoneNumber,
    selectedPhoneNumberInvalid,
    setSelectedPhoneNumberInvalid
  ]);
  const onClose = React.useCallback(() => {
    onRequestClose();
    // Reset after modal gets hidden
    setTimeout(() => {
      setSelectedPhoneNumber(undefined);
      setSelectedPhoneNumberInvalid(false);
      setMode("contact");
    }, 300);
  }, [onRequestClose, setMode]);
  const onSave = React.useCallback(async () => {
    if (!forwardType || !selectedPhoneNumber) {
      return;
    }
    setSaveInProgress(true);
    try {
      const response = await dispatch(
        updateUserIdentity({
          [forwardType]: cleanPhoneNumber(selectedPhoneNumber)
        })
      );
      if (response.type === updateUserIdentity.rejected.type) {
        throw response.payload;
      }
      onClose();
    } catch (error) {
      handleError(error, {
        forceMessage:
          error.error &&
          error.error.responseJSON &&
          error.error.responseJSON.code === 400
            ? "Phone number doesn't seem correct. Make sure you have entered a proper format"
            : undefined
      });
    } finally {
      setSaveInProgress(false);
    }
  }, [dispatch, selectedPhoneNumber, forwardType]);
  const onSetInputNumberMode = React.useCallback(() => {
    setSelectedPhoneNumber("");
    setMode("number");
  }, []);
  let visibleContactsList = allContactsList;
  if (searchQuery && mode === "contact") {
    visibleContactsList = filterContacts(allContactsList, searchQuery);
  }
  const radioOptions: RadioOption[] = [];
  visibleContactsList.forEach(contact =>
    contact.phones.forEach(phoneNumber =>
      radioOptions.push({
        name: contact.name,
        value: phoneNumber.value,
        label: phoneNumber.value
      })
    )
  );
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      showCloseBtn={true}
      buttonsPlacement="right"
      title={`Forward incoming calls to${mode === "contact" ? "" : " number"}`}
      buttons={[
        {
          text: "Cancel",
          color: BridgeColor.gs900,
          clicked: onClose,
          disabled: saveInProgress
        },
        ...(mode === "contact"
          ? [
              {
                text: "Input number",
                color: BridgeColor.gs900,
                clicked: onSetInputNumberMode,
                disabled: saveInProgress
              }
            ]
          : []),
        {
          text:
            mode === "contact" ? "Set forward" : "Set forward to this number",
          color: BridgeColor.prim500,
          clicked: onSave,
          disabled:
            saveInProgress || !selectedPhoneNumber || selectedPhoneNumberInvalid
        }
      ]}
    >
      {mode === "contact" ? (
        <>
          <div className="forward-modal__search-wrap">
            <SearchInput
              alwaysOpened={true}
              changed={onSearchInput}
              dark={true}
              expand="full"
            />
          </div>
          <div className="forward-modal__contacts">
            {radioOptions.length ? (
              <Radio
                options={radioOptions}
                onSelect={setSelectedPhoneNumber}
                vertical={true}
                value={selectedPhoneNumber || savedPhoneNumber}
              />
            ) : (
              <div className="forward-modal__contacts-message">
                {!allContactsList.length
                  ? "You currently have no contacts."
                  : `No contacts with "${searchQuery}" found.`}
              </div>
            )}
          </div>
        </>
      ) : (
        <div className="forward-modal__phone-input-wrap">
          <input
            placeholder="Phone number"
            onInput={onPhoneNumberInput}
            ref={$phoneNumberInput}
            data-error={selectedPhoneNumberInvalid}
          />
          {selectedPhoneNumberInvalid ? (
            <InfoMessage level="danger" icon={faExclamationCircle}>
              Phone number can’t contain letters and symbols. Please type
              numbers only.
            </InfoMessage>
          ) : null}
        </div>
      )}
    </Modal>
  );
});

export default ForwardModal;
