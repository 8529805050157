import * as React from "react";
import { connect } from "react-redux";
import { AnyAction } from "redux";
import { IRootState } from "src/store/reducers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbtack } from "@fortawesome/pro-solid-svg-icons/faThumbtack";
import { faChevronUp } from "@fortawesome/pro-solid-svg-icons/faChevronUp";
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons/faChevronDown";
import { faCircle } from "@fortawesome/pro-solid-svg-icons/faCircle";
import { faThumbtack as faThumbtackRegular } from "@fortawesome/pro-regular-svg-icons/faThumbtack";
import Auxi from "src/hoc/Auxi/Auxi";
import Button from "src/components/UI/Button/Button";
import {
  ListRow,
  ListRowTitle,
  ListRowInfo,
  ListRowInfoItem
} from "src/components/UI/List/";
import {
  getUserStatusInfo,
  UserStatus,
  UserStatusInfo,
  getUserStatusDisplay
} from "src/utils/user";
import { ThunkDispatch } from "redux-thunk";
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import "./ContactItem.scss";
import { cssSafeStr } from "src/utils";
import { IContact, ContactType } from "src/store/reducers/contacts";
import { Connection } from "compass.js";
import { dialNumber } from "src/store/actions";
import { handleError } from "src/utils/errorHandler";
import { Tooltip } from "../UI/Tooltip/Tooltip";
import { TrackCategory, TrackAction, trackEvent } from "src/utils/track";
import {
  InfiniteItemComponent,
  InfiniteItemComponentProps
} from "src/utils/InfiniteItemComponent";
import CallDetailCounter, {
  CallDetailCounterType
} from "../CallDetailCounter/CallDetailCounter";
import { pinContact, unpinContact } from "src/store/actions/contacts";
import { BridgeColor } from "src/utils/consts";

const TRACK_CATEGORY = TrackCategory.contactItem;

class ContactItem extends InfiniteItemComponent<
  IContactItemProps,
  IContactItemState
> {
  state = {
    visible: false
  };

  public render() {
    return (
      <Auxi key={this.props.id}>
        <ContextMenuTrigger id={this.props.elementId}>
          <ListRow
            active={this.props.isActive}
            onClick={this.onClick}
            className={this.props.className}
            id={this.props.elementId}
          >
            {this.$getRowContent()}
          </ListRow>
        </ContextMenuTrigger>
        {this.state.visible ? this.$getContextMenu() : null}
      </Auxi>
    );
  }

  private onClick = () => {
    if (this.props.onClick) {
      this.props.onClick(this.props.id);
    }
  };

  private $getRowContent = () => {
    if (!this.state.visible) {
      return (
        <div className="contact-item__invisible">
          <div className="contact-item__invisible-name" />
          <div className="contact-item__invisible-buttons" />
        </div>
      );
    }
    return (
      <Auxi>
        <ListRowTitle>
          {/* TODO: status for address book contacts */}
          <div
            className={`contact-item__status contact-item__status--${this.props
              .status || "logged-out"}`}
          >
            <FontAwesomeIcon icon={faCircle} />
          </div>
          <Tooltip
            enabled={true}
            content={this.props.contactName}
            delay={[1000, 0]}
            annotation={true}
          >
            <span className="list__row-title-text">
              {this.props.contactName}
            </span>
          </Tooltip>
        </ListRowTitle>
        <ListRowInfo>
          {this.props.status &&
          this.props.contactType === ContactType.compass ? (
            <ListRowInfoItem className="contact-item__right-info">
              <div className={"br-text-right br-text-size--s"}>
                {this.props.statusDisplay}
                {this.props.status === UserStatus.inCall &&
                this.props.statusCallId ? (
                  <span>
                    &nbsp;(
                    <CallDetailCounter
                      callId={this.props.statusCallId}
                      userId={this.props.id}
                      type={CallDetailCounterType.userDuration}
                    />
                    )
                  </span>
                ) : null}
              </div>
            </ListRowInfoItem>
          ) : null}
          {this.props.showPinBtn ? (
            <ListRowInfoItem isButton={true}>
              <Tooltip
                placement="top"
                content={
                  this.props.isPinned
                    ? "Unpin contact from the top"
                    : "Pin contact to the top"
                }
              >
                <Button
                  color={BridgeColor.gs800}
                  icononly={true}
                  fill={"clear"}
                  small={true}
                  onClick={this.togglePin}
                  track={[TRACK_CATEGORY, TrackAction.contactItemPinToggle]}
                >
                  <FontAwesomeIcon
                    icon={
                      this.props.isPinned ? faThumbtack : faThumbtackRegular
                    }
                  />
                </Button>
              </Tooltip>
            </ListRowInfoItem>
          ) : null}
          {this.props.buttons}
          {this.props.onDetailsToggle ? (
            <ListRowInfoItem isButton={true} className="br-screen-small">
              <Button
                color={BridgeColor.gs800}
                icononly={true}
                fill={"clear"}
                small={true}
                onClick={this.toggleHandler}
              >
                <FontAwesomeIcon
                  icon={this.props.isActive ? faChevronUp : faChevronDown}
                />
              </Button>
            </ListRowInfoItem>
          ) : null}
        </ListRowInfo>
      </Auxi>
    );
  };

  private toggleHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (this.props.onDetailsToggle) {
      this.props.onDetailsToggle(this.props.id);
    }
  };

  private togglePin = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (e) {
      e.stopPropagation();
    }
    (this.props.isPinned
      ? this.props.onUnpinContact(this.props.id)
      : this.props.onPinContact(this.props.id)
    ).catch(handleError);
  };

  private contextPinToggle = (e: React.MouseEvent<HTMLButtonElement>) => {
    trackEvent(TRACK_CATEGORY, TrackAction.contactItemContextPinToggle);
    this.togglePin(e);
  };

  private contextDialContact = (contact: IContact) => {
    trackEvent(TRACK_CATEGORY, TrackAction.contactItemContextDial);
    if (!this.props.contactFirstPhoneNumber) {
      return;
    }
    this.props
      .onDialNumber(this.props.contactFirstPhoneNumber)
      .catch(handleError);
  };

  private $getContextMenu(): React.ReactNode {
    return (
      <div>
        <ContextMenu id={`contact-${this.props.id}`} rtl={true}>
          {this.props.showPinBtn ? (
            <MenuItem onClick={this.contextPinToggle}>
              {this.props.isPinned ? "Unpin" : "Pin"}
            </MenuItem>
          ) : null}
          <MenuItem
            disabled={!this.props.contactFirstPhoneNumber || !this.props.online}
            onClick={this.contextDialContact}
          >
            Call
          </MenuItem>
        </ContextMenu>
      </div>
    );
  }
}
interface IPropsFromState {
  online: boolean;
  contactType: IContact["type"];
  contactName: string;
  contactFirstPhoneNumber: string | null;
  status: UserStatusInfo["userStatus"] | null;
  statusDisplay: string | null;
  statusCallId: string | null;
  isPinned: boolean;
  elementId: string;
}

interface IPropsFromDispatch {
  onDialNumber: (destination: string) => Promise<any>;
  onPinContact: (contactId: string) => Promise<any>;
  onUnpinContact: (contactId: string) => Promise<any>;
}

interface IContactItemComponentProps {
  type?: "contact" | "agent";
  id: string;
  isActive?: boolean;
  onClick?: (id: IContact["id"]) => void;
  className?: string;
  onDetailsToggle?: (id: IContact["id"]) => void;
  showPinBtn?: boolean;
  buttons?: React.ReactElement[];
}

interface IContactItemProps
  extends IPropsFromState,
    IPropsFromDispatch,
    IContactItemComponentProps,
    InfiniteItemComponentProps {}

interface IContactItemState {
  visible: boolean;
}

const mapStateToProps = (
  state: IRootState,
  componentProps: IContactItemComponentProps
): IPropsFromState => {
  const contact =
    state.contacts.compassItems[componentProps.id] ||
    state.contacts.addressBookItems[componentProps.id];
  let userStatusInfo: UserStatusInfo | null = null;
  if (contact.type === ContactType.compass) {
    const user = (state.auth.connection as Connection).model.users[contact.id];
    if (user) {
      userStatusInfo = getUserStatusInfo(user, {
        considerRingingQueueCalls: componentProps.type === "agent"
      });
    }
  }
  return {
    online: state.window.online,
    contactType: contact.type,
    contactName: contact.name,
    contactFirstPhoneNumber: contact.phones.length
      ? contact.phones[0].value
      : null,
    status: userStatusInfo ? userStatusInfo.userStatus : null,
    statusDisplay: getUserStatusDisplay(componentProps.id, {
      considerRingingQueueCalls: componentProps.type === "agent"
    }),
    statusCallId:
      userStatusInfo && userStatusInfo.call ? userStatusInfo.call.id : null,
    isPinned: state.contacts.pinned.includes(componentProps.id),
    elementId: `contact-row--${cssSafeStr(componentProps.id)}`
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IRootState, void, AnyAction>
): IPropsFromDispatch => {
  return {
    onDialNumber: (destination: string) => dispatch(dialNumber(destination)),
    onPinContact: (contactId: string) => dispatch(pinContact(contactId)),
    onUnpinContact: (contactId: string) => dispatch(unpinContact(contactId))
  };
};

export default connect<IPropsFromState, IPropsFromDispatch>(
  mapStateToProps,
  mapDispatchToProps
)(ContactItem);
