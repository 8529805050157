import * as React from "react";
import Auxi from "src/hoc/Auxi/Auxi";
import Navbar from "src/components/Navbar/Navbar";
import Calls from "src/components/Calls/Calls";
import BottomView from "src/components/BottomView/BottomView";
import { OnboardingModal } from "src/components/OnboardingModal/OnboardingModal";
import LoginPhoneModal, {
  LoginPhoneModalType
} from "src/components/LoginPhoneModal/LoginPhoneModal";
import { connect } from "react-redux";
import { IRootState } from "src/store/reducers";
import "./Home.scss";
import Button from "src/components/UI/Button/Button";
import { BridgeColor } from "src/utils/consts";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import {
  showLoginPhoneModal,
  showOnboardingModal
} from "src/store/actions/auth";
import { userStorage } from "src/utils/userStorage";
import { ONBOARDING_MODAL_SHOWN_KEY } from "src/utils/consts";
import { OnboardingType } from "src/store/reducers/auth";

class Home extends React.PureComponent<IHomeProps> {
  componentDidMount() {
    if (
      this.props.onboardingMode &&
      this.props.onboardingType === OnboardingType.guest
    ) {
      return;
    }
    Promise.all([userStorage.getItem(ONBOARDING_MODAL_SHOWN_KEY)]).then(
      ([onboardingShown]) => {
        if (!onboardingShown) {
          this.props.onShowOnboardingModal();
        } else if (!this.props.hasPhone) {
          this.props.onShowLoginPhoneModal(LoginPhoneModalType.login);
        }
      }
    );
  }

  public render() {
    return (
      <Auxi>
        <Navbar />
        {this.props.isConnected ? (
          <Auxi>
            <Calls />
            <BottomView />
            <OnboardingModal />
            <LoginPhoneModal />
          </Auxi>
        ) : (
          <div className="home__error">
            <div className="home__error-message">Cannot connect to Bridge</div>
            <div className="home__error-btn">
              <Button
                color={BridgeColor.prim500}
                onClick={this.props.onReloadClick}
              >
                Try again
              </Button>
            </div>
          </div>
        )}
      </Auxi>
    );
  }
}

interface IPropsFromState {
  isConnected: boolean;
  hasPhone: boolean;
  onboardingMode: boolean;
  onboardingType?: OnboardingType;
}

interface IPropsFromDispatch {
  onShowLoginPhoneModal: (modalType: LoginPhoneModalType) => void;
  onShowOnboardingModal: () => void;
}

interface IHomeProps extends IPropsFromState, IPropsFromDispatch {
  onReloadClick?: () => void;
}

const mapStateToProps = (state: IRootState): IPropsFromState => {
  return {
    isConnected: !!state.auth.connection,
    hasPhone: !!state.auth.userStatus && !!state.auth.userStatus.phone,
    onboardingMode: state.auth.onboardingMode,
    onboardingType: state.auth.onboardingType
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IRootState, void, AnyAction>
): IPropsFromDispatch => {
  return {
    onShowLoginPhoneModal: (modalType: LoginPhoneModalType) =>
      dispatch(showLoginPhoneModal(modalType)),
    onShowOnboardingModal: () => dispatch(showOnboardingModal())
  };
};

export default connect<IPropsFromState, IPropsFromDispatch>(
  mapStateToProps,
  mapDispatchToProps
)(Home);
