import * as React from "react";
import "./HorizontalScroll.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons/faChevronRight";
import { faChevronLeft } from "@fortawesome/pro-regular-svg-icons/faChevronLeft";
import Auxi from "src/hoc/Auxi/Auxi";

export class HorizontalScroll extends React.Component {
  public $scroll: HTMLDivElement | null;
  public $scrollContent: HTMLDivElement | null;

  public componentWillMount() {
    this.updateScrollBtns();
  }

  public componentDidMount() {
    window.addEventListener("resize", this.updateScrollBtns);
  }

  public componentWillUnmount() {
    window.removeEventListener("resize", this.updateScrollBtns);
  }

  public componentDidUpdate() {
    this.updateScrollBtns();
  }

  public render() {
    return (
      <Auxi>
        <div
          className="scroll"
          ref={el => {
            this.$scroll = el;
          }}
        >
          <div
            className="scroll__content"
            ref={el => {
              this.$scrollContent = el;
            }}
            onScroll={this.updateScrollBtns}
          >
            {this.props.children}
          </div>
          <div
            className="scroll__scroll-btn scroll__scroll-btn--right"
            onClick={this.doScroll.bind(null, 250)}
          >
            <FontAwesomeIcon icon={faChevronRight} />
          </div>
          <div
            className="scroll__scroll-btn scroll__scroll-btn--left"
            onClick={this.doScroll.bind(null, -250)}
          >
            <FontAwesomeIcon icon={faChevronLeft} />
          </div>
        </div>
      </Auxi>
    );
  }

  private updateScrollBtns = () => {
    if (!this.$scrollContent || !this.$scroll) {
      return;
    }
    if (
      this.$scrollContent.scrollLeft + this.$scroll.offsetWidth >=
      this.$scrollContent.scrollWidth
    ) {
      this.$scroll.classList.remove("scroll--right-scroll-visible");
    } else {
      this.$scroll.classList.add("scroll--right-scroll-visible");
    }
    if (this.$scrollContent.scrollLeft) {
      this.$scroll.classList.add("scroll--left-scroll-visible");
    } else {
      this.$scroll.classList.remove("scroll--left-scroll-visible");
    }
  };

  private doScroll = (scrollLeft: number) => {
    if (!this.$scrollContent) {
      return;
    }
    this.$scrollContent.scrollTo({
      left: this.$scrollContent.scrollLeft + scrollLeft,
      behavior: "smooth"
    });
    this.updateScrollBtns();
  };
}
