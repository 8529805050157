import * as React from "react";
import ContainerHeader from "src/components/UI/ContainerHeader/ContainerHeader";
import "./QueueDetails.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH } from "@fortawesome/pro-solid-svg-icons/faEllipsisH";
import { IRootState } from "src/store/reducers";
import { connect } from "react-redux";
import { loginQueue, logoutQueue } from "src/store/actions";
import { User } from "compass.js";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import Segment, { ISegmentProps } from "src/components/UI/Segment/Segment";
import { handleError } from "src/utils/errorHandler";
import { UserPermission } from "src/store/reducers/auth";
import Auxi from "src/hoc/Auxi/Auxi";
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import { WindowSizeType } from "src/store/reducers/window";
import { pinQueue, unpinQueue } from "src/store/actions/queues";
import { TrackCategory, TrackAction, trackEvent } from "src/utils/track";
import { getQueueActiveMembers } from "src/utils/queue";
import QueueDetailCounter, {
  QueueDetailCounterType
} from "../QueueDetailCounter/QueueDetailCounter";
import QueueDetailsCallsList from "./QueueDetailsCallsList/QueueDetailsCallsList";
import QueueDetailsAgentsList from "./QueueDetailsAgentsList/QueueDetailsAgentsList";
import QueueDetailsManageAgents from "./QueueDetailsManageAgents/QueueDetailsManageAgents";
import Button from "../UI/Button/Button";
import SearchInput from "src/components/UI/SearchInput/SearchInput";
import { faSlidersH } from "@fortawesome/pro-regular-svg-icons/faSlidersH";
import { BridgeColor } from "src/utils/consts";

const TRACK_CATEGORY = TrackCategory.queueDetails;

enum OpenedTab {
  agents = "agents",
  callers = "callers"
}

class QueueDetails extends React.PureComponent<
  IQueueDetailsProps,
  IQueueDetailsState
> {
  $searchInput: SearchInput | null;
  state = {
    openedTab: OpenedTab.agents,
    searchQuery: "",
    manageMode: false
  };

  public render() {
    if (!this.props.isQueueAvailable) {
      return null;
    }

    const cssClasses = ["queue-details"];
    if (this.props.isInline) {
      cssClasses.push("queue-details--inline");
    }

    const segmentProps: ISegmentProps = {
      onChange: (value: OpenedTab) => {
        this.openTab(value);
      },
      value: this.state.openedTab,
      items: [
        {
          title: `Agents (${this.props.nonPausedMemebersNumber})`,
          value: OpenedTab.agents
        },
        {
          title: `Callers (${this.props.callsNumber})`,
          value: OpenedTab.callers
        }
      ]
    };
    return (
      <div className={cssClasses.join(" ")}>
        {this.props.hideHeader ? null : (
          <ContainerHeader
            title={this.props.name}
            enableBackBtn={true}
            mobileOnlyBackBtn={true}
            className={"container-header--no-border"}
            showTitleTooltip={true}
          >
            {this.state.openedTab === OpenedTab.agents &&
            this.props.hasWritePermissions ? (
              <div className="container-header-left">
                <SearchInput
                  ref={this.assignSearchInput}
                  changed={this.onSearchInputChanged}
                  value={this.state.searchQuery}
                  disableOutsideClick={true}
                  closeOnClear={true}
                  closedIcon={faSlidersH}
                  tooltip={"Manage agents in queue"}
                  onOpen={this.enableManageMode}
                  onClose={this.disableManageMode}
                />
              </div>
            ) : null}
            <div className="container-header-buttons container-header-buttons--queue-details">
              {this.props.windowSizeType === WindowSizeType.desktop
                ? this.$getManageQueueBtn()
                : null}
            </div>
          </ContainerHeader>
        )}
        <div className="queue-details__segment-wrap">
          <Segment {...segmentProps} />
          {this.props.windowSizeType === WindowSizeType.mobile
            ? this.$getManageQueueBtn()
            : null}
        </div>
        <div className="queue-details__tab-template">
          {this.props.isInline && this.state.manageMode ? (
            <div className="queue-details__inline-search-input">
              <SearchInput
                changed={this.onSearchInputChanged}
                value={this.state.searchQuery}
                disableOutsideClick={true}
                closeOnClear={true}
                alwaysOpened={true}
                tooltip={"Manage agents in queue"}
                onOpen={this.enableManageMode}
                onClose={this.disableManageMode}
              />
            </div>
          ) : (
            this.$getStats()
          )}
          {this.$getTabTemplate()}
        </div>
      </div>
    );
  }

  private assignSearchInput = (searchInput: SearchInput | null) => {
    this.$searchInput = searchInput;
  };

  private toggleSearchInput = () => {
    if (
      !this.props.isInline &&
      this.$searchInput &&
      !this.$searchInput.state.opened
    ) {
      this.$searchInput.toggleInput();
    }
  };

  private enableManageMode = () => {
    this.setState({
      manageMode: true
    });
  };

  private disableManageMode = () => {
    this.setState({
      manageMode: false
    });
  };

  private onSearchInputChanged = (value: string) => {
    this.setState({
      searchQuery: value
    });
  };

  private openTab(openedTab: OpenedTab) {
    if (this.props.onboardingMode) {
      return;
    }
    switch (openedTab) {
      case OpenedTab.agents:
        trackEvent(TRACK_CATEGORY, TrackAction.queueDetailsShowAgents);
        break;
      case OpenedTab.agents:
        trackEvent(TRACK_CATEGORY, TrackAction.queueDetailsShowCallers);
        break;
    }
    this.setState({
      openedTab,
      // NOTE: always disable manage mode on tab change
      manageMode: false
    });
  }

  private $getStats(): React.ReactElement {
    return (
      <div className="queue-details__stats">
        <div className="queue-details__stats-item">
          <div className="queue-details__stats-label">Average waiting</div>
          <div className="queue-details__stats-value">
            <QueueDetailCounter
              id={this.props.id}
              type={QueueDetailCounterType.avgWaiting}
            />
          </div>
        </div>
        <div className="queue-details__stats-item">
          <div className="queue-details__stats-label">Longest waiting</div>
          <div className="queue-details__stats-value">
            <QueueDetailCounter
              id={this.props.id}
              type={QueueDetailCounterType.longestWaiting}
            />
          </div>
        </div>
        <div className="queue-details__stats-item">
          <div className="queue-details__stats-label">Agents</div>
          <div className="queue-details__stats-value">
            <QueueDetailCounter
              id={this.props.id}
              type={QueueDetailCounterType.agents}
            />
          </div>
        </div>
        <div className="queue-details__stats-item">
          <div className="queue-details__stats-label">Callers</div>
          <div className="queue-details__stats-value">
            <QueueDetailCounter
              id={this.props.id}
              type={QueueDetailCounterType.callers}
            />
          </div>
        </div>
      </div>
    );
  }

  private $getTabTemplate(): React.ReactElement {
    switch (this.state.openedTab) {
      case OpenedTab.agents:
        return this.state.manageMode ? (
          <QueueDetailsManageAgents
            queueId={this.props.id}
            query={this.state.searchQuery}
            className="queue-details__manage-agents"
          />
        ) : (
          <QueueDetailsAgentsList
            queueId={this.props.id}
            className="queue-details__tab-list"
            onShowManageAgents={
              this.props.isInline
                ? this.enableManageMode
                : this.toggleSearchInput
            }
          />
        );
      case OpenedTab.callers:
        return (
          <QueueDetailsCallsList
            queueId={this.props.id}
            className="queue-details__tab-list"
          />
        );
    }
  }

  private login = () => {
    this.props.onLoginQueue(this.props.id);
  };

  private logout = () => {
    this.props.onLogoutQueue(this.props.id);
  };

  private togglePin = () => {
    trackEvent(
      TRACK_CATEGORY,
      this.props.isPinned
        ? TrackAction.queueDetailsUnpin
        : TrackAction.queueDetailsPin
    );
    (this.props.isPinned
      ? this.props.onUnpinQueue(this.props.id)
      : this.props.onPinQueue(this.props.id)
    ).catch(handleError);
  };

  private $getManageQueueBtn(): React.ReactNode {
    return (
      <Auxi>
        <ContextMenuTrigger
          id={`queue-manage-${this.props.id}`}
          holdToDisplay={1}
          disable={this.props.onboardingMode}
        >
          <Button
            small={true}
            icononly={true}
            fill={"clear"}
            color={BridgeColor.gs1000}
            disabled={this.props.queuesIsLoading || !this.props.online}
          >
            <FontAwesomeIcon icon={faEllipsisH} />
          </Button>
        </ContextMenuTrigger>
        <ContextMenu id={`queue-manage-${this.props.id}`} rtl={true}>
          <MenuItem
            onClick={
              this.props.isLoggedInUserInQueue ? this.logout : this.login
            }
          >
            {`${this.props.isLoggedInUserInQueue ? "Leave" : "Join"} ${
              this.props.name
            }`}
          </MenuItem>
          <MenuItem onClick={this.togglePin}>
            {`${this.props.isPinned ? "Unpin" : "Pin"} ${this.props.name}`}
          </MenuItem>
          {this.props.hasWritePermissions && this.props.isInline ? (
            <MenuItem onClick={this.enableManageMode}>Manage agents</MenuItem>
          ) : null}
        </ContextMenu>
      </Auxi>
    );
  }
}

interface IQueueDetailsState {
  openedTab: OpenedTab;
  searchQuery: string;
  manageMode: boolean;
}

interface IPropsFromState {
  isQueueAvailable: boolean;
  name: string;
  isLoggedInUserInQueue: boolean;
  queuesIsLoading: boolean;
  callsNumber: number;
  nonPausedMemebersNumber: number;
  online: boolean;
  windowSizeType: WindowSizeType;
  isPinned: boolean;
  hasWritePermissions: boolean;
  onboardingMode: boolean;
}

interface IQueueDetailsProps extends IPropsFromState, IPropsFromDispatch {
  id: string;
  hideHeader?: boolean;
  isInline?: boolean;
}

interface IPropsFromDispatch {
  onLoginQueue: (queueId: string) => Promise<any>;
  onLogoutQueue: (queueId: string) => Promise<any>;
  onPinQueue: (queueId: string) => Promise<any>;
  onUnpinQueue: (queueId: string) => Promise<any>;
}

const mapStateToProps = (
  state: IRootState,
  componentParams: IQueueDetailsProps
): IPropsFromState => {
  const queue = state.queues.items[componentParams.id];
  return {
    onboardingMode: state.auth.onboardingMode,
    isQueueAvailable: !!queue,
    name: queue ? queue.name : "",
    isLoggedInUserInQueue: queue
      ? queue.isUserInQueue((state.auth.user as User).id)
      : false,
    queuesIsLoading: state.queues.actionsInProgress > 0,
    callsNumber: queue ? queue.getCalls().length : 0,
    nonPausedMemebersNumber: queue
      ? getQueueActiveMembers(queue).filter(item => !item.pausedSince).length
      : 0,
    online: state.window.online,
    windowSizeType: state.window.sizeType,
    isPinned: state.queues.pinned.includes(componentParams.id),
    hasWritePermissions:
      state.auth.companyPermission === UserPermission.permWrite
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IRootState, void, AnyAction>
): IPropsFromDispatch => {
  return {
    onLoginQueue: (queueId: string) => dispatch(loginQueue(queueId)),
    onLogoutQueue: (queueId: string) => dispatch(logoutQueue(queueId)),
    onPinQueue: (queueId: string) => dispatch(pinQueue(queueId)),
    onUnpinQueue: (queueId: string) => dispatch(unpinQueue(queueId))
  };
};

export default connect<IPropsFromState, IPropsFromDispatch>(
  mapStateToProps,
  mapDispatchToProps
)(QueueDetails);
