import * as React from "react";
import "./ContactsOverview.scss";
import { connect } from "react-redux";
import { NavigationPage } from "src/store/reducers/navigation";
import { ThunkDispatch } from "redux-thunk";
import { IRootState } from "src/store/reducers";
import { AnyAction } from "redux";
import { navigationSet } from "src/store/actions/navigation";
import ContactsOverviewList from "src/components/ContactsOverviewList/ContactsOverviewList";

class ContactsOverview extends React.Component<IContactsOverviewProps> {
  public render() {
    return (
      <div className="contacts-overview">
        <div className="contacts-overview__header">Contacts overview</div>
        <div className="contacts-overview__list">
          <ContactsOverviewList />
        </div>
      </div>
    );
  }
}

interface IContactsOverviewProps {
  onNavigationSet: (page: NavigationPage, params?: any) => void;
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IRootState, void, AnyAction>
): IContactsOverviewProps => {
  return {
    onNavigationSet: (page: NavigationPage, params?: any) =>
      dispatch(navigationSet(page, params))
  };
};

export default connect<null, IContactsOverviewProps>(
  null,
  mapDispatchToProps
)(ContactsOverview);
