import * as React from "react";
import "./InfoMessage.scss";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const InfoMessage: React.FC<{
  icon: IconDefinition;
  level: "danger" | "warning";
}> = ({ children, icon, level }) => {
  return (
    <div className={`info-message info-message--${level}`}>
      <div className="info-message__icon">
        <FontAwesomeIcon icon={icon} />
      </div>
      <div className="info-message__content">{children}</div>
    </div>
  );
};

export default InfoMessage;
