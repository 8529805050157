import * as React from "react";
import Auxi from "src/hoc/Auxi/Auxi";
import ContainerHeader from "src/components/UI/ContainerHeader/ContainerHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/pro-solid-svg-icons/faUser";
import { faClock } from "@fortawesome/pro-regular-svg-icons/faClock";
import Button from "src/components/UI/Button/Button";
import { connect } from "react-redux";
import { IRootState } from "src/store/reducers";
import { List, ListEmptyMsg } from "src/components/UI/List/";
import "./QueuesList.scss";
import { setAllQueuePausedState } from "src/store/actions";
import {
  homeToggleDialer,
  homeChangeList,
  homeHideDetails,
  homeShowDetails
} from "src/store/actions/navigation";
import {
  IHomePageParams,
  NavigationHomeList
} from "src/store/reducers/navigation";
import { Queue, User, ReceiveCalls } from "compass.js";
import {
  sortByProperty,
  sortIgnoreCaseComparator,
  isPurePropsEqual
} from "src/utils";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import * as stable from "stable";
import QueueItem from "src/components/QueueItem/QueueItem";
import { updateGlobalPause } from "src/store/actions/queues";
import FlipMove from "react-flip-move";
import QueueDetails from "src/components/QueueDetails/QueueDetails";
import { WindowSizeType } from "src/store/reducers/window";
import { IUserPreferences, ViewModeType } from "src/store/preferences";
import { handleError } from "src/utils/errorHandler";
import { OnboardingStepId } from "src/utils/OnboardingStep";
import { TrackCategory, TrackAction } from "src/utils/track";
import { wrapOnboarding } from "src/utils/onboarding";
import {
  NORMAL_LIST_ITEM_HEIGHT,
  COMPACT_LIST_ITEM_HEIGHT,
  INFINITE_LIST_BREAKPOINT,
  BridgeColor
} from "src/utils/consts";
import * as Infinite from "react-infinite";

const TRACK_CATEGORY = TrackCategory.queueList;

class QueuesList extends React.Component<IQueuesListProps> {
  private $list: HTMLDivElement | null = null;
  private $listWrapper: HTMLDivElement | null = null;
  private $activeItemWrapper: HTMLDivElement | null;

  // NOTE: keeps active contact item element on which
  // scrollIntoView method was already called to prevent
  // multiple scroll calling
  private lastScrollPos: number | null;

  public shouldComponentUpdate(nextProps: IQueuesListProps) {
    if (!isPurePropsEqual(this.props, nextProps)) {
      return true;
    }
    // NOTE: number of queues changed
    if (this.props.queues.length !== nextProps.queues.length) {
      return true;
    }
    // NOTE: order changed
    return !!this.props.queues.find(
      (queue, idx) => queue.id !== nextProps.queues[idx].id
    );
  }

  public componentDidUpdate() {
    this.scrollDetailsUpForMobile();
  }

  public componentDidMount() {
    this.scrollDetailsUpForMobile();
    // NOTE: to render infinite list properly we need to render
    // wrapper element first, to get exact height
    if (this.props.useInfiniteList) {
      this.forceUpdate();
    }
  }

  public render() {
    // NOTE: close details when item got removed
    if (
      this.props.openedQueue &&
      !this.props.queues.find(queue => this.props.openedQueue === queue.id)
    ) {
      this.props.onNavigationHideDetails();
    }

    const currentlyOpenedQueue = this.props.queues.find(
      queue => queue.id === this.props.openedQueue
    );
    const queuesWrapCssClasses = ["queues-list-wrap"];
    if (currentlyOpenedQueue) {
      queuesWrapCssClasses.push("queues-list-wrap--opened-details");
    }
    if (this.props.useInfiniteList) {
      queuesWrapCssClasses.push("queues-list-wrap--infinite");
    }
    return (
      <Auxi>
        <ContainerHeader
          title={"Queues"}
          enableBackBtn={
            this.props.defaultHomeList !== NavigationHomeList.queues &&
            !this.props.onboardingMode
          }
          backBtnClicked={this.openContacts}
          className={
            !!currentlyOpenedQueue
              ? "container-header--no-mobile-border"
              : undefined
          }
          backBtnTrack={[TRACK_CATEGORY, TrackAction.queueListGoBack]}
        >
          <div className="container-header-left">
            <Button
              small={true}
              icononly={true}
              onClick={this.toggleGlobalPause}
              disabled={this.props.queuesIsLoading || !this.props.online}
              color={
                this.props.globalPause ? BridgeColor.red500 : BridgeColor.gs300
              }
              tooltip={
                this.props.globalPause
                  ? "Resume receiving calls in all queues"
                  : "Pause yourself in all queues"
              }
              track={[
                TRACK_CATEGORY,
                this.props.globalPause
                  ? TrackAction.queueListDisableGlobalPause
                  : TrackAction.queueListEnableGlobalPause
              ]}
            >
              <FontAwesomeIcon icon={faClock} />
            </Button>
          </div>
          <div className="container-header-buttons">
            <div className="container-header-buttons__queues-wrap">
              {this.props.defaultHomeList === NavigationHomeList.queues ||
              this.props.onboardingMode ? (
                <Button
                  small={true}
                  icononly={true}
                  onClick={this.openContacts}
                  color={BridgeColor.gs300}
                  tooltip={"Contacts"}
                  track={[TRACK_CATEGORY, TrackAction.queueListOpenContacts]}
                >
                  <FontAwesomeIcon icon={faUser} />
                </Button>
              ) : null}
            </div>
          </div>
        </ContainerHeader>
        <div
          className={queuesWrapCssClasses.join(" ")}
          ref={el => (this.$listWrapper = el)}
        >
          <div className="queues-list" ref={el => (this.$list = el)}>
            <div className="queues-list__inner">
              <List>
                {this.props.queues.length ? (
                  this.$getQueuesListItems(this.props.queues)
                ) : (
                  <ListEmptyMsg>You currently have no queues.</ListEmptyMsg>
                )}
              </List>
            </div>
          </div>
        </div>
      </Auxi>
    );
  }

  private scrollDetailsUpForMobile() {
    // NOTE: remove lastItemScrollTo every time when user resizes
    // window from mobile => desktop, to be able scroll beck when window
    // gets smaller again
    if (
      this.lastScrollPos &&
      (this.props.windowSizeType !== WindowSizeType.mobile ||
        !this.props.openedQueue)
    ) {
      this.lastScrollPos = null;
    }

    if (
      !this.props.openedQueue ||
      this.props.windowSizeType !== WindowSizeType.mobile ||
      !this.$listWrapper ||
      !this.$list
    ) {
      return;
    }
    const activeItemIdx = this.props.queues.findIndex(
      item => item.id === this.props.openedQueue
    );
    if (activeItemIdx < 0) {
      return;
    }
    // NOTE: for infinite we're not sure that $activeItemWrapper rendered
    // so scrolling to it based on calculated position
    const scrollPosition =
        activeItemIdx * this.getItemHeight() + 15 /* list padding */;
    if (this.lastScrollPos === scrollPosition) {
      return;
    }
    if (this.$activeItemWrapper) {
      this.$activeItemWrapper.scrollIntoView({
        // NOTE: remove scroll animation if
        // details active and pin was toggled or list got reordered
        behavior:
          this.lastScrollPos && this.lastScrollPos !== scrollPosition
            ? "auto"
            : "smooth",
        block: "start"
      });
      this.lastScrollPos = scrollPosition;
    } else if (this.props.useInfiniteList) {
      const $infiniteList = this.$listWrapper.querySelector(
        ".queues-list-infinite"
      );
      if ($infiniteList) {
        $infiniteList.scrollTo(0, scrollPosition);
        this.lastScrollPos = scrollPosition;
      }
    }
  }

  private $getQueuesListItems(queues: Queue[]): React.ReactNode {
    const $items = queues.map((queue, idx) => {
      const isItemActive = this.props.openedQueue === queue.id;
      const wrapperCssClasses = ["queues-list__item-wrapper"];
      if (isItemActive) {
        wrapperCssClasses.push("queues-list__item-wrapper--active");
      }
      const $queueItem = (
        <div
          key={queue.id}
          ref={
            isItemActive
              ? el => {
                  this.$activeItemWrapper = el;
                }
              : null
          }
        >
          <QueueItem
            id={queue.id}
            onClick={this.onQueueItemClick.bind(null, queue)}
            onDetailsToggle={this.toggleDetails.bind(null, queue)}
            isActive={isItemActive}
            disableSkeleton={isItemActive}
          />
        </div>
      );
      return (
        <div key={queue.id} className={wrapperCssClasses.join(" ")}>
          {wrapOnboarding(
            $queueItem,
            OnboardingStepId.sectionQueues,
            { placement: "bottom", arrow: false },
            idx === queues.length - 1
          )}
          {isItemActive &&
          this.props.windowSizeType === WindowSizeType.mobile ? (
            <div className="queues-list__details-wrap br-screen-small">
              <QueueDetails id={queue.id} hideHeader={true} isInline={true} />
            </div>
          ) : null}
        </div>
      );
    });
    if (!this.props.useInfiniteList) {
      return (
        <FlipMove
          enterAnimation="none"
          leaveAnimation="none"
          disableAllAnimations={!!this.props.openedQueue}
        >
          {$items}
        </FlipMove>
      );
    }
    if (!this.$list) {
      return null;
    }
    return (
      <Infinite
        containerHeight={this.$list.clientHeight}
        elementHeight={this.getItemHeight()}
        preloadAdditionalHeight={Infinite.containerHeightScaleFactor(2)}
        className="queues-list-infinite"
      >
        {$items}
      </Infinite>
    );
  }

  private getItemHeight() {
    return this.props.viewMode === ViewModeType.comfortable
      ? NORMAL_LIST_ITEM_HEIGHT
      : COMPACT_LIST_ITEM_HEIGHT;
  }

  private onQueueItemClick = (queue: Queue) => {
    if (this.props.onboardingMode) {
      return;
    }
    this.props.windowSizeType === WindowSizeType.mobile
      ? this.toggleDetails(queue)
      : this.props.onNavigationShowDetails(queue.id);
  };

  private toggleDetails = (queue: Queue) => {
    if (!!this.props.openedQueue) {
      this.closeDetails();
    } else {
      this.props.onNavigationShowDetails(queue.id);
    }
  };

  private closeDetails = () => {
    this.props.onNavigationHideDetails();
    // NOTE: hack, due to browser not updating scroll
    // props when items from bottom of the list got closed
    setTimeout(() => {
      const $list = this.$list as HTMLDivElement;
      $list.scrollTo($list.scrollLeft, $list.scrollTop - 1);
      $list.scrollTo($list.scrollLeft, $list.scrollTop + 1);
    }, 100);
  };

  private toggleGlobalPause = () => {
    if (this.props.apiVersion && this.props.apiVersion === 2) {
      const loggedInQueues = this.props.queues.filter(queue => {
        return queue.isUserInQueue(this.props.userId);
      });
      if (!loggedInQueues.length) {
        return this.props.onUpdateGlobalPause(!this.props.globalPause);
      }
    }
    this.props
      .onSetAllQueuePausedState(!this.props.globalPause)
      .catch(handleError);
  };

  private openContacts = () => {
    this.props.onNavigationChangeList(NavigationHomeList.contacts);
  };
}

interface IPropsFromState {
  queues: Queue[];
  globalPause: boolean;
  openedQueue?: string;
  userId: string;
  pinnedQueues: string[];
  queuesIsLoading: boolean;
  defaultHomeList: IUserPreferences["defaultHomeList"];
  viewMode: IUserPreferences["viewMode"];
  windowSizeType: WindowSizeType;
  online: boolean;
  onboardingMode: boolean;
  useInfiniteList: boolean;
  windowHeight?: number;
  apiVersion?: number;
}

interface IPropsFromDispatch {
  onNavigationToggleDialer: () => void;
  onNavigationChangeList: (list: NavigationHomeList) => void;
  onNavigationShowDetails: (id: string) => void;
  onNavigationHideDetails: () => void;
  onSetAllQueuePausedState: (targetPausedState: boolean) => Promise<any>;
  onUpdateGlobalPause: (val: boolean) => void;
}

interface IQueuesListProps extends IPropsFromState, IPropsFromDispatch {}

const mapStateToProps = (state: IRootState): IPropsFromState => {
  const navigationParams = state.navigation.params as IHomePageParams;
  let queues: Queue[] = sortByProperty<Queue, string>(
    Object.values(state.queues.items),
    "name",
    sortIgnoreCaseComparator
  );
  queues = stable(queues, (a: Queue, b: Queue) => {
    const aPinned = !!state.queues.pinned.find(item => item === a.id);
    const bPinned = !!state.queues.pinned.find(item => item === b.id);
    if (!aPinned && bPinned) {
      return 1;
    } else if (aPinned && !bPinned) {
      return -1;
    }
    return 0;
  });
  const useInfiniteList =
    Object.keys(state.queues.items).length > INFINITE_LIST_BREAKPOINT;
  const user = state.auth.user as User;
  return {
    queues,
    apiVersion: state.auth.apiVersion,
    globalPause:
      state.auth.apiVersion && state.auth.apiVersion >= 3
        ? user.status.receiveCalls === ReceiveCalls.onlyDirect
        : state.queues.v2GlobalPause,
    openedQueue:
      navigationParams.detailsOpened && navigationParams.detailsId
        ? navigationParams.detailsId.toString()
        : undefined,
    userId: (state.auth.user as User).id,
    pinnedQueues: state.queues.pinned,
    queuesIsLoading: state.queues.actionsInProgress > 0,
    defaultHomeList: state.preferences.user.defaultHomeList,
    viewMode: state.preferences.user.viewMode,
    windowSizeType: state.window.sizeType,
    online: state.window.online,
    onboardingMode: state.auth.onboardingMode,
    useInfiniteList,
    // NOTE: it used only to force infinite list height update
    // on window resize
    windowHeight: useInfiniteList ? state.window.bounds.height : undefined
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IRootState, void, AnyAction>
): IPropsFromDispatch => {
  return {
    onNavigationToggleDialer: () => dispatch(homeToggleDialer()),
    onNavigationChangeList: (list: NavigationHomeList) =>
      dispatch(homeChangeList(list)),
    onNavigationShowDetails: (id: string) => dispatch(homeShowDetails(id)),
    onNavigationHideDetails: () => dispatch(homeHideDetails()),
    onSetAllQueuePausedState: (targetPausedState: boolean) =>
      dispatch(setAllQueuePausedState(targetPausedState)),
    onUpdateGlobalPause: (val: boolean) => dispatch(updateGlobalPause(val))
  };
};

export default connect<IPropsFromState, IPropsFromDispatch>(
  mapStateToProps,
  mapDispatchToProps
)(QueuesList);
