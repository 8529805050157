import * as React from "react";
import { IRootState } from "src/store/reducers";
import { connect } from "react-redux";
import { isPurePropsEqual } from "src/utils";
import ContactItem from "src/components/ContactItem/ContactItem";
import { QueueMember, Queue, User } from "compass.js";
import { IContact } from "src/store/reducers/contacts";
import "./QueueDetailsManageAgents.scss";
import Button from "src/components/UI/Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-solid-svg-icons/faPlus";
import { faMinus } from "@fortawesome/pro-solid-svg-icons/faMinus";
import { notificationShow, loginQueue, logoutQueue } from "src/store/actions";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { handleError } from "src/utils/errorHandler";
import { sortContacts, filterContacts } from "src/utils/contact";
import {
  NORMAL_LIST_ITEM_HEIGHT,
  COMPACT_LIST_ITEM_HEIGHT,
  BridgeColor
} from "src/utils/consts";
import { ViewModeType } from "src/store/preferences";
import * as Infinite from "react-infinite";
import { getSortedQueueMembers } from "src/utils/queue";

class QueueDetailsManageAgents extends React.Component<
  IQueueDetailsManageAgentsProps
> {
  private $contentWrap: HTMLDivElement | null;

  shouldComponentUpdate(nextProps: IQueueDetailsManageAgentsProps) {
    if (!isPurePropsEqual(this.props, nextProps)) {
      return true;
    }
    // NOTE: number of contacts or agents changed
    if (
      this.props.contacts.length !== nextProps.contacts.length ||
      this.props.agents.length !== nextProps.agents.length
    ) {
      return true;
    }
    // NOTE: order changed
    return (
      !!this.props.contacts.find(
        (contact, idx) => contact.id !== nextProps.contacts[idx].id
      ) ||
      !!this.props.agents.find(
        (agents, idx) => agents.userId !== nextProps.agents[idx].userId
      )
    );
  }

  componentDidMount() {
    // NOTE: to render infinite list properly we need to render
    // wrapper element first, to get exact height
    this.forceUpdate();
  }

  render() {
    const itemHeight =
      this.props.viewMode === ViewModeType.comfortable
        ? NORMAL_LIST_ITEM_HEIGHT
        : COMPACT_LIST_ITEM_HEIGHT;
    return (
      <div
        className={this.props.className}
        ref={el => (this.$contentWrap = el)}
      >
        {this.$contentWrap ? (
          <Infinite
            containerHeight={this.$contentWrap.clientHeight}
            elementHeight={itemHeight}
            preloadAdditionalHeight={Infinite.containerHeightScaleFactor(2)}
          >
            {this.$getListContent()}
          </Infinite>
        ) : null}
      </div>
    );
  }

  private $getListContent() {
    if (this.props.query) {
      return filterContacts(this.props.contacts, this.props.query).map(
        contact =>
          contact.user ? (
            <ContactItem
              key={contact.id}
              id={contact.id}
              buttons={[this.$getLoginBtn(contact.user)]}
            />
          ) : null
      );
    }
    const notLoggedInContacts = this.props.contacts.filter(
      item => !this.props.agents.find(agent => agent.userId === item.id)
    );
    let $elements: React.ReactElement[] = [];
    if (this.props.agents.length) {
      $elements = [
        <div
          className="queue-details-manage-agents__title"
          key="agents-in-queue-title"
        >
          Agents in queue
        </div>,
        ...this.props.agents.map(agent => {
          return (
            <div key={agent.userId}>
              <ContactItem
                id={agent.userId}
                type="agent"
                buttons={[this.$getLoginBtn(agent.getUser())]}
              />
            </div>
          );
        })
      ];
    }
    if (notLoggedInContacts.length) {
      $elements = [
        ...$elements,
        <div
          className="queue-details-manage-agents__title"
          key="agents-not-in-queue-title"
        >
          Agents not in queue
        </div>,
        ...notLoggedInContacts.map(contact => (
          <ContactItem
            id={contact.id}
            key={contact.id}
            buttons={[this.$getLoginBtn(contact.user as User)]}
          />
        ))
      ];
    }
    return $elements;
  }

  private $getLoginBtn(user: User) {
    return this.props.queue.isUserInQueue(user.id) ? (
      <Button
        key="logout-queue"
        icononly={true}
        small={true}
        color={BridgeColor.gs300}
        tooltip={"Remove agent from this queue"}
        disabled={this.props.queuesIsLoading}
        onClick={this.logoutUserFromQueue.bind(null, user)}
      >
        <FontAwesomeIcon icon={faMinus} />
      </Button>
    ) : (
      <Button
        key="login-queue"
        icononly={true}
        small={true}
        color={BridgeColor.gs300}
        tooltip={"Add agent to this queue"}
        disabled={this.props.queuesIsLoading}
        onClick={this.loginUserOnQueue.bind(null, user)}
      >
        <FontAwesomeIcon icon={faPlus} />
      </Button>
    );
  }

  private loginUserOnQueue = async (user: User) => {
    try {
      await this.props.onLoginQueue(this.props.queueId, user.id);
      if (this.props.loggedInUserId !== user.id) {
        this.props.onNotificationShow({
          autoDismiss: 4000,
          message: `${user.name} placed in queue ${this.props.queue.name}`,
          level: "success"
        });
      }
    } catch (error) {
      handleError(error);
    }
  };

  private logoutUserFromQueue = async (user: User) => {
    try {
      await this.props.onLogoutQueue(this.props.queueId, user.id);
      if (this.props.loggedInUserId !== user.id) {
        this.props.onNotificationShow({
          autoDismiss: 4000,
          message: `${user.name} removed from queue ${this.props.queue.name}`,
          level: "success"
        });
      }
    } catch (error) {
      handleError(error);
    }
  };
}

interface IQueueDetailsManageAgentsProps
  extends IPropsFromState,
    IPropsFromDispatch,
    IQueueDetailsManageAgentsOwnProps {}

interface IQueueDetailsManageAgentsOwnProps {
  queueId: string;
  className?: string;
  query?: string;
}

interface IPropsFromState {
  agents: QueueMember[];
  contacts: IContact[];
  queue: Queue;
  queuesIsLoading: boolean;
  viewMode: ViewModeType;
  loggedInUserId: User["id"];
}

interface IPropsFromDispatch {
  onNotificationShow: typeof notificationShow;
  onLoginQueue: (queueId: string, userId?: string) => Promise<any>;
  onLogoutQueue: (queueId: string, userId?: string) => Promise<any>;
}

const mapStateToProps = (
  state: IRootState,
  componentProps: IQueueDetailsManageAgentsOwnProps
): IPropsFromState => {
  return {
    agents: getSortedQueueMembers(state.queues.items[componentProps.queueId]),
    contacts: sortContacts(Object.values(state.contacts.compassItems)),
    queue: state.queues.items[componentProps.queueId],
    queuesIsLoading: state.queues.actionsInProgress > 0,
    viewMode: state.preferences.user.viewMode,
    loggedInUserId: (state.auth.user as User).id
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IRootState, void, AnyAction>
): IPropsFromDispatch => {
  return {
    onNotificationShow: notification =>
      dispatch(notificationShow(notification)),
    onLoginQueue: (queueId: string, userId?: string) =>
      dispatch(loginQueue(queueId, userId)),
    onLogoutQueue: (queueId: string, userId?: string) =>
      dispatch(logoutQueue(queueId, userId))
  };
};

export default connect<IPropsFromState, IPropsFromDispatch>(
  mapStateToProps,
  mapDispatchToProps
)(QueueDetailsManageAgents);
