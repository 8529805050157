import * as React from "react";
import "./InputWrapper.scss";

export const InputWrapper: React.FC<IInputWrapperProps> = props => {
  const cssClasses = ["input-wrapper"];
  if (props.invalid) {
    cssClasses.push("input-wrapper--invalid");
  }
  if (props.type) {
    cssClasses.push(`input-wrapper--${props.type || "input"}`);
  }
  return <div className={cssClasses.join(" ")}>{props.children}</div>;
};

interface IInputWrapperProps {
  invalid?: boolean;
  type?: "checkbox";
}
