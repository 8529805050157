import * as React from "react";
import "./ContactDetails.scss";
import Auxi from "src/hoc/Auxi/Auxi";
import ContainerHeader from "src/components/UI/ContainerHeader/ContainerHeader";
import {
  List,
  ListRow,
  ListRowTitle,
  ListRowInfo,
  ListRowInfoItem,
  ListHeading
} from "src/components/UI/List/";
import { IRootState } from "src/store/reducers";
import { connect } from "react-redux";
import "./ContactDetails.scss";
import { Connection } from "compass.js";
import {
  getUserStatusInfo,
  UserStatusInfo,
  UserStatus,
  getUserStatusDisplay
} from "src/utils/user";
import { faCircle } from "@fortawesome/pro-solid-svg-icons/faCircle";
import { faPhone } from "@fortawesome/pro-solid-svg-icons/faPhone";
import { faEnvelope } from "@fortawesome/pro-solid-svg-icons/faEnvelope";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import Button from "src/components/UI/Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { dialNumber } from "src/store/actions";
import { IContact, ContactType } from "src/store/reducers/contacts";
import { handleError } from "src/utils/errorHandler";
import { TrackAction, TrackCategory } from "src/utils/track";
import CallDetailCounter, {
  CallDetailCounterType
} from "../CallDetailCounter/CallDetailCounter";
import { isPurePropsEqual } from "src/utils";
import { BridgeColor } from "src/utils/consts";
import Segment, { ISegmentProps } from "../UI/Segment/Segment";
import ContactDetailsCalls from "./ContactDetailsCalls/ContactDetailsCalls";

const TRACK_CATEGORY = TrackCategory.contactDetails;

class ContactDetails extends React.Component<
  IContactsDetailsProps,
  IContactsDetailsState
> {
  state = {
    openedTab: OpenedTab.details
  };
  public shouldComponentUpdate(
    nextProps: IContactsDetailsProps,
    nextState: IContactsDetailsState
  ) {
    if (!isPurePropsEqual(this.props, nextProps)) {
      return true;
    }
    return (
      JSON.stringify(this.props.emails) !== JSON.stringify(nextProps.emails) ||
      JSON.stringify(this.props.phones) !== JSON.stringify(nextProps.phones) ||
      this.state.openedTab !== nextState.openedTab
    );
  }

  public render() {
    if (!this.props.isContactAvailable) {
      return null;
    }
    const cssClasses = ["contact-details"];
    if (this.props.inline) {
      cssClasses.push("contact-details--inline");
    }
    const segmentProps: ISegmentProps = {
      onChange: (value: OpenedTab) => {
        this.openTab(value);
      },
      value: this.state.openedTab,
      items: [
        {
          title: "Details",
          value: OpenedTab.details
        },
        {
          title: "Calls",
          value: OpenedTab.calls
        }
      ]
    };

    return (
      <div className={cssClasses.join(" ")}>
        {this.props.hideHeader ? null : (
          // TODO: status color for address book users
          <ContainerHeader
            title={this.props.name}
            enableBackBtn={true}
            mobileOnlyBackBtn={true}
            showTitleTooltip={true}
            className={"container-header--no-border"}
            titleIcon={{
              icon: faCircle,
              color: `${
                this.props.status ? `status-${this.props.status}` : "grey-1"
              }`
            }}
          >
            {this.props.status && this.props.statusDisplay ? (
              <div className="container-header-buttons">
                <div className="contact-details__status">
                  {this.props.statusDisplay}
                  {this.props.status === UserStatus.inCall &&
                  this.props.statusCallId ? (
                    <span>
                      &nbsp;(
                      <CallDetailCounter
                        callId={this.props.statusCallId}
                        userId={this.props.id}
                        type={CallDetailCounterType.userDuration}
                      />
                      )
                    </span>
                  ) : null}
                </div>
              </div>
            ) : null}
          </ContainerHeader>
        )}
        <div className="contact-details__segment-wrap">
          <Segment {...segmentProps} />
        </div>
        <div className="contact-details__tab-template">
          {this.$getTabTemplate()}
        </div>
      </div>
    );
  }

  private $getTabTemplate(): React.ReactNode {
    switch (this.state.openedTab) {
      case OpenedTab.details:
        return this.$getDetailsTemplate();
      case OpenedTab.calls:
        return <ContactDetailsCalls contactId={this.props.id} />;
    }
  }

  private $getDetailsTemplate(): React.ReactNode {
    return (
      <List>
        {this.$getCompanyBlock()}
        {this.$getPhoneBlock()}
        {this.$getEmailBlock()}
      </List>
    );
  }

  private openTab = (openedTab: OpenedTab) => {
    if (this.props.onboardingMode) {
      return;
    }
    this.setState({ openedTab });
  };

  private $getCompanyBlock(): React.ReactNode {
    if (
      !this.props.companyName ||
      this.props.contactType === ContactType.addressBook
    ) {
      return null;
    }
    return (
      <Auxi>
        <ListHeading>Company</ListHeading>
        <ListRow>
          <ListRowTitle>{this.props.companyName}</ListRowTitle>
          <ListRowInfo>
            <ListRowInfoItem>{this.props.companyContact || ""}</ListRowInfoItem>
          </ListRowInfo>
        </ListRow>
      </Auxi>
    );
  }

  private $getPhoneBlock(): React.ReactNode {
    if (!this.props.phones.length) {
      return null;
    }
    return (
      <Auxi>
        <ListHeading>Phone</ListHeading>
        {this.props.phones.map((phone, idx) => (
          <ListRow key={idx}>
            <ListRowTitle>{phone.label}</ListRowTitle>
            <ListRowInfo>
              <ListRowInfoItem>{phone.value}</ListRowInfoItem>
              <ListRowInfoItem isButton={true}>
                <Button
                  icononly={true}
                  small={true}
                  color={BridgeColor.gs300}
                  disabled={this.props.callsIsLoading || !this.props.online}
                  onClick={this.dialNumber.bind(null, phone.value)}
                  track={[TRACK_CATEGORY, TrackAction.contactDetailsDial]}
                >
                  <FontAwesomeIcon icon={faPhone} />
                </Button>
              </ListRowInfoItem>
            </ListRowInfo>
          </ListRow>
        ))}
      </Auxi>
    );
  }

  private dialNumber = (destination: string) => {
    this.props.onDialNumber(destination).catch(handleError);
  };

  private $getEmailBlock(): React.ReactNode {
    if (!this.props.emails.length) {
      return null;
    }
    return (
      <Auxi>
        <ListHeading>Email</ListHeading>
        {this.props.emails.map((item, idx) => (
          <ListRow key={idx}>
            <ListRowTitle>{item.label}</ListRowTitle>
            <ListRowInfo>
              <ListRowInfoItem>{item.value}</ListRowInfoItem>
              <ListRowInfoItem isButton={true}>
                <Button
                  icononly={true}
                  small={true}
                  tooltip={"Email"}
                  color={BridgeColor.gs300}
                  onClick={this.composeEmail.bind(null, item.value)}
                  track={[TRACK_CATEGORY, TrackAction.contactDetailsEmail]}
                >
                  <FontAwesomeIcon icon={faEnvelope} />
                </Button>
              </ListRowInfoItem>
            </ListRowInfo>
          </ListRow>
        ))}
      </Auxi>
    );
  }

  private composeEmail = (email: string) => {
    window.location.href = `mailto:${email}`;
  };
}

enum OpenedTab {
  details = "details",
  calls = "calls"
}

interface IContactsDetailsState {
  openedTab: OpenedTab;
}

interface IPropsFromState {
  phones: IContact["phones"];
  emails: IContact["emails"];
  isContactAvailable: boolean;
  name: string;
  callsIsLoading: boolean;
  online: boolean;
  companyName: string;
  companyContact: string;
  status: UserStatusInfo["userStatus"] | null;
  statusDisplay: string | null;
  statusCallId: string | null;
  contactType?: ContactType;
  onboardingMode: boolean;
}

interface IPropsFromDispatch {
  onDialNumber: (destination: string | number) => Promise<any>;
}

interface IContactsDetailsProps extends IPropsFromState, IPropsFromDispatch {
  id: string;
  hideHeader?: boolean;
  inline?: boolean;
}

const mapStateToProps = (
  state: IRootState,
  componentProps: IContactsDetailsProps
): IPropsFromState => {
  const contact =
    state.contacts.compassItems[componentProps.id] ||
    state.contacts.addressBookItems[componentProps.id];
  const company = state.auth.company;
  let userStatusInfo: UserStatusInfo | null = null;
  let userStatusDisplay: string | null = null;
  if (contact && contact.type === ContactType.compass) {
    const user = (state.auth.connection as Connection).model.users[contact.id];
    if (user) {
      userStatusInfo = getUserStatusInfo(user);
      userStatusDisplay = getUserStatusDisplay(user.id, {
        extended: true
      });
    }
  }

  return {
    phones: contact ? contact.phones : [],
    emails: contact ? contact.emails : [],
    isContactAvailable: !!contact,
    name: contact ? contact.name : "",
    callsIsLoading: state.calls.actionsInProgress > 0,
    online: state.window.online,
    companyName: company ? company.name : "",
    companyContact: company ? company.contact : "",
    status: userStatusInfo ? userStatusInfo.userStatus : null,
    statusDisplay: userStatusDisplay,
    statusCallId:
      userStatusInfo && userStatusInfo.call ? userStatusInfo.call.id : null,
    contactType: contact && contact.type,
    onboardingMode: state.auth.onboardingMode
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IRootState, void, AnyAction>
): IPropsFromDispatch => {
  return {
    onDialNumber: (destination: string) => dispatch(dialNumber(destination))
  };
};

export default connect<IPropsFromState, IPropsFromDispatch>(
  mapStateToProps,
  mapDispatchToProps
)(ContactDetails);
