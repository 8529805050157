import * as React from "react";
import {
  ListRow,
  ListRowTitle,
  ListRowInfo,
  ListRowInfoItem
} from "src/components/UI/List/";
import { IRootState } from "src/store/reducers";
import { connect } from "react-redux";
import {
  User,
  Call,
  CallPointState,
  OtherSide,
  ListenInCallPoint,
  CallPointType
} from "compass.js";
import { faEar } from "@fortawesome/pro-regular-svg-icons/faEar";
import { humanizeApproxDuration } from "src/utils/dateTime";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import Button from "src/components/UI/Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IContact } from "src/store/reducers/contacts";
import { handleError } from "src/utils/errorHandler";
import { getCallPointTitle, getUserSide } from "src/utils/call";
import { listenIn } from "src/store/actions/calls";
import { UserFeature, UserPermission } from "src/store/reducers/auth";
import { BridgeColor } from "src/utils/consts";

class ContactDetailsCallItem extends React.PureComponent<
  IContactsDetailsProps
> {
  render() {
    let $listenInBtn: React.ReactElement | null = null;
    if (this.props.canDoListenIn) {
      $listenInBtn = (
        <ListRowInfoItem isButton={true}>
          <Button
            icononly={true}
            small={true}
            color={BridgeColor.gs300}
            disabled={!this.props.canBeListenedTo || this.props.callsIsLoading}
            tooltip={this.props.listenInBtnTooltip}
            onClick={this.onListenInClick.bind(null, this.props.callId)}
          >
            <FontAwesomeIcon icon={faEar} />
          </Button>
        </ListRowInfoItem>
      );
    }
    return (
      <ListRow key={this.props.callId}>
        <ListRowTitle>
          {this.props.title}{" "}
          <span className="contact-detail__call-subtitle">
            {this.props.subTitle}
          </span>
        </ListRowTitle>
        <ListRowInfo>
          <ListRowInfoItem>
            {humanizeApproxDuration(+new Date() / 1000 - this.props.created)}
          </ListRowInfoItem>
          {$listenInBtn}
        </ListRowInfo>
      </ListRow>
    );
  }

  private onListenInClick = (callId: Call["id"]) => {
    this.props.onListenIn(this.props.callId).catch(handleError);
  };
}

interface IPropsFromState {
  callsIsLoading: boolean;
  canDoListenIn: boolean;
  canBeListenedTo: boolean;
  listenInBtnTooltip?: string;
  subTitle: string;
  created: number;
  title: string;
}

interface IPropsFromDispatch {
  onListenIn: (callId: Call["id"]) => Promise<any>;
}

interface IContactsDetailsComponentProps {
  callId: Call["id"];
  contactId: IContact["id"];
}

interface IContactsDetailsProps
  extends IPropsFromState,
    IPropsFromDispatch,
    IContactsDetailsComponentProps {}

const mapStateToProps = (
  state: IRootState,
  componentProps: IContactsDetailsComponentProps
): IPropsFromState => {
  const callsIsLoading = state.calls.actionsInProgress > 0;

  const contact = state.contacts.compassItems[componentProps.contactId];
  const contactUser = contact.user as User;
  const selfUser = state.auth.user as User;

  const call: Call = contactUser
    .getCalls()
    .find(item => item.id === componentProps.callId) as Call;
  const callMetadata = state.calls.allCallsMetadata[call.id];

  const contactUserSide = getUserSide(call, contactUser);
  const selfUserSide = getUserSide(call, selfUser);

  const callHasListenInCallpoint =
    call.source instanceof ListenInCallPoint ||
    call.destination instanceof ListenInCallPoint;

  let isCallActive = false;
  let otherCallPointState: CallPointState | undefined;
  let title = "Unknown";
  if (contactUserSide !== null) {
    const contactEndpoint = call.getEndpoint(contactUserSide);
    const otherEndpoint = call.getEndpoint(OtherSide(contactUserSide));
    title = getCallPointTitle(otherEndpoint, title);
    otherCallPointState = otherEndpoint.state;
    // NOTE: check if call is active for both sides
    isCallActive =
      [CallPointState.answered, CallPointState.inactive].includes(
        otherCallPointState
      ) &&
      [CallPointState.answered, CallPointState.inactive].includes(
        contactEndpoint.state
      );
  }

  const canDoListenIn =
    state.auth.features.includes(UserFeature.listenin) &&
    state.auth.companyPermission === UserPermission.permWrite;
  let listenInBtnTooltip: string | undefined;
  let canBeListenedTo: boolean = false;
  if (canDoListenIn) {
    const alreadyListenedTo: boolean = !!selfUser.getCalls().find(item => {
      return (
        item.source.type === CallPointType.listenIn &&
        (item.source as ListenInCallPoint).listenedToCallId === call.id
      );
    });
    canBeListenedTo =
      !callsIsLoading &&
      selfUserSide === null &&
      !callHasListenInCallpoint &&
      isCallActive &&
      !alreadyListenedTo;
    if (!canBeListenedTo) {
      listenInBtnTooltip = "This call can’t be listened in on";
      if (alreadyListenedTo) {
        listenInBtnTooltip = `You are already listening to this call`;
      }
      if (selfUserSide !== null) {
        listenInBtnTooltip = `You are in this call`;
      } else if (callHasListenInCallpoint) {
        listenInBtnTooltip = "Listened call can’t be listened to";
      }
    }
  }

  let subTitle = "direct call";
  if (callHasListenInCallpoint) {
    subTitle = "listen in";
  } else if (callMetadata.queue) {
    subTitle = `via ${callMetadata.queue.name}`;
  }

  return {
    callsIsLoading,
    canDoListenIn,
    canBeListenedTo,
    listenInBtnTooltip,
    title,
    subTitle,
    created: callMetadata.created
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IRootState, void, AnyAction>
): IPropsFromDispatch => {
  return {
    onListenIn: (callId: Call["id"]) => dispatch(listenIn(callId))
  };
};

export default connect<IPropsFromState, IPropsFromDispatch>(
  mapStateToProps,
  mapDispatchToProps
)(ContactDetailsCallItem);
