import { cssSafeStr } from "./../../utils/index";
import { notificationDismiss, notificationShow } from "./notifications";
import { compassDebouncePipe } from "./../../utils/compassDataMiddleware";
import { IQueueTargetState } from "./../reducers/queues";
import { wrapApiError, createRuntimeError } from "./../../utils/errorHandler";
import { dispatchWithProgress } from "src/utils/redux";
import { AnyAction } from "redux";
import * as actionTypes from "./actionTypes";
import { Queue, Connection, User, ReceiveCalls } from "compass.js";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { IRootState } from "src/store/reducers";
import { userStorage } from "src/utils/userStorage";
import { store } from "..";
import { compassDataMiddleware } from "src/utils/compassDataMiddleware";
import { setUserStatus } from "./auth";
import { ApplyToQueues } from "../preferences";

const PIN_STORAGE_KEY = "pinnedQueues";

export const updateGlobalPause = (
  val: boolean
): { type: string; payload: any } => {
  return {
    type: actionTypes.QUEUES_SET_GLOBAL_PAUSE,
    payload: val
  };
};

const queuesDispatchWithProgress = <PropType>(
  action: Promise<any>
): Promise<PropType> => {
  return dispatchWithProgress<PropType>(
    action,
    actionTypes.QUEUES_ACTION_STARTED,
    actionTypes.QUEUES_ACTION_COMPLETED
  );
};

export const setupQueues = (
  dispatch: ThunkDispatch<IRootState, void, AnyAction>
) => {
  const authenticatedUserQueues: Queue[] = [];
  dispatch(loadPinnedQueues()).then(() => {
    compassDataMiddleware.queues$
      .pipe(compassDebouncePipe())
      .subscribe(queues => {
        const user = store.getState().auth.user;
        if (user) {
          const userQueuesIds = user.getQueues().map(item => item.id);
          Object.values(queues).forEach(queue => {
            const isUserInQueue = userQueuesIds.includes(queue.id);
            if (authenticatedUserQueues[queue.id] === isUserInQueue) {
              return;
            }
            if (authenticatedUserQueues[queue.id] === undefined) {
              authenticatedUserQueues[queue.id] = isUserInQueue;
              return;
            }
            authenticatedUserQueues[queue.id] = isUserInQueue;
            const targetState = store.getState().queues.queueTargetState[
              queue.id
            ];
            // NOTE: user initiated queue login/out action
            const notificationId = `queue-manage-${cssSafeStr(queue.id)}`;
            dispatch(notificationDismiss(notificationId));
            if (targetState) {
              dispatch(
                notificationShow({
                  uid: notificationId,
                  message: isUserInQueue
                    ? `Joined queue ${queue.name}`
                    : `Left queue ${queue.name}`,
                  level: "info",
                  autoDismiss: 4000,
                  dismissable: true
                })
              );
              return;
            }
            dispatch(
              notificationShow({
                uid: notificationId,
                level: "info",
                dismissable: true,
                message: isUserInQueue
                  ? `You have been placed in queue ${queue.name}`
                  : `You have been taken out of queue ${queue.name}`
              })
            );
          });
        }
        dispatch(updateQueues(queues));
        if (user) {
          updateQueuesTargetState(user, queues);
          const apiVersion = store.getState().auth.apiVersion;
          if (apiVersion === 2) {
            v2UpdateGlobalPauseState(user, queues);
          }
        }
      });
  });
};

const v2UpdateGlobalPauseState = (
  user: User,
  queues: { [key: string]: Queue }
) => {
  // NOTE: don't update global pause while queueTargetState is not empty
  // for case where user logging in to queue while global pause is enabled
  if (!!Object.keys(store.getState().queues.queueTargetState).length) {
    return;
  }
  const queueMemberships = Object.values(queues)
    .filter(item => {
      return item.isUserInQueue(user.id);
    })
    .map(item => item.getQueueMember(user.id));
  // NOTE: don't update global pause if no logged in queues
  // for cases when user logging out from all queues
  if (!queueMemberships.length) {
    return;
  }
  // NOTE: global pause active when user is paused
  // in all logged in queues
  const isGlobalPauseActive = queueMemberships.every(
    item => !!item.pausedSince
  );
  if (isGlobalPauseActive !== store.getState().queues.v2GlobalPause) {
    store.dispatch(updateGlobalPause(isGlobalPauseActive));
  }
};

const updateQueuesTargetState = (
  user: User,
  queues: { [key: string]: Queue }
) => {
  const queuesTargetState = store.getState().queues.queueTargetState;
  Object.keys(queuesTargetState).forEach(queueId => {
    const queue = queues[queueId];
    if (!queue) {
      return;
    }
    const queueTargetState = queuesTargetState[queueId];
    const newQueueTargetState = { ...queueTargetState };
    if (
      "paused" in queueTargetState &&
      queue.isUserPausedInQueue(user.id) === queueTargetState.paused
    ) {
      delete newQueueTargetState.paused;
    }
    if (
      "loggedIn" in queueTargetState &&
      queue.isUserInQueue(user.id) === queueTargetState.loggedIn
    ) {
      delete newQueueTargetState.loggedIn;
    }
    if (!!Object.keys(newQueueTargetState).length) {
      store.dispatch(setQueueTargetState(queue.id, newQueueTargetState));
    } else {
      store.dispatch(clearQueueTargetState(queue.id));
    }
  });
};

export const updateQueues = (queues: {
  [key: string]: Queue;
}): { type: string; payload: { [key: string]: Queue } } => {
  return {
    type: actionTypes.QUEUES_UPDATE,
    payload: queues
  };
};

export const updatePinnedQueues = (
  queues: string[]
): { type: string; payload: string[] } => {
  return {
    type: actionTypes.QUEUES_UPDATE_PINNED,
    payload: queues
  };
};

export const setQueueTargetState = (
  queueId: string,
  state: IQueueTargetState
): { type: string; payload: { queueId: string; state: IQueueTargetState } } => {
  return {
    type: actionTypes.QUEUES_SET_QUEUE_TARGET_STATE,
    payload: {
      state,
      queueId
    }
  };
};

export const clearQueueTargetState = (
  queueId: string
): { type: string; payload: { queueId: string } } => {
  return {
    type: actionTypes.QUEUES_CLEAR_QUEUE_TARGET_STATE,
    payload: {
      queueId
    }
  };
};

export const setAllQueuePausedState = (
  targetPausedState: boolean
): ThunkAction<Promise<any>, IRootState, void, AnyAction> => {
  return (dispatch, getState, extraParams) => {
    const state = getState();
    const rest = (state.auth.connection as Connection).rest;
    const user = state.auth.user as User;
    if (state.auth.apiVersion === 2) {
      const action = targetPausedState ? "pauseQueue" : "unpauseQueue";
      return queuesDispatchWithProgress<any>(
        new Promise((resolve, reject) => {
          wrapApiError(rest.getMyFirstIdentity()).then(e => {
            const queuesToUpdate = Object.values(user.getQueues()).filter(
              item => item.isUserPausedInQueue(user.id) !== targetPausedState
            );
            const promises = queuesToUpdate.map(queue => {
              setQueueTargetState(queue.id, {
                paused: targetPausedState
              });
              return wrapApiError(
                rest.post(`identity/${e.identityId}/${action}`, {
                  queue: rest.getUrlForObject("queue", parseInt(queue.id, 10))
                })
              );
            });
            Promise.all(promises).then(resolve, error => {
              queuesToUpdate.forEach(queue => {
                clearQueueTargetState(queue.id);
              });
              reject(error);
            });
          }, reject);
        })
      );
    }
    return queuesDispatchWithProgress<any>(
      dispatch(
        setUserStatus(
          targetPausedState ? ReceiveCalls.onlyDirect : ReceiveCalls.all
        )
      )
    );
  };
};

export const loadPinnedQueues = (): ThunkAction<
  Promise<any>,
  IRootState,
  void,
  AnyAction
> => {
  return async (dispatch, getState, extraParams) => {
    try {
      const pinnedQueues = (await userStorage.getItem(
        PIN_STORAGE_KEY
      )) as string[];
      dispatch(updatePinnedQueues(pinnedQueues || []));
      return pinnedQueues;
    } catch (error) {
      throw createRuntimeError(error);
    }
  };
};

export const pinQueue = (
  queueId: string
): ThunkAction<Promise<any>, IRootState, void, AnyAction> => {
  return async (dispatch, getState, extraParams) => {
    const pinnedQueues = (getState().queues.pinned || []).slice();
    pinnedQueues.push(queueId);
    try {
      await userStorage.setItem(PIN_STORAGE_KEY, pinnedQueues);
      dispatch(updatePinnedQueues(pinnedQueues));
      return pinnedQueues;
    } catch (error) {
      throw createRuntimeError(error);
    }
  };
};

export const unpinQueue = (
  queueId: string
): ThunkAction<Promise<any>, IRootState, void, AnyAction> => {
  return async (dispatch, getState, extraParams) => {
    const pinnedQueues = (getState().queues.pinned || []).filter(
      item => item !== queueId
    );
    try {
      await userStorage.setItem(PIN_STORAGE_KEY, pinnedQueues);
      dispatch(updatePinnedQueues(pinnedQueues));
      return pinnedQueues;
    } catch (error) {
      throw createRuntimeError(error);
    }
  };
};

export const logoutQueue = (
  queueId: string,
  targetUserId?: string
): ThunkAction<Promise<any>, IRootState, void, AnyAction> => {
  return async (dispatch, getState, extraParams) => {
    const loggedInUser = getState().auth.user as User;
    const connection = getState().auth.connection as Connection;
    const userId = targetUserId ? targetUserId : loggedInUser.id;
    const isSelfUser = userId === loggedInUser.id;

    await queuesDispatchWithProgress<any>(
      (async () => {
        if (isSelfUser) {
          dispatch(setQueueTargetState(queueId, { loggedIn: false }));
        }
        try {
          await wrapApiError(
            connection.rest.post(`user/${userId}/logoutQueue`, {
              queue: connection.rest.getUrlForObject(
                "queue",
                parseInt(queueId, 10)
              )
            })
          );
        } catch (error) {
          if (isSelfUser) {
            dispatch(clearQueueTargetState(queueId));
          }
          throw new Error(error);
        }
      })()
    );
  };
};

export const loginQueue = (
  queueId: string,
  targetUserId?: string
): ThunkAction<Promise<any>, IRootState, void, AnyAction> => {
  return async (dispatch, getState, extraParams) => {
    const loggedInUser = getState().auth.user as User;
    const connection = getState().auth.connection as Connection;
    const userId = targetUserId ? targetUserId : loggedInUser.id;
    const isSelfUser = userId === loggedInUser.id;
    const apiVersion = getState().auth.apiVersion;
    const v2GlobalPauseEnabled = getState().queues.v2GlobalPause;
    const userPreferences = getState().preferences.user;
    await queuesDispatchWithProgress<any>(
      (async () => {
        if (isSelfUser) {
          dispatch(
            setQueueTargetState(queueId, {
              paused: apiVersion === 2 ? v2GlobalPauseEnabled : undefined,
              loggedIn: true
            })
          );
        }
        try {
          await wrapApiError(
            connection.rest.post(`user/${userId}/loginQueue`, {
              callForward:
                userPreferences.applyToQueues === ApplyToQueues.allCalls,
              priority: 1,
              queue: connection.rest.getUrlForObject(
                "queue",
                parseInt(queueId, 10)
              )
            })
          );
        } catch (error) {
          if (isSelfUser) {
            dispatch(clearQueueTargetState(queueId));
          }
          throw new Error(error);
        }
        if (apiVersion === 2 && v2GlobalPauseEnabled) {
          const identity = isSelfUser
            ? await wrapApiError(connection.rest.getMyFirstIdentity())
            : (await wrapApiError(
                connection.rest.get(
                  `${connection.rest.getUrlForObject(
                    "user",
                    parseInt(userId, 10)
                  )}/identities`
                )
              ))[0];
          await wrapApiError(
            connection.rest.post(`identity/${identity.identityId}/pauseQueue`, {
              queue: connection.rest.getUrlForObject(
                "queue",
                parseInt(queueId, 10)
              )
            })
          );
        }
      })()
    );
  };
};
