import * as React from "react";
import "./TwoColumn.scss";

const twoColumn: React.FC<ITwoColumnProps> = props => {
  const cssClasses = ["two-column"];
  if (props.rightActive) {
    cssClasses.push("two-column--right-active");
  }
  return <div className={cssClasses.join(" ")}>{props.children}</div>;
};

interface ITwoColumnProps {
  rightActive?: boolean;
}

export const TwoColumn = twoColumn;
