import * as React from "react";
import "./NavbarDropdown.scss";
import { connect } from "react-redux";
import { IRootState } from "src/store/reducers";
import {
  windowShowNavbarDropdown,
  windowHideNavbarDropdown
} from "src/store/actions/window";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { OnboardingStepId } from "src/utils/OnboardingStep";
import { TrackAction, TrackCategory, trackEvent } from "src/utils/track";
import { wrapOnboarding } from "src/utils/onboarding";
import { NavbarDropDownId } from "src/store/reducers/window";
import { IconDefinition } from "@fortawesome/fontawesome-common-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-regular-svg-icons/faCheck";
import { faTimes } from "@fortawesome/pro-regular-svg-icons/faTimes";
import Auxi from "src/hoc/Auxi/Auxi";
import { Tooltip } from "src/components/UI/Tooltip/Tooltip";
import { BridgeColor } from "src/utils/consts";

export enum MenuItemType {
  button = "button",
  separator = "separator",
  input = "input",
  header = "header"
}

export interface IMenuItem {
  action?: string;
  type: MenuItemType;
  title?: string;
  data?: any;
  selected?: boolean;
  icon?: IconDefinition;
  iconColor?: BridgeColor;
  track?: [TrackCategory, TrackAction];
  disabled?: boolean;
  tooltip?: string;
  onboardingStep?: OnboardingStepId;
}

class NavbarDropdown extends React.Component<
  INavbarDropdownProps,
  INavbarDropdownState
> {
  state = {
    openedInput: null,
    openedInputValue: ""
  };
  private $openedInput: HTMLInputElement | null;
  private $wrapperRef: HTMLDivElement | null;

  componentDidMount() {
    document.addEventListener("click", this.onDocumentClick);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.onDocumentClick);
  }

  render() {
    return (
      <div className="br-navbar-dropdown" ref={ref => (this.$wrapperRef = ref)}>
        <div className="br-navbar-dropdown__btn" onClick={this.toggleDropdown}>
          {this.props.children}
        </div>
        {this.props.items && this.props.navbarDropdownVisible
          ? wrapOnboarding(
              this.$getMenu(),
              OnboardingStepId.sectionNavbarDropdown,
              { placement: "left" }
            )
          : null}
      </div>
    );
  }

  private hideDropDown = () => {
    if (this.state.openedInput && this.state.openedInputValue) {
      const menuItem = this.props.items.find(
        item => item.action === this.state.openedInput
      );
      if (menuItem && menuItem.action) {
        this.props.onClick(
          menuItem.action,
          menuItem.data,
          this.state.openedInputValue
        );
      }
    }
    this.setState({
      openedInput: null,
      openedInputValue: ""
    });
    this.props.onHideNavbarDropdown();
  };

  private onDocumentClick = (e: MouseEvent) => {
    if (this.props.onboardingMode) {
      return;
    }
    if (
      this.props.navbarDropdownVisible &&
      this.$wrapperRef &&
      !this.$wrapperRef.contains(e.target as Node)
    ) {
      this.hideDropDown();
    }
  };

  private toggleDropdown = () => {
    if (this.props.onboardingMode) {
      return;
    }
    this.props.navbarDropdownVisible
      ? this.hideDropDown()
      : this.props.onShowNavbarDropdown(this.props.id);
  };

  private menuItemButtonClicked = (item: IMenuItem) => {
    if (this.props.onboardingMode || item.disabled) {
      return;
    }
    if (item.action) {
      this.props.onClick(item.action, item.data);
    }
    this.hideDropDown();
    if (item.track) {
      trackEvent.apply(null, item.track);
    }
  };

  private menuItemInputClicked = (item: IMenuItem) => {
    if (this.props.onboardingMode) {
      return;
    }
    if (item.action) {
      this.setState({ openedInput: item.action }, () => {
        if (this.$openedInput) {
          this.$openedInput.focus();
        }
      });
    }
  };

  private $getMenu() {
    return (
      <div className="br-navbar-dropdown__menu">
        <div className="br-navbar-dropdown__menu-inner">
          {this.props.items.map((item, idx) => this.$getMenuItem(item, idx))}
        </div>
      </div>
    );
  }

  private $getMenuItem(
    item: IMenuItem,
    idx: number
  ): React.ReactElement | null {
    let $item: React.ReactElement | null = null;
    switch (item.type) {
      case MenuItemType.button: {
        const cssClasses = ["br-navbar-dropdown__menu-item"];
        if (item.disabled) {
          cssClasses.push("br-navbar-dropdown__menu-item--disabled");
        }
        $item = (
          <div
            key={idx}
            className={cssClasses.join(" ")}
            onClick={this.menuItemButtonClicked.bind(this, item)}
          >
            {item.icon ? (
              <div
                data-color={item.iconColor || "default"}
                className="br-navbar-dropdown__menu-item-icon"
              >
                <FontAwesomeIcon icon={item.icon} />
              </div>
            ) : null}
            <div className="br-navbar-dropdown__menu-item-content">
              {item.title}
            </div>
            {item.selected ? (
              <div className="br-navbar-dropdown__menu-item-selected-icon">
                <FontAwesomeIcon icon={faCheck} />
              </div>
            ) : null}
          </div>
        );
        break;
      }
      case MenuItemType.input: {
        const isOpenedInput = this.state.openedInput === item.action;
        const cssClasses = ["br-navbar-dropdown__menu-item"];
        if (item.disabled) {
          cssClasses.push("br-navbar-dropdown__menu-item--disabled");
        }
        $item = (
          <div
            key={idx}
            className={cssClasses.join(" ")}
            onClick={
              isOpenedInput ? null : this.menuItemInputClicked.bind(this, item)
            }
          >
            <Auxi>
              {item.icon ? (
                <div
                  className={`br-navbar-dropdown__menu-item-icon br-navbar-dropdown__menu-item-icon--color-${item.iconColor ||
                    "default"}`}
                >
                  <FontAwesomeIcon icon={item.icon} />
                </div>
              ) : null}
              <div
                className="br-navbar-dropdown__menu-item-content"
                style={{ display: isOpenedInput ? "none" : undefined }}
              >
                {item.title}
              </div>
              <div
                className="br-navbar-dropdown__menu-item-input"
                style={{ display: !isOpenedInput ? "none" : undefined }}
              >
                <input
                  placeholder="Type here ..."
                  ref={ref => (this.$openedInput = ref)}
                  value={this.state.openedInputValue}
                  onChange={this.onOpenedInputChange}
                  onKeyPress={this.onOpenedInputKeyPress}
                />
                {this.state.openedInputValue ? (
                  <div
                    onClick={this.onOpenedInputCancel}
                    className="br-navbar-dropdown__menu-item-input-cancel"
                  >
                    <FontAwesomeIcon
                      className="br-navbar-dropdown__menu-item-input-cancel-icon"
                      icon={faTimes}
                    />
                  </div>
                ) : null}
              </div>
              {item.selected && !isOpenedInput ? (
                <div className="br-navbar-dropdown__menu-item-selected-icon">
                  <FontAwesomeIcon icon={faCheck} />
                </div>
              ) : null}
            </Auxi>
          </div>
        );
        break;
      }
      case MenuItemType.header:
        $item = (
          <div key={idx} className="br-navbar-dropdown__header">
            {item.title}
          </div>
        );
        break;
      case MenuItemType.separator:
        $item = <hr key={idx} />;
        break;
    }
    if ($item && item.onboardingStep) {
      return wrapOnboarding(
        $item,
        item.onboardingStep,
        {
          placement: "left"
        },
        true,
        `navbar-dropdown-${this.props.id}:${idx}`
      );
    }
    if (item.tooltip && $item) {
      return (
        <Tooltip key={idx} content={item.tooltip}>
          {$item}
        </Tooltip>
      );
    }
    return $item;
  }

  private onOpenedInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ openedInputValue: e.target.value });
  };

  private onOpenedInputCancel = (e: React.MouseEvent<HTMLInputElement>) => {
    this.setState({ openedInputValue: "", openedInput: null });
  };

  private onOpenedInputKeyPress = (
    e: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (e.key === "Enter") {
      this.hideDropDown();
    }
  };
}

interface INavbarDropdownState {
  openedInput: string | null;
  openedInputValue: string;
}

interface IPropsFromState {
  navbarDropdownVisible: boolean;
  onboardingMode: boolean;
}

interface IPropsFromDispatch {
  onHideNavbarDropdown: () => void;
  onShowNavbarDropdown: (navbarId: NavbarDropDownId) => void;
}

export interface INavbarDropdownProps
  extends IPropsFromState,
    IPropsFromDispatch {
  items: IMenuItem[];
  id: NavbarDropDownId;
  onClick: (id: string, data?: any, value?: string) => void;
}

const mapStateToProps = (
  state: IRootState,
  componentProps: INavbarDropdownProps
): IPropsFromState => {
  return {
    navbarDropdownVisible:
      state.window.navbarDropdownVisible === componentProps.id,
    onboardingMode: state.auth.onboardingMode
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IRootState, void, AnyAction>
): IPropsFromDispatch => {
  return {
    onHideNavbarDropdown: () => dispatch(windowHideNavbarDropdown()),
    onShowNavbarDropdown: (navbarId: NavbarDropDownId) =>
      dispatch(windowShowNavbarDropdown(navbarId))
  };
};

export default connect<IPropsFromState, IPropsFromDispatch>(
  mapStateToProps,
  mapDispatchToProps
)(NavbarDropdown);
