import * as React from "react";
import QueueDetails from "src/components/QueueDetails/QueueDetails";
import QueuesList from "src/components/QueuesList/QueuesList";
import ContactDetails from "src/components/ContactDetails/ContactDetails";
import ContactsList from "src/components/ContactsList/ContactsList";
import Dialer, { DialerType } from "src/components/UI/Dialer/Dialer";
import {
  TwoColumn,
  TwoColumnLeft,
  TwoColumnRight
} from "src/components/UI/TwoColumn";
import { connect } from "react-redux";
import { IRootState } from "src/store/reducers";
import {
  IHomePageParams,
  NavigationHomeList
} from "src/store/reducers/navigation";
import Auxi from "src/hoc/Auxi/Auxi";
import "./BottomView.scss";
import ContainerHeader from "src/components/UI/ContainerHeader/ContainerHeader";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { navigationUpdateParams } from "src/store/actions/navigation";
import { WindowSizeType } from "src/store/reducers/window";

class BottomView extends React.PureComponent<IBottomViewProps> {
  public render() {
    return (
      <TwoColumn rightActive={this.props.dialerActive}>
        <TwoColumnLeft>{this.$getLeftComponent()}</TwoColumnLeft>
        {this.props.windowSizeType === WindowSizeType.desktop ||
        this.props.dialerActive ? (
          <TwoColumnRight>{this.$getRightComponent()}</TwoColumnRight>
        ) : null}
      </TwoColumn>
    );
  }

  private $getRightComponent(): React.ReactNode {
    if (this.props.openedDetails) {
      switch (this.props.openedList) {
        case NavigationHomeList.queues:
          return <QueueDetails id={this.props.openedDetails} />;
        case NavigationHomeList.contacts:
          return <ContactDetails id={this.props.openedDetails} />;
        default:
          return null;
      }
    } else if (this.props.dialerActive) {
      return (
        <Auxi>
          <div className="br-screen-small">
            <ContainerHeader
              title={""}
              enableBackBtn={true}
              mobileOnlyBackBtn={true}
              className={"container-header--no-border"}
              backBtnClicked={this.onDialerClose}
            />
          </div>
          <Dialer
            type={
              this.props.callIdForDtmf ? DialerType.dtmf : DialerType.default
            }
            callIdForDtmf={this.props.callIdForDtmf}
          />
        </Auxi>
      );
    }
    return null;
  }

  private onDialerClose = () => {
    this.props.onNavigationUpdateParams({
      dialerActive: false
    });
  };

  private $getLeftComponent(): React.ReactNode {
    switch (this.props.openedList) {
      case "queues":
        return <QueuesList />;
      case "contacts":
        return <ContactsList />;
      default:
        return null;
    }
  }
}

interface IBottomViewProps extends IPropsFromState, IPropsFromDispatch {}

interface IPropsFromState {
  dialerActive: boolean;
  windowSizeType: WindowSizeType;
  openedDetails?: string;
  openedList: IHomePageParams["list"];
  callIdForDtmf?: string;
}

interface IPropsFromDispatch {
  onNavigationUpdateParams: (params: any) => void;
}

const mapStateToProps = (state: IRootState): IPropsFromState => {
  const navigationParams = state.navigation.params as IHomePageParams;
  return {
    dialerActive: navigationParams.dialerActive,
    openedDetails: navigationParams.detailsOpened
      ? navigationParams.detailsId
      : undefined,
    openedList: navigationParams.list,
    windowSizeType: state.window.sizeType,
    callIdForDtmf: navigationParams.callIdForDtmf
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IRootState, void, AnyAction>
): IPropsFromDispatch => {
  return {
    onNavigationUpdateParams: params => dispatch(navigationUpdateParams(params))
  };
};

export default connect<IPropsFromState, IPropsFromDispatch>(
  mapStateToProps,
  mapDispatchToProps
)(BottomView);
