import * as React from "react";
import { IRootState } from "../../../store/reducers";
import { connect } from "react-redux";
import { Call, User, Side, CallPointState } from "compass.js";
import { shouldShowCallForUser, getUserSide } from "src/utils/call";
import ringing from "src/assets/sounds/ringing.mp3";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { PhoneActionType, phoneAction } from "src/store/actions";
import { PhoneCapability, UserFeature } from "src/store/reducers/auth";
import { canAuthenticatedUserUseFeature } from "src/utils/user";
import { CallMetadata } from "src/utils/CallMetadata";
import { isElectron } from "src/utils";

class CallNotifications extends React.Component<ICallNotificationsProps> {
  public $audio: HTMLAudioElement | null;
  public state: ICallNotificationsState = {
    ignore: true,
    initialized: false,
    swRegistration: undefined
  };
  private shownNotifications: { [key: string]: Notification | undefined } = {};
  private swApi = navigator.serviceWorker;

  public componentDidMount() {
    this.checkNotificationsAccess();
    // NOTE: close all notifications before closing page and when user goes offline
    window.addEventListener("beforeunload", this.closeAllNotifications);
    window.addEventListener("offline", this.closeAllNotifications);
    if (this.swApi) {
      this.swApi.addEventListener("message", this.onSWNotificationClick);
      this.swApi
        .register("/notifications-service-worker.js")
        .then(this.onSWRegister);
    } else {
      this.setState({
        initialized: true
      });
    }
  }

  public componentWillUnmount() {
    window.removeEventListener("onbeforeunload", this.closeAllNotifications);
    window.removeEventListener("offline", this.closeAllNotifications);
    if (this.swApi) {
      this.swApi.removeEventListener("message", this.onSWNotificationClick);
    }
  }

  public onSWNotificationClick = (e: ServiceWorkerMessageEvent) => {
    if (e.data.type !== "notification-clicked") {
      return;
    }
    this.focusWindow();
    if (!e.data.action) {
      return;
    }
    const call = this.props.ringingCalls.find(
      item => item.id === e.data.notification
    );
    if (!call) {
      return;
    }
    switch (e.data.action) {
      case "answer":
        this.props.onPhoneAction(call.id, PhoneActionType.answerCall);
        break;
      case "decline":
        this.props.onPhoneAction(call.id, PhoneActionType.hangupCall);
        break;
    }
  };

  public onNotificationClick = (e: any) => {
    e.preventDefault();
    this.focusWindow();
    e.target.close();
  };

  public updateAudio = () => {
    if (!this.$audio) {
      return;
    }
    if (this.props.ringingCalls.length && this.props.online) {
      if (this.$audio.paused) {
        this.$audio.play().catch(error => {
          console.error(error);
        });
      }
    } else {
      this.$audio.pause();
      this.$audio.currentTime = 0;
    }
  };

  public showNotifications() {
    if (this.state.ignore || this.props.onboardingMode) {
      return;
    }
    this.clearExpiredNotifications();
    this.props.ringingCalls.forEach(call => {
      if (call.id in this.shownNotifications) {
        return;
      }
      const title = this.props.callsMetadata[call.id].lastConnectedTitle;
      const queue = this.props.callsMetadata[call.id].queue;
      let body: string;
      if (queue) {
        body = `From Bridge, queue ${queue.name}`;
      } else {
        body = "From Bridge, direct call";
      }
      const options: NotificationOptions = {
        body,
        requireInteraction: true,
        lang: "en",
        dir: "ltr",
        tag: call.id
      };
      if (
        this.state.swRegistration &&
        "showNotification" in this.state.swRegistration
      ) {
        options.actions = [{ action: "decline", title: "Decline" }];
        if (
          this.props.phoneCapabilities.includes(PhoneCapability.answer) &&
          canAuthenticatedUserUseFeature(UserFeature.callcontrol)
        ) {
          options.actions.unshift({ action: "answer", title: "Answer" });
        }
        const registration = this.state.swRegistration;
        this.removeNotification(call.id);
        registration.showNotification(title, options).then(() => {
          registration
            .getNotifications({ tag: call.id })
            .then(notifications => {
              if (!notifications.length) {
                return;
              }
              const notification = notifications[0];
              this.shownNotifications[call.id] = notification;
              notification.addEventListener("click", this.onNotificationClick);
            });
        });
      } else {
        const notification = new Notification(title, options);
        notification.addEventListener("click", this.onNotificationClick);
        this.shownNotifications[call.id] = notification;
      }
    });
  }

  public render() {
    this.updateAudio();
    this.showNotifications();
    return (
      <audio
        ref={el => (this.$audio = el)}
        preload="auto"
        autoPlay={false}
        loop={true}
      >
        <source src={ringing} type="audio/mpeg" />
      </audio>
    );
  }

  private focusWindow = () => {
    if (isElectron()) {
      window.remote.getCurrentWindow().show();
    } else {
      window.focus();
    }
  };

  private onSWRegister = (swRegistration: ServiceWorkerRegistration) => {
    this.setState({
      initialized: true,
      swRegistration
    });
  };

  private checkNotificationsAccess() {
    if (!("Notification" in window) || Notification.permission === "denied") {
      return this.setState({
        ignore: true
      });
    } else if (Notification.permission === "granted") {
      return this.setState({
        ignore: false
      });
    }
    Notification.requestPermission(e => {
      this.setState({
        ignore: e === "denied"
      });
    });
  }

  private clearExpiredNotifications() {
    const callsIds = this.props.ringingCalls.map(item => item.id);
    Object.keys(this.shownNotifications).forEach(id => {
      if (!callsIds.includes(id)) {
        const notification = this.shownNotifications[id];
        if (notification) {
          notification.close();
          this.removeNotification(id);
        }
      }
    });
  }

  private removeNotification(callId: string) {
    const notification = this.shownNotifications[callId];
    if (!notification) {
      return;
    }
    delete this.shownNotifications[callId];
    notification.close();
  }

  private closeAllNotifications = () => {
    Object.values(this.shownNotifications).forEach(item => {
      if (item) {
        this.removeNotification(item.tag);
      }
    });
  };
}

interface ICallNotificationsProps extends IPropsFromDispatch, IPropsFromState {}

interface IPropsFromState {
  ringingCalls: Call[];
  phoneCapabilities: PhoneCapability[];
  callsMetadata: { [key: string]: CallMetadata };
  online: boolean;
  onboardingMode: boolean;
}

interface ICallNotificationsState {
  ignore: boolean;
  initialized: boolean;
  swRegistration: ServiceWorkerRegistration | undefined;
}

interface IPropsFromDispatch {
  onPhoneAction: (callId: Call["id"], action: PhoneActionType) => Promise<any>;
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IRootState, void, AnyAction>
): IPropsFromDispatch => {
  return {
    onPhoneAction: (callId: Call["id"], action: PhoneActionType) =>
      dispatch(phoneAction(callId, action))
  };
};

const mapStateToProps = (state: IRootState): IPropsFromState => {
  return {
    phoneCapabilities: state.auth.phone ? state.auth.phone.capabilities : [],
    callsMetadata: state.calls.callsMetadata,
    ringingCalls: state.calls.items.filter(call => {
      const selfSide = getUserSide(call, state.auth.user as User);
      return (
        shouldShowCallForUser(call, state.auth.user as User) &&
        selfSide === Side.destination &&
        call.destination.state === CallPointState.ringing &&
        !state.calls.callsMetadata[call.id].isEnded
      );
    }),
    online: state.window.online,
    onboardingMode: state.auth.onboardingMode
  };
};

export default connect<IPropsFromState, IPropsFromDispatch>(
  mapStateToProps,
  mapDispatchToProps
)(CallNotifications);
