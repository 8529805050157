import {
  Call,
  CallPoint,
  CallPointState,
  CallPointType,
  CallState,
  Company,
  Connection,
  Event,
  EventType,
  Model,
  Queue,
  User,
  UserCallPoint,
  QueueMember,
  QueueCallPoint,
  ReceiveCalls
} from "compass.js";
import * as $ from "jquery";
import {
  PhoneCapability,
  UserFeature,
  OnboardingType
} from "src/store/reducers/auth";
import { generateUID } from ".";
import * as clone from "clone";
import { store } from "src/store";
import {
  setAsDesiredTransferCall,
  resetDesiredTransferCall,
  registerAttendedTransfer
} from "src/store/actions/calls";
import {
  homeChangeList,
  navigationUpdateParams
} from "src/store/actions/navigation";
import { NavigationHomeList } from "src/store/reducers/navigation";
import {
  windowShowNavbarDropdown,
  windowHideNavbarDropdown
} from "src/store/actions/window";
import { OnboardingStep, OnboardingStepId } from "./OnboardingStep";
import { NavbarDropDownId } from "src/store/reducers/window";
import { updateAddressBookContacts } from "src/store/actions";

export class OnboardingController {
  step: OnboardingStep;
  tour: OnboardingStep[];
  private savedPrevStep: OnboardingStep | null;
  private model: Model;
  private company: Company;
  private user: User;
  private connection: Connection;
  private onboardingCalls: { [key: string]: Call } = {};

  constructor(
    private loggedInUser: User,
    private type: OnboardingType,
    private params: { userHasPremium?: boolean } = {}
  ) {
    this.connection = new Connection("onboarding");
    this.model = this.connection.model;
    this.initUser();

    this.company = new Company("company", DUMMY_XML_ELEM, this.model);
    this.company.name = "Bridge Telecom Solutions";
    this.model.company = this.company;
    this.tour = this.initTour();
    this.step = this.tour[0];
  }

  start() {
    this.initAgents();
    this.initQueues();
    // NOTE: remove address book contacts from store
    store.dispatch(updateAddressBookContacts({}, {}));
    this.generateOnboardingCalls();
    this.activateStep(this.step);
  }

  getConnection(): Connection {
    return this.connection;
  }

  getRestUserFeatures(): UserFeature[] {
    return Object.keys(UserFeature).map(k => UserFeature[k]);
  }

  getRestPhoneCaps(): PhoneCapability[] {
    return Object.keys(PhoneCapability).map(k => PhoneCapability[k]);
  }

  getNextStep(): OnboardingStep {
    if (this.step.isLastStep) {
      throw new Error("Onboarding out of bounds");
    }
    return this.tour[this.tour.findIndex(step => step.id === this.step.id) + 1];
  }

  getPrevStep(): OnboardingStep {
    if (this.savedPrevStep) {
      return this.savedPrevStep;
    }
    const currentStepIdx = this.tour.findIndex(
      step => step.id === this.step.id
    );
    if (currentStepIdx === 0) {
      throw new Error("Onboarding out of bounds");
    }
    return this.tour[currentStepIdx - 1];
  }

  getEndStep(): OnboardingStep | null {
    if (this.type === OnboardingType.guest) {
      return new OnboardingStep(OnboardingStepId.finishGuest);
    }
    if (!this.params.userHasPremium) {
      return new OnboardingStep(OnboardingStepId.endNoPremium);
    }
    return null;
  }

  setStep(nextStep: OnboardingStep) {
    const nextStepIdx = this.tour.findIndex(step => step.id === nextStep.id);
    const currentStepIdx = this.tour.findIndex(
      step => step.id === this.step.id
    );
    this.onLeaveStep(currentStepIdx < nextStepIdx ? "next" : "prev");
    const endStep = this.getEndStep();
    if (endStep && endStep.id === nextStep.id) {
      this.savedPrevStep = this.step;
    } else if (this.savedPrevStep) {
      this.savedPrevStep = null;
    }
    this.activateStep(nextStep);
    this.step = nextStep;
  }

  private initTour() {
    const steps = [
      new OnboardingStep(
        this.type === OnboardingType.guest
          ? OnboardingStepId.welcomeGuest
          : OnboardingStepId.welcome
      ),
      new OnboardingStep(OnboardingStepId.sectionCalls),
      new OnboardingStep(OnboardingStepId.callAnswer),
      new OnboardingStep(OnboardingStepId.callHold),
      new OnboardingStep(OnboardingStepId.callUnhold),
      new OnboardingStep(OnboardingStepId.callTransfer),
      new OnboardingStep(OnboardingStepId.modalTransfer),
      new OnboardingStep(OnboardingStepId.transferAttended),
      new OnboardingStep(OnboardingStepId.callCancelAttendedTransfer),
      new OnboardingStep(OnboardingStepId.navigateQueues),
      new OnboardingStep(OnboardingStepId.sectionQueues),
      new OnboardingStep(OnboardingStepId.sectionNavbarDropdown),
      new OnboardingStep(OnboardingStepId.navbarHelp),
      new OnboardingStep(OnboardingStepId.navbarSendFeedback)
    ];
    if (this.type === OnboardingType.guest) {
      return [...steps, new OnboardingStep(OnboardingStepId.finishGuest)];
    }
    return [
      ...steps,
      new OnboardingStep(
        this.params.userHasPremium
          ? OnboardingStepId.finish
          : OnboardingStepId.finishNoPremium
      )
    ];
  }

  private generateOnboardingCalls() {
    ONBOARDING_CALLS.forEach(item => {
      const call = new Call(item.id, DUMMY_XML_ELEM, this.model);
      const userCallpoint = this.createUserCallpoint(this.user);
      const otherUserCallPoint = this.createUserCallpoint(
        this.model.users[item.otherUserId]
      );
      switch (item.type) {
        case OnboardingCallType.incoming:
          call.destination = userCallpoint;
          call.source = otherUserCallPoint;
          break;
        case OnboardingCallType.outgoing:
          call.destination = otherUserCallPoint;
          call.source = userCallpoint;
          break;
      }
      call.state = CallState.answered;

      if (item.queueId) {
        const parentCall = new Call(generateUID(), DUMMY_XML_ELEM, this.model);
        parentCall.state = CallState.answered;
        parentCall.source = otherUserCallPoint;
        parentCall.destination = this.createQueueCallpoint(
          this.model.queues[item.queueId]
        );
        call.parentCall = parentCall;
      }
      this.onboardingCalls[call.id] = call;
    });
  }

  private initQueues() {
    for (const queueInfo of ONBOARDING_QUEUES) {
      const queue = new Queue(queueInfo.id, DUMMY_XML_ELEM, this.model);
      queue.name = queueInfo.name;
      queue.queueMembers = [
        new QueueMember(this.user.id, queue.id, "1", "0", this.model)
      ];
      this.model.queues[queue.id] = queue;
      this.model.notify(new Event(queue, EventType.Added));
    }
  }

  private initAgents() {
    let extension = 2000;
    for (const userInfo of ONBOARDING_USERS) {
      const user = new User(userInfo.id, DUMMY_XML_ELEM, this.model);
      user.name = userInfo.name;
      user.loggedIn = true;
      user.jid = `demo${userInfo.id}@demo.com`;
      user.extensions = [extension.toString()];
      user.status = {
        receiveCalls: ReceiveCalls.all,
        displayStatus: "",
        wrapupState: null
      };
      this.model.users[user.id] = user;
      this.model.notify(new Event(user, EventType.Added));
      extension++;
    }
  }

  private initUser() {
    this.user = new User(this.loggedInUser.id, DUMMY_XML_ELEM, this.model);
    this.user.name = this.loggedInUser.name;
    this.user.jid = this.loggedInUser.jid;
    this.user.extensions =
      this.loggedInUser.extensions && this.loggedInUser.extensions.length
        ? this.loggedInUser.extensions
        : ["9999"];
    this.user.loggedIn = true;
    this.user.status = {
      receiveCalls: ReceiveCalls.all,
      displayStatus: "",
      wrapupState: null
    };
    this.model.users[this.user.id] = this.user;
    this.model.notify(new Event(this.user, EventType.UserAdded));
  }

  private onLeaveStep(direction: "next" | "prev") {
    switch (this.step.id) {
      case OnboardingStepId.callAnswer:
        if (direction === "prev") {
          this.clearCalls();
        }
        break;
      case OnboardingStepId.modalTransfer:
        if (direction === "prev") {
          store.dispatch(resetDesiredTransferCall());
        }
        break;
      case OnboardingStepId.callCancelAttendedTransfer:
        this.clearCall(OnboardingCallId.userOutgoing);
        this.pushCall(this.getAnsweredCall(OnboardingCallId.queueIncoming));
        if (direction === "prev") {
          store.dispatch(
            setAsDesiredTransferCall(OnboardingCallId.queueIncoming)
          );
        } else {
          store.dispatch(resetDesiredTransferCall());
        }
        break;
      case OnboardingStepId.sectionQueues:
        if (direction === "prev") {
          store.dispatch(homeChangeList(NavigationHomeList.contacts));
        }
        break;
      case OnboardingStepId.sectionNavbarDropdown:
      case OnboardingStepId.navbarHelp:
      case OnboardingStepId.navbarSendFeedback:
        store.dispatch(windowHideNavbarDropdown());
        break;
    }
  }

  private activateStep(step: OnboardingStep) {
    switch (step.id) {
      case OnboardingStepId.sectionCalls:
        store.dispatch(homeChangeList(NavigationHomeList.contacts));
        this.clearCalls();
        break;
      case OnboardingStepId.callAnswer: {
        this.pushCall(this.getRingingCall(OnboardingCallId.queueIncoming));
        break;
      }
      case OnboardingStepId.callHold: {
        this.pushCall(this.getAnsweredCall(OnboardingCallId.queueIncoming));
        break;
      }
      case OnboardingStepId.callUnhold: {
        this.pushCall(this.getPausedCall(OnboardingCallId.queueIncoming));
        break;
      }
      case OnboardingStepId.callTransfer: {
        this.pushCall(this.getAnsweredCall(OnboardingCallId.queueIncoming));
        break;
      }
      case OnboardingStepId.modalTransfer: {
        store.dispatch(
          setAsDesiredTransferCall(OnboardingCallId.queueIncoming)
        );
        break;
      }
      case OnboardingStepId.transferAttended: {
        store.dispatch(
          setAsDesiredTransferCall(OnboardingCallId.queueIncoming)
        );
        break;
      }
      case OnboardingStepId.callCancelAttendedTransfer: {
        const call = this.getPausedCall(OnboardingCallId.queueIncoming);
        const consultationCall = this.getAnsweredCall(
          OnboardingCallId.userOutgoing
        );
        const unsubscribe = store.subscribe(() => {
          const calls = store.getState().calls.items;
          if (
            calls.find(item => item.id === OnboardingCallId.queueIncoming) &&
            calls.find(item => item.id === OnboardingCallId.userOutgoing)
          ) {
            unsubscribe();
            store.dispatch(
              setAsDesiredTransferCall(OnboardingCallId.queueIncoming)
            );
            store.dispatch(
              registerAttendedTransfer(call.id, consultationCall.id)
            );
          }
        });
        this.pushCall(call);
        this.pushCall(consultationCall);
        break;
      }
      case OnboardingStepId.sectionQueues: {
        store.dispatch(homeChangeList(NavigationHomeList.queues));
        store.dispatch(
          navigationUpdateParams({
            detailsOpened: true,
            detailsId: OnboardingQueueId.Development
          })
        );
        break;
      }
      case OnboardingStepId.sectionNavbarDropdown:
      case OnboardingStepId.navbarHelp:
      case OnboardingStepId.navbarSendFeedback: {
        store.dispatch(windowShowNavbarDropdown(NavbarDropDownId.app));
        break;
      }
      case OnboardingStepId.finish: {
        // NOTE: make sure that when user clicks "End tutorial",
        // while transfer modal opened we will hide it
        store.dispatch(resetDesiredTransferCall());
        break;
      }
    }
  }

  private pushCall(call: Call) {
    this.model.calls[call.id] = call;
    this.model.notify(new Event(call, EventType.Added));
  }

  private getAnsweredCall(id: string) {
    const call = clone(this.onboardingCalls[id], undefined, 2);
    call.state = CallState.answered;
    call.destination.state = CallPointState.answered;
    call.source.state = CallPointState.answered;
    return call;
  }

  private getRingingCall(id: string) {
    const call = clone(this.onboardingCalls[id], undefined, 2);
    call.state = CallState.ringing;
    call.destination.state = CallPointState.ringing;
    call.source.state = CallPointState.answered;
    return call;
  }

  private getPausedCall(id: string) {
    const call = clone(this.onboardingCalls[id], undefined, 2);
    call.state = CallState.answered;
    call.destination.state = CallPointState.inactive;
    call.source.state = CallPointState.inactive;
    return call;
  }

  private clearCall(callId: string) {
    const call = this.model.calls[callId];
    if (call) {
      delete this.model.calls[call.id];
      this.model.notify(new Event(call, EventType.Removed));
    }
  }

  private clearCalls() {
    Object.values(this.model.calls).forEach(call => {
      delete this.model.calls[call.id];
      this.model.notify(new Event(call, EventType.CallRemoved));
    });
  }

  private createUserCallpoint(user: User) {
    const callPoint = new UserCallPoint(
      generateUID(),
      DUMMY_XML_ELEM,
      this.model
    );
    this.fillCallpoint(callPoint, CallPointType.user);
    callPoint.userId = user.id;
    callPoint.userName = user.name;
    return callPoint;
  }

  private createQueueCallpoint(queue: Queue) {
    const callPoint = new QueueCallPoint(
      generateUID(),
      DUMMY_XML_ELEM,
      this.model
    );
    this.fillCallpoint(callPoint, CallPointType.queue);
    callPoint.queueId = queue.id;
    callPoint.queueName = queue.name;
    return callPoint;
  }

  private fillCallpoint(callPoint: CallPoint, type: CallPointType) {
    callPoint.type = type;
    callPoint.state = CallPointState.answered;
    callPoint.timeCreated = this.getTimestamp();
    callPoint.timeStarted = this.getTimestamp();
  }

  private getTimestamp() {
    return new Date().getTime() / 1000;
  }
}

const DUMMY_XML_ELEM = $();

enum OnboardingUserId {
  JamieLannister = "1",
  CerseiLannister = "2",
  JonSnow = "3"
}
const ONBOARDING_USERS: Array<{ name: string; id: OnboardingUserId }> = [
  { name: "Jamie Lannister", id: OnboardingUserId.JamieLannister },
  { name: "Cersei Lannister", id: OnboardingUserId.CerseiLannister },
  { name: "Jon Snow", id: OnboardingUserId.JonSnow }
];

enum OnboardingQueueId {
  Development = "1",
  CustomerService = "2"
}
const ONBOARDING_QUEUES: Array<{ name: string; id: OnboardingQueueId }> = [
  { name: "Development", id: OnboardingQueueId.Development },
  { name: "Customer Service", id: OnboardingQueueId.CustomerService }
];

enum OnboardingCallType {
  outgoing = "outgoing",
  incoming = "incoming"
}
enum OnboardingCallId {
  queueIncoming = "1",
  userOutgoing = "2"
}

const ONBOARDING_CALLS: Array<{
  id: OnboardingCallId;
  otherUserId: string;
  type: OnboardingCallType;
  queueId?: string;
}> = [
  {
    id: OnboardingCallId.queueIncoming,
    otherUserId: ONBOARDING_USERS[0].id,
    queueId: ONBOARDING_QUEUES[0].id,
    type: OnboardingCallType.incoming
  },
  {
    id: OnboardingCallId.userOutgoing,
    otherUserId: ONBOARDING_USERS[1].id,
    type: OnboardingCallType.outgoing
  }
];
