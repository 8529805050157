import * as React from "react";
import "./SettingsViewForm.scss";
import { useSelector, useDispatch } from "react-redux";
import {
  selectUserPreferences,
  ViewModeType,
  updateUserPreferences
} from "src/store/preferences";
import Radio from "../UI/Radio/Radio";
import { NavigationHomeList } from "src/store/reducers/navigation";

const SettingsViewForm: React.FC = () => {
  const userPreferences = useSelector(selectUserPreferences);
  const dispatch = useDispatch();
  const onDefaultHomeListChange = (defaultHomeList: NavigationHomeList) => {
    dispatch(updateUserPreferences({ ...userPreferences, defaultHomeList }));
  };
  const onViewModeChange = (viewMode: ViewModeType) => {
    dispatch(updateUserPreferences({ ...userPreferences, viewMode }));
  };
  return (
    <div className="settings-view-form">
      <div className="br-form-group">
        <div className="br-form-label">Homepage</div>
        <div className="br-form-info">
          Select if you want to see contacts or queues on homepage.
        </div>
        <div className="br-form-control">
          <Radio
            value={userPreferences.defaultHomeList}
            options={[
              {
                name: "Contacts view",
                value: NavigationHomeList.contacts
              },
              {
                name: "Queues view",
                value: NavigationHomeList.queues
              }
            ]}
            onSelect={onDefaultHomeListChange}
          />
        </div>
      </div>
      <div className="br-form-group">
        <div className="br-form-label">Information density</div>
        <div className="br-form-info">
          Select hom much white space you like to see in the application.
        </div>
        <div className="br-form-control">
          <Radio
            value={userPreferences.viewMode}
            options={[
              {
                name: "Comfortable view",
                value: ViewModeType.comfortable
              },
              {
                name: "Compact view",
                value: ViewModeType.compact
              }
            ]}
            onSelect={onViewModeChange}
          />
        </div>
      </div>
    </div>
  );
};

export default SettingsViewForm;
