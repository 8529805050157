import * as React from "react";
import "./GlobalAlert.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";

export interface IGlobalAlertProps {
  message: string;
  level: "success" | "info" | "danger";
  icon?: IconDefinition;
  action?: {
    text: string;
    onClick: () => void;
  };
}

export const GlobalAlert: React.FC<IGlobalAlertProps> = props => {
  const onActionClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    if (!props.action) {
      return;
    }
    props.action.onClick();
  };
  return (
    <div className={`global-alert global-alert--${props.level}`}>
      {props.icon ? (
        <div className="global-alert__icon">
          <FontAwesomeIcon icon={props.icon} />
        </div>
      ) : null}
      <div className="global-alert__message">
        {props.message}{" "}
        {props.action ? (
          <a href="#" onClick={onActionClick}>
            {props.action.text}
          </a>
        ) : null}
      </div>
    </div>
  );
};
