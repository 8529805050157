import { IContact, ContactType } from "src/store/reducers/contacts";
import { parsePhoneNumber } from "src/utils";

export const getMacOsAddressBook = (): AddressBook => {
  // NOTE: check if preload successfully loaded addressbook lib
  // details: #721
  if (!!window.macOsAddressBook && !!window.macOsAddressBook.getContacts) {
    return window.macOsAddressBook;
  }
  return window.remote.require("@wireapp/node-addressbook");
};

/**
 * Get contact full name
 * @param {Contact} contact Contact
 */
const getContactName = (contact: AddressBook.ContactInformation) => {
  const nameArr = [];
  if (!contact.firstName && !contact.lastName) {
    return;
  }
  if (contact.firstName) {
    nameArr.push(contact.firstName);
  }
  if (contact.lastName) {
    nameArr.push(contact.lastName);
  }
  return nameArr.join(" ");
};

/**
 * Get contact emails list
 * @param {Contact} contact Contact
 */
const getEmails = (
  contact: AddressBook.ContactInformation
): Array<{ value: string; label: string }> => {
  return contact.emails
    .filter((item: string) => !!item)
    .map((item: string) => {
      // TODO: label is empty due to
      // node-addressbook don't support it yet
      return {
        value: item,
        label: ""
      };
    });
};

/**
 * Get contact phones list
 * @param {Contact} contact Contact
 */
const getPhones = (
  contact: AddressBook.ContactInformation
): Array<{ value: string; label: string }> => {
  return contact.numbers
    .filter((item: string) => parsePhoneNumber(item))
    .map((item: string) => {
      // TODO: label is empty due to
      // node-addressbook don't support it yet
      return {
        value: parsePhoneNumber(item),
        label: ""
      };
    });
};

/**
 * Merge 2 lists of objects with "value" property
 * @param {*} a List
 * @param {*} b List
 */
const mergeValueLists = <PropType>(
  a: Array<PropType & { value: any }>,
  b: Array<PropType & { value: any }>
): PropType[] => {
  let initialList;
  let sideList;
  if (a.length > b.length) {
    initialList = b;
    sideList = a;
  } else {
    initialList = a;
    sideList = b;
  }
  const out = [...initialList];
  sideList.forEach(item => {
    if (out.find(outItem => item.value === outItem.value)) {
      return;
    }
    out.push({ ...item });
  });
  return out;
};

export const getMacOsContacts = async (): Promise<{
  [key: string]: IContact;
}> => {
  const contacts = await getMacOsAddressBook().getContacts();
  const transformedContacts: { [key: string]: IContact } = {};
  // TODO: merging contacts with the same names, due to
  // mac os address book returns linked contacts as individual
  // related SO thread with possible solution:
  // https://stackoverflow.com/questions/11351454/dealing-with-duplicate-contacts-due-to-linked-cards-in-ios-address-book-api
  const namesMap = {};
  contacts.forEach((contact: AddressBook.ContactInformation) => {
    const name = getContactName(contact);
    if (!name) {
      return;
    }
    if (namesMap[name]) {
      const transformedContact = transformedContacts[namesMap[name]];
      transformedContact.phones = mergeValueLists(
        getPhones(contact),
        transformedContact.phones
      );
      transformedContact.emails = mergeValueLists(
        getEmails(contact),
        transformedContact.emails
      );
      return;
    }
    transformedContacts[contact.uid] = {
      id: contact.uid,
      type: ContactType.addressBook,
      name,
      phones: getPhones(contact),
      emails: getEmails(contact)
    };
    namesMap[name] = contact.uid;
  });
  return transformedContacts;
};
