import * as React from "react";
import { TippyProps } from "@tippy.js/react";
import { Tooltip } from "../Tooltip/Tooltip";
import { OnboardingStepId, OnboardingStep } from "src/utils/OnboardingStep";
import { IRootState } from "src/store/reducers";
import { connect } from "react-redux";
import {
  endOnboarding,
  onboardingNext,
  onboardingPrev,
  onboardingSetStep
} from "src/store/actions/auth";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import Button from "../Button/Button";
import "./OnboardingTooltip.scss";
import { UserFeature, OnboardingType } from "src/store/reducers/auth";
import { OnboardingController } from "src/utils/OnboardingController";
import { BridgeColor } from "src/utils/consts";

const defaultTippyProps: Partial<TippyProps> = {
  arrow: true,
  delay: [0, 0],
  theme: "bridge",
  boundary: "window",
  hideOnClick: false,
  interactive: true,
  animateFill: false,
  animation: "shift-away"
};

class OnboardingTooltip extends React.Component<
  IOnboardingTooltipProps,
  { isVisible: boolean }
> {
  // NOTE: 'isVisible' is for show delay purpose,
  // to prevent various possible glitches on render.
  // 'visible' tippy.js option ignores 'delay'.
  // https://gitlab.iperitydev.com/compass/bridge/-/issues/757
  state = {
    isVisible: false
  };
  private visibleTimeout: NodeJS.Timer | null = null;

  componentDidMount() {
    this.visibleTimeout = setTimeout(() => {
      this.setState({ isVisible: true });
    }, 300);
  }

  componentWillUnmount() {
    if (this.visibleTimeout) {
      clearTimeout(this.visibleTimeout);
    }
  }

  render() {
    if (!this.props.onboardingMode) {
      return this.props.children;
    }
    let matchingStep: OnboardingStep | undefined;
    if (typeof this.props.step === "string") {
      matchingStep =
        this.props.onboardingStep &&
        this.props.step === this.props.onboardingStep.id
          ? this.props.onboardingStep
          : undefined;
    } else if (Array.isArray(this.props.step)) {
      matchingStep =
        this.props.onboardingStep &&
        !!this.props.step.find(
          item =>
            !!(
              this.props.onboardingStep && item === this.props.onboardingStep.id
            )
        )
          ? this.props.onboardingStep
          : undefined;
    }
    if (!matchingStep) {
      return this.props.children;
    }
    const cssClasses = ["br-onboarding-tooltip"];
    if (this.props.tippyProps && this.props.tippyProps.className) {
      cssClasses.push(this.props.tippyProps.className);
    }
    return (
      <Tooltip
        {...{
          ...defaultTippyProps,
          ...this.props.tippyProps,
          visible: this.state.isVisible
        }}
        className={cssClasses.join(" ")}
        content={this.$getTooltipContent(matchingStep)}
      >
        {this.props.children}
      </Tooltip>
    );
  }

  private $getTooltipContent(step: OnboardingStep) {
    return (
      <div className="onboarding-tooltip">
        <div className="onboarding-tooltip__content">
          <div className="onboarding-tooltip__message">{step.message}</div>
          <div className="onboarding-tooltip__buttons">
            {this.$getButtons(step)}
          </div>
        </div>
      </div>
    );
  }

  private $getButtons(step: OnboardingStep): React.ReactElement {
    if (step.id === OnboardingStepId.finishGuest) {
      return (
        <>
          <Button
            onClick={this.props.onEndOnboarding}
            fill={"clear"}
            color={BridgeColor.gs600}
            onboardingDoNotBlock={true}
          >
            End tutorial
          </Button>
          <Button
            onClick={this.onLearnMore}
            color={BridgeColor.gs800}
            onboardingDoNotBlock={true}
          >
            Learn more
          </Button>
          <Button
            onClick={this.onGetInTouch}
            color={BridgeColor.prim500}
            onboardingDoNotBlock={true}
          >
            Get in touch
          </Button>
        </>
      );
    }
    return (
      <>
        {this.$getEndBtn(step)}
        {this.$getRestartBtn(step)}
        {this.$getPrevBtn(step)}
        {this.$getNextBtn(step)}
      </>
    );
  }

  private $getRestartBtn(step: OnboardingStep) {
    if (!step.hasResetButton || !this.props.onboardingController) {
      return null;
    }
    return (
      <Button
        onClick={this.props.onOnboardingSetStep.bind(
          null,
          this.props.onboardingController.tour[0]
        )}
        fill={"clear"}
        color={BridgeColor.gs600}
        onboardingDoNotBlock={true}
      >
        Start over
      </Button>
    );
  }

  private $getEndBtn(step: OnboardingStep) {
    if (step.isLastStep || !this.props.onboardingController) {
      return null;
    }
    const endStep = this.props.onboardingController.getEndStep();
    return (
      <Button
        onClick={
          endStep
            ? this.props.onOnboardingSetStep.bind(null, endStep)
            : this.props.onEndOnboarding
        }
        fill={"clear"}
        color={BridgeColor.gs600}
        onboardingDoNotBlock={true}
      >
        End the tour
      </Button>
    );
  }

  private $getPrevBtn(step: OnboardingStep) {
    if (!step.hasPrevButton) {
      return null;
    }
    return (
      <Button
        onClick={this.props.onOnboardingPrev}
        color={BridgeColor.gs800}
        onboardingDoNotBlock={true}
      >
        Previous
      </Button>
    );
  }

  private onLearnMore = () => {
    window.open("https://talksome.com/bridge", "_blank");
  };

  private onGetInTouch = () => {
    window.open("mailto:sales@talksome.com");
  };

  private $getNextBtn(step: OnboardingStep) {
    return (
      <Button
        onClick={this.props.onOnboardingNext}
        disabled={step.nextButtonDisabled}
        color={
          step.nextButtonDisabled ? BridgeColor.gs600 : BridgeColor.prim500
        }
        onboardingDoNotBlock={true}
      >
        {step.nextBtnString}
      </Button>
    );
  }
}

export interface IOnboardingTooltipComponentProps {
  step: OnboardingStepId | OnboardingStepId[];
  tippyProps?: Partial<TippyProps>;
  children: React.ReactElement;
}

interface IOnboardingTooltipProps
  extends IPropsFromState,
    IPropsFromDispatch,
    IOnboardingTooltipComponentProps {}

interface IPropsFromState {
  onboardingStep?: OnboardingStep;
  onboardingMode: boolean;
  onboardingController?: OnboardingController;
  realUserFeatures?: UserFeature[];
  onboardingType?: OnboardingType;
}

interface IPropsFromDispatch {
  onEndOnboarding: () => void;
  onOnboardingNext: () => void;
  onOnboardingPrev: () => void;
  onOnboardingSetStep: (step: OnboardingStep) => void;
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IRootState, void, AnyAction>
): IPropsFromDispatch => {
  return {
    onEndOnboarding: () => dispatch(endOnboarding()),
    onOnboardingNext: () => dispatch(onboardingNext()),
    onOnboardingPrev: () => dispatch(onboardingPrev()),
    onOnboardingSetStep: (step: OnboardingStep) =>
      dispatch(onboardingSetStep(step))
  };
};

const mapStateToProps = (state: IRootState): IPropsFromState => {
  return {
    onboardingStep: state.auth.onboardingStep,
    onboardingMode: state.auth.onboardingMode,
    onboardingType: state.auth.onboardingType,
    onboardingController: state.auth.onboardingController,
    realUserFeatures: state.auth.backup.features
  };
};

export default connect<IPropsFromState, IPropsFromDispatch>(
  mapStateToProps,
  mapDispatchToProps
)(OnboardingTooltip);
