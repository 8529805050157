import * as React from "react";
import SearchInput from "src/components/UI/SearchInput/SearchInput";
import Dialer, { DialerType } from "src/components/UI/Dialer/Dialer";
import Button from "src/components/UI/Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-solid-svg-icons/faTimes";
import { faTh } from "@fortawesome/pro-solid-svg-icons/faTh";
import "./TransferModal.scss";
import { IContact, ContactType } from "src/store/reducers/contacts";
import { IRootState } from "src/store/reducers";
import {
  User,
  Connection,
  OtherSide,
  UserCallPoint,
  CallPointType,
  Call,
  Side,
  CallState
} from "compass.js";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { notificationShow } from "src/store/actions";
import {
  resetDesiredTransferCall,
  redirectCall,
  startAttendedTransfer,
  mergeCalls,
  registerAttendedTransfer,
  cancelAttendedTransferDestination
} from "src/store/actions/calls";
import { connect } from "react-redux";
import {
  sortByProperty,
  sortIgnoreCaseComparator,
  cssSafeStr,
  stringContains
} from "src/utils";
import {
  canAuthenticatedUserUseFeature,
  getUserStatusInfo,
  UserStatus
} from "src/utils/user";
import { getUserSide } from "src/utils/call";
import { handleError } from "src/utils/errorHandler";
import { PhoneCapability, UserFeature } from "src/store/reducers/auth";
import { sortContacts, filterContacts } from "src/utils/contact";
import { TrackCategory, TrackAction } from "src/utils/track";
import * as Infinite from "react-infinite";
import {
  COMPACT_LIST_ITEM_HEIGHT,
  INFINITE_LIST_BREAKPOINT,
  BridgeColor
} from "src/utils/consts";
import { ListEmptyMsg } from "src/components/UI/List/";
import TransferDestinationItem from "./TransferDestinationItem/TransferDestinationItem";
import { CallMetadata } from "src/utils/CallMetadata";
import { wrapOnboarding } from "src/utils/onboarding";
import { OnboardingStepId } from "src/utils/OnboardingStep";

const TRACK_CATEGORY = TrackCategory.transferModal;

class TransferModal extends React.Component<
  ITransferModalProps,
  ITransferModalState
> {
  public state: ITransferModalState = {
    searchQuery: "",
    showDialer: false,
    waitingForConsultationCall: false
  };
  private $contentWrap: HTMLDivElement | null;

  public componentDidMount() {
    // NOTE: to render infinite list properly we need to render
    // wrapper element first, to get exact height
    this.forceUpdate();
  }

  render() {
    const cssClasses = ["transfer-modal__wrap"];
    if (this.state.activeItem || this.props.onboardingMode) {
      cssClasses.push("transfer-modal__wrap--has-active");
    }
    const $title = (
      <div className="transfer-modal__header-title">Transfer call</div>
    );
    return (
      <div className={cssClasses.join(" ")} onClick={this.onModalClick}>
        <div className="transfer-modal__header">
          <div className="transfer-modal__header-left">
            {wrapOnboarding($title, OnboardingStepId.modalTransfer, {
              placement: "right"
            })}
          </div>
          <div className="transfer-modal__header-buttons">
            <Button
              onClick={this.toggleDialler}
              fill={this.state.showDialer ? "solid" : "clear"}
              color={
                this.state.showDialer ? BridgeColor.gs1000 : BridgeColor.gs200
              }
              disabled={this.state.waitingForConsultationCall}
              small={true}
              icononly={true}
              track={[
                TRACK_CATEGORY,
                this.state.showDialer
                  ? TrackAction.transferModalDialerHide
                  : TrackAction.transferModalDialerShow
              ]}
            >
              <FontAwesomeIcon icon={faTh} />
            </Button>
            <Button
              onClick={this.props.onResetDesiredTransferCall}
              fill={"clear"}
              color={BridgeColor.gs200}
              small={true}
              icononly={true}
              track={[TRACK_CATEGORY, TrackAction.transferModalClose]}
            >
              <FontAwesomeIcon icon={faTimes} />
            </Button>
          </div>
        </div>
        <div
          className={`transfer-modal__search${
            this.state.showDialer ? " transfer-modal__search--hidden" : ""
          }`}
        >
          <SearchInput
            changed={this.onSearchInputChanged}
            value={this.state.searchQuery}
            alwaysOpened={true}
            expand={"full"}
            dark={true}
            autoFocus={true}
          />
        </div>
        <div className="transfer-modal__container">
          <div
            className="transfer-modal__content-wrap"
            ref={el => (this.$contentWrap = el)}
          >
            {this.$getContent()}
          </div>
        </div>
      </div>
    );
  }

  private $getContent = () => {
    if (this.state.showDialer) {
      return this.$getDialer();
    }
    return this.$getContacts();
  };

  private $getContacts = () => {
    if (!this.$contentWrap) {
      return null;
    }
    let contacts = this.props.contacts;
    let activeCalls = this.props.activeCalls.filter(
      call => getUserSide(call, this.props.user) !== null
    );
    if (this.state.searchQuery) {
      contacts = filterContacts(contacts, this.state.searchQuery);
      activeCalls = activeCalls.filter(call => {
        return stringContains(
          this.props.callsMetadata[call.id].lastConnectedTitle,
          this.state.searchQuery
        );
      });
    }
    let $items: React.ReactElement[] = [];
    if (activeCalls.length) {
      $items.push(
        <div
          key="active-calls-label"
          className="transfer-modal__contacts-label"
        >
          Current calls
        </div>
      );
      $items = $items.concat(activeCalls.map(this.$getCallItem));
    }

    contacts = sortContacts(contacts);
    if (contacts.length) {
      $items.push(
        <div key="contacts-label" className="transfer-modal__contacts-label">
          Contacts
        </div>
      );
      $items = $items.concat(contacts
        .map(this.$getContactItems)
        .flatMap(item => item)
        .filter(item => item !== null) as React.ReactElement[]);
    } else {
      $items.push(
        <ListEmptyMsg>
          {!this.props.contacts.length
            ? "You currently have no contacts."
            : `No contacts with "${this.state.searchQuery}" found.`}
        </ListEmptyMsg>
      );
    }

    return (
      <div className="transfer-modal__contacts">
        <Infinite
          containerHeight={this.$contentWrap.clientHeight}
          elementHeight={COMPACT_LIST_ITEM_HEIGHT}
          preloadAdditionalHeight={Infinite.containerHeightScaleFactor(2)}
        >
          {$items}
        </Infinite>
      </div>
    );
  };

  private isContactPinned(contact: IContact): boolean {
    return !!this.props.pinnedContacts.find(item => item === contact.id);
  }

  private toggleDialler = () => {
    this.setState({
      showDialer: !this.state.showDialer
    });
  };

  private onItemClick = (itemId: string, e: React.MouseEvent) => {
    if (this.props.onboardingMode) {
      return;
    }
    if (this.state.activeItem === itemId) {
      e.stopPropagation();
      return;
    }
    if (this.state.activeItem) {
      return;
    }
    this.setState({
      activeItem: itemId
    });
  };

  private onModalClick = (e: React.MouseEvent) => {
    if (this.props.onboardingMode || !this.state.activeItem) {
      return;
    }
    if (this.state.waitingForConsultationCall) {
      this.props.onCancelAttendedTransferDestination();
    }
    this.setState({
      activeItem: undefined,
      waitingForConsultationCall: false
    });
  };

  private $getContactItems = (
    contact: IContact,
    contactIdx: number
  ): Array<React.ReactElement | null> => {
    let userCallState: UserStatus = UserStatus.loggedOut;
    if (contact.type === ContactType.compass) {
      userCallState = getUserStatusInfo(this.props.users[contact.id])
        .userStatus;
    }
    return contact.phones.map((phone, idx) => {
      const itemId = `transfer-modal__contact-item-${contact.id}-${idx}`;
      let isActive = this.state.activeItem === itemId;
      if (this.props.onboardingMode) {
        isActive = idx === 0 && contactIdx === 0;
      }
      return (
        <TransferDestinationItem
          key={itemId}
          id={itemId}
          click={this.onItemClick}
          active={isActive}
          title={contact.name}
          number={phone.value}
          status={userCallState}
          directTransferClick={this.onDirectTransfer}
          directTransferDisabled={
            this.props.callsIsLoading || this.props.onboardingMode
          }
          attendedTransferClick={this.onAttendedTransfer}
          attendedTransferDisabled={this.props.callsIsLoading}
          isPinned={this.isContactPinned(contact)}
          showOnboarding={idx === 0 && contactIdx === 0}
          elementId={cssSafeStr(itemId)}
          showDialerCallCTA={
            isActive &&
            this.state.waitingForConsultationCall &&
            !this.props.userCanAnswerCalls
          }
        />
      );
    });
  };

  private $getCallItem = (call: Call): React.ReactElement => {
    // NOTE: this component contains only user's call
    // so `getUserSide` will always return value
    const selfPoint = getUserSide(call, this.props.user) as Side;
    const otherPoint = call.getEndpoint(OtherSide(selfPoint));
    const callTitle = this.props.callsMetadata[call.id].lastConnectedTitle;
    const callNumber = this.props.callsMetadata[call.id].lastConnectedNumber;
    let userCallState = UserStatus.loggedOut;
    if (otherPoint.type === CallPointType.user) {
      userCallState = getUserStatusInfo((otherPoint as UserCallPoint).getUser())
        .userStatus;
    }
    const itemId = `transfer-modal__call-item-${call.id}`;
    const isActive = this.state.activeItem === itemId;
    return (
      <TransferDestinationItem
        active={isActive}
        id={itemId}
        key={itemId}
        click={this.onItemClick}
        title={callTitle}
        number={callNumber}
        callId={call.id}
        status={userCallState}
        directTransferClick={this.onDirectTransfer}
        directTransferDisabled={
          this.props.callsIsLoading || this.props.onboardingMode
        }
        attendedTransferClick={this.onAttendedTransfer}
        attendedTransferDisabled={this.props.callsIsLoading}
        elementId={cssSafeStr(itemId)}
      />
    );
  };

  private $getDialer = () => {
    return (
      <div className="transfer-modal__dialer-wrap">
        {this.state.waitingForConsultationCall &&
        !this.props.userCanAnswerCalls ? (
          <div className="transfer-modal__dialer-exclamation">
            The call you are getting is an automated call from dialler. Please
            answer it manually to continue with the attended transfer.
            <div className="transfer-modal__dialer-exclamation-btn">
              <Button
                small={true}
                onClick={this.props.onCancelAttendedTransferDestination}
                color={BridgeColor.gs800}
              >
                Cancel
              </Button>
            </div>
          </div>
        ) : null}
        <Dialer
          type={DialerType.transfer}
          dark={true}
          onDirectTransfer={this.onDirectTransfer}
          onAttendedTransfer={this.onAttendedTransfer}
        />
      </div>
    );
  };

  private onDirectTransfer = (
    destination: string,
    e?: React.MouseEvent,
    callId?: Call["id"]
  ) => {
    if (e) {
      e.stopPropagation();
    }
    if (!this.props.desiredTransferCallId) {
      return;
    }
    if (callId) {
      this.props
        .onMergeCalls(callId, this.props.desiredTransferCallId)
        .then(() => {
          this.props.onResetDesiredTransferCall();
        }, handleError);
    } else {
      this.props
        .onRedirectCall(this.props.desiredTransferCallId, destination)
        .then(() => {
          this.props.onNotificationShow({
            message: "The call is successfully transferred.",
            level: "success",
            autoDismiss: 3000
          });
        }, handleError);
    }
  };

  private onAttendedTransfer = async (
    destination: string,
    e?: React.MouseEvent,
    callId?: Call["id"]
  ) => {
    if (e) {
      e.stopPropagation();
    }
    if (!this.props.desiredTransferCallId) {
      return;
    }
    if (callId) {
      this.props.onRegisterAttendedTransfer(
        this.props.desiredTransferCallId,
        callId
      );
    } else {
      this.setState({
        waitingForConsultationCall: true
      });
      try {
        await this.props.onStartAttendedTransfer(destination);
      } catch (error) {
        handleError(error);
      } finally {
        this.setState({
          waitingForConsultationCall: false
        });
      }
    }
  };

  private onSearchInputChanged = (value: string) => {
    this.setState({
      searchQuery: value,
      showDialer: false
    });
  };
}

interface ITransferModalState {
  searchQuery: string;
  mobileDetailsShouldHide?: boolean;
  showDialer: boolean;
  waitingForConsultationCall: boolean;
  activeItem?: string;
}

interface ITransferModalProps extends IPropsFromState, IPropsFromDispatch {}

interface IPropsFromState {
  contacts: IContact[];
  pinnedContacts: string[];
  user: User;
  users: { [key: string]: User };
  desiredTransferCallId: string | undefined;
  callsIsLoading: boolean;
  useInfiniteList: boolean;
  userCanAnswerCalls: boolean;
  activeCalls: Call[];
  callsMetadata: { [key: string]: CallMetadata };
  onboardingMode: boolean;
}

interface IPropsFromDispatch {
  onNotificationShow: typeof notificationShow;
  onResetDesiredTransferCall: () => void;
  onCancelAttendedTransferDestination: () => void;
  onRedirectCall: (callId: string, destination: string) => Promise<any>;
  onStartAttendedTransfer: (destination: string) => Promise<any>;
  onMergeCalls: (callId1: Call["id"], callId2: Call["id"]) => Promise<any>;
  onRegisterAttendedTransfer: (
    callId1: Call["id"],
    callId2: Call["id"]
  ) => void;
}

const mapStateToProps = (state: IRootState): IPropsFromState => {
  const transferCall = state.calls.items.find(
    item => item.id === state.calls.desiredTransferCallId
  );
  let transferCallUser: User;
  if (transferCall) {
    const selfSide = getUserSide(transferCall, state.auth.user as User);
    if (selfSide !== null) {
      const otherCallPoint = transferCall.getEndpoint(OtherSide(selfSide));
      if (otherCallPoint.type === CallPointType.user) {
        transferCallUser = (otherCallPoint as UserCallPoint).getUser();
      }
    }
  }
  const allContacts = {
    ...state.contacts.compassItems,
    ...state.contacts.addressBookItems
  };
  const contacts = sortByProperty<IContact, string>(
    Object.values(allContacts).filter(contact => {
      // NOTE: remove main call caller from contacts list
      if (
        transferCallUser &&
        contact.type === ContactType.compass &&
        contact.id === transferCallUser.id
      ) {
        return false;
      }
      return !(
        contact.type === ContactType.compass &&
        contact.id === (state.auth.user as User).id
      );
    }),
    "name",
    sortIgnoreCaseComparator
  ).filter(item => !!item.phones.length);
  let userCanAnswerCalls = false;
  if (state.auth.phone) {
    userCanAnswerCalls =
      state.auth.phone.capabilities.includes(PhoneCapability.answer) &&
      canAuthenticatedUserUseFeature(UserFeature.callcontrol);
  }
  return {
    contacts,
    pinnedContacts: state.contacts.pinned.filter(
      item => !!(allContacts[item] && allContacts[item].phones.length)
    ),
    users: (state.auth.connection as Connection).model.users,
    desiredTransferCallId: state.calls.desiredTransferCallId,
    user: state.auth.user as User,
    callsIsLoading: !!state.calls.actionsInProgress,
    useInfiniteList: contacts.length > INFINITE_LIST_BREAKPOINT,
    userCanAnswerCalls,
    callsMetadata: state.calls.callsMetadata,
    activeCalls: state.calls.items.filter(item => {
      return (
        item.id !== state.calls.desiredTransferCallId &&
        (item.state === CallState.answered ||
          item.state === CallState.on_hold) &&
        !state.calls.callsMetadata[item.id].listeningToCall
      );
    }),
    onboardingMode: state.auth.onboardingMode
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IRootState, void, AnyAction>
): IPropsFromDispatch => {
  return {
    onNotificationShow: notification =>
      dispatch(notificationShow(notification)),
    onResetDesiredTransferCall: () => dispatch(resetDesiredTransferCall()),
    onRedirectCall: (callId: string, destination: string) =>
      dispatch(redirectCall(callId, destination)),
    onStartAttendedTransfer: (destination: string) =>
      dispatch(startAttendedTransfer(destination)),
    onMergeCalls: (callId1: Call["id"], callId2: Call["id"]) =>
      dispatch(mergeCalls(callId1, callId2)),
    onRegisterAttendedTransfer: (callId1: Call["id"], callId2: Call["id"]) =>
      dispatch(registerAttendedTransfer(callId1, callId2)),
    onCancelAttendedTransferDestination: () =>
      dispatch(cancelAttendedTransferDestination())
  };
};

export default connect<IPropsFromState, IPropsFromDispatch>(
  mapStateToProps,
  mapDispatchToProps
)(TransferModal);
