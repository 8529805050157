import * as React from "react";
import Button, { IButtonProps } from "src/components/UI/Button/Button";
import { faShare } from "@fortawesome/pro-solid-svg-icons/faShare";
import { faPause } from "@fortawesome/pro-solid-svg-icons/faPause";
import { faPhone } from "@fortawesome/pro-solid-svg-icons/faPhone";
import { faTimes } from "@fortawesome/pro-solid-svg-icons/faTimes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./CallItem.scss";
import {
  Call,
  CallState,
  Side,
  UserCallPoint,
  CallPoint,
  CallPointType,
  CallPointState,
  User,
  OtherSide,
  Queue
} from "compass.js";
import { IRootState } from "src/store/reducers";
import { connect } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { phoneAction, PhoneActionType } from "src/store/actions";
import { getUserSide, getCallPointTitle } from "src/utils/call";
import { handleError } from "src/utils/errorHandler";
import { setAsDesiredTransferCall } from "src/store/actions/calls";
import { PhoneCapability, UserFeature } from "src/store/reducers/auth";
import {
  NavigationHomeList,
  IHomePageParams,
  NavigationPage
} from "src/store/reducers/navigation";
import {
  navigationSet,
  navigationUpdateParams
} from "src/store/actions/navigation";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { OnboardingStepId } from "src/utils/OnboardingStep";
import { TrackAction, TrackCategory } from "src/utils/track";
import { faDeaf } from "@fortawesome/pro-regular-svg-icons/faDeaf";
import { faEar } from "@fortawesome/pro-regular-svg-icons/faEar";
import { faGlasses } from "@fortawesome/pro-regular-svg-icons/faGlasses";
import { Tooltip } from "src/components/UI/Tooltip/Tooltip";
import { faTh } from "@fortawesome/pro-solid-svg-icons/faTh";
import { BridgeColor } from "src/utils/consts";
import CallDetailCounter, {
  CallDetailCounterType
} from "src/components/CallDetailCounter/CallDetailCounter";

const TRACK_CATEGORY = TrackCategory.callItem;

enum CallItemState {
  answered = "answered",
  attended_transfer = "attended_transfer",
  paused = "paused",
  default = "default"
}

class CallItem extends React.PureComponent<ICallItemProps, ICallItemState> {
  public state: ICallItemState = {
    listenedByCalls: []
  };

  public render() {
    return (
      <div className="call-item__wrapper">
        {this.props.listenedCallTooltip ? (
          <Tooltip content={this.props.listenedCallTooltip} enabled={true}>
            <div className="call-item__listened-call-icon">
              <FontAwesomeIcon icon={faGlasses} />
            </div>
          </Tooltip>
        ) : null}
        <div className={this.getElementClass()}>
          {this.$getHeader()}
          {this.$getFooter()}
        </div>
      </div>
    );
  }

  private getElementClass(): string {
    const cssClasses = ["call-item", `call-item--${this.getCallState()}`];
    if (this.props.isHighlighted) {
      cssClasses.push("call-item--highlighted");
    }
    if (this.props.isListened) {
      cssClasses.push("call-item--listened");
    }
    return cssClasses.join(" ");
  }

  private getCallState(): CallItemState {
    if (this.props.callState === CallState.answered) {
      if (this.props.selfCallPointState === CallPointState.inactive) {
        return CallItemState.paused;
      }
      return CallItemState.answered;
    }
    return CallItemState.default;
  }

  private $getHeader() {
    const headerNameCssClasses = ["call-item__header-name"];
    let onUserNameClick: (() => void) | undefined;
    if (this.props.otherCallPointUserId) {
      headerNameCssClasses.push("call-item__header-name--clickable");
      onUserNameClick = () => {
        if (this.props.otherCallPointUserId) {
          this.onUserNameClick(this.props.otherCallPointUserId);
        }
      };
    }
    return (
      <div className="call-item__header">
        <div
          className={headerNameCssClasses.join(" ")}
          onClick={onUserNameClick}
        >
          {this.props.isListenIn ? (
            <Tooltip content={"Listening in on the call"} enabled={true}>
              <span>
                <FontAwesomeIcon icon={faEar} />
              </span>
            </Tooltip>
          ) : null}
          {this.props.lastConnectedTitle}
        </div>
        <div className="call-item__header-contact">
          {this.props.otherCallPointUserId ? (
            <div className="call-item__header-contact-company">Internal</div>
          ) : null}
          <div className="call-item__header-contact-number">
            {this.props.numberDisplay}
          </div>
          {!this.props.isEnded &&
          this.props.callState === CallState.answered ? (
            <div className="call-item__header-duration">
              <CallDetailCounter
                userId={this.props.userId}
                callId={this.props.callId}
                type={CallDetailCounterType.userDuration}
                precise={true}
              />
            </div>
          ) : null}
        </div>
      </div>
    );
  }

  private $getFooter() {
    let callInfoTxt = "-";
    let onCallInfoClick: (() => void) | undefined;
    // NOTE: don't show Connecting/Ringing for incoming calls
    if (this.props.isEnded) {
      callInfoTxt = "Call ended";
    } else if (
      this.props.selfSide === Side.source &&
      this.props.callState === CallState.connecting
    ) {
      callInfoTxt = "Connecting ...";
    } else if (
      this.props.selfSide === Side.source &&
      this.props.callState === CallState.ringing
    ) {
      callInfoTxt = "Ringing ...";
    } else if (this.props.isAttendedTransferConsultationLeg) {
      callInfoTxt = "Attended transfer";
    } else if (this.props.queueId) {
      callInfoTxt = this.props.queueName || "";
      onCallInfoClick = () => {
        if (!this.props.queueId) {
          return;
        }
        this.onQueueNameClick(this.props.queueId);
      };
    } else {
      callInfoTxt = "Direct call";
    }
    const infoTxtCssClasses = ["call-item__footer-info-txt"];
    if (onCallInfoClick) {
      infoTxtCssClasses.push("call-item__footer-info-txt--clickable");
    }
    return (
      <div className="call-item__footer">
        <div className={infoTxtCssClasses.join(" ")} onClick={onCallInfoClick}>
          {callInfoTxt}
        </div>
        <div className="call-item__footer-buttons">{this.$getButtons()}</div>
      </div>
    );
  }

  private onQueueNameClick = (queueId: Queue["id"]) => {
    this.props.onNavigationSet(NavigationPage.home, {
      list: NavigationHomeList.queues,
      detailsOpened: true,
      dialerActive: false,
      detailsId: queueId
    });
  };

  private onUserNameClick = (userId: User["id"]) => {
    this.props.onNavigationSet(NavigationPage.home, {
      list: NavigationHomeList.contacts,
      detailsOpened: true,
      dialerActive: false,
      detailsId: userId
    });
  };

  private $getButtons(): React.ReactElement[] {
    if (this.props.isEnded) {
      return [];
    }
    if (this.props.isListenIn) {
      return this.$getListenInToCallButtons();
    }
    switch (this.props.selfCallPointState) {
      case CallPointState.answered:
      case CallPointState.inactive:
        if (this.props.otherCallPointState === CallPointState.ringing) {
          return this.$getOutgoingButtons();
        }
        return this.$getAnsweredButtons();
      case CallPointState.ringing:
        return this.$getRingingButtons();
      case CallPointState.connecting:
        return [this.$getHangupBtn()];
      default:
        return [];
    }
  }

  private $getListenInToCallButtons(): React.ReactElement[] {
    return [this.$getHoldBtn(), this.$getHangupBtn(faDeaf)];
  }

  private $getOutgoingButtons(): React.ReactElement[] {
    const buttons: React.ReactElement[] = [];
    buttons.push(this.$getHangupBtn());
    return buttons;
  }

  private $getAnsweredButtons(): React.ReactElement[] {
    return [
      ...(this.props.hideDtmfBtn ? [] : [this.$getDtmfBtn()]),
      this.$getHoldBtn(),
      ...(this.props.hideTransferBtn ? [] : [this.$getTransferBtn()]),
      this.$getHangupBtn()
    ];
  }

  private $getHoldBtn(): React.ReactElement {
    return this.props.selfCallPointState === CallPointState.inactive ? (
      <Button
        key="hold-btn"
        icononly={true}
        color={BridgeColor.gs900}
        requiredFeature={UserFeature.callcontrol}
        disabled={
          this.props.callsIsLoading ||
          !this.props.hasUnHoldCapability ||
          !this.props.online
        }
        onClick={this.buttonClicked.bind(null, PhoneActionType.unholdCall)}
        onboardingStep={OnboardingStepId.callUnhold}
        track={[TRACK_CATEGORY, TrackAction.callItemUnhold]}
      >
        <FontAwesomeIcon icon={faPause} />
      </Button>
    ) : (
      <Button
        key="hold-btn"
        icononly={true}
        color={BridgeColor.gs300}
        requiredFeature={UserFeature.callcontrol}
        disabled={
          this.props.callsIsLoading ||
          !this.props.hasHoldCapability ||
          !this.props.online
        }
        onboardingStep={OnboardingStepId.callHold}
        onClick={this.buttonClicked.bind(null, PhoneActionType.holdCall)}
        track={[TRACK_CATEGORY, TrackAction.callItemHold]}
      >
        <FontAwesomeIcon icon={faPause} />
      </Button>
    );
  }

  private $getRingingButtons(): React.ReactElement[] {
    return [
      this.$getHangupBtn(),
      <Button
        key="ringing-btn"
        icononly={true}
        color={BridgeColor.green500}
        fill={"fade"}
        requiredFeature={UserFeature.callcontrol}
        disabled={
          this.props.callsIsLoading ||
          !this.props.hasAnswerCapability ||
          !this.props.online
        }
        onboardingStep={OnboardingStepId.callAnswer}
        onClick={this.buttonClicked.bind(null, PhoneActionType.answerCall)}
        track={[TRACK_CATEGORY, TrackAction.callItemAnswer]}
      >
        <FontAwesomeIcon icon={faPhone} />
      </Button>
    ];
  }

  private $getHangupBtn(
    icon: IconDefinition = faTimes,
    color: IButtonProps["color"] = BridgeColor.red500,
    fill: IButtonProps["fill"] = "fade"
  ): React.ReactElement {
    // NOTE: disable ability to hangup queue dial attempt
    let tooltipMessage: string = "";
    const isQueueDialAttempt = !!this.props.queueId && this.props.hasParentCall;
    if (isQueueDialAttempt) {
      tooltipMessage = "Queue calls cannot be rejected";
    }
    return (
      <Button
        key="hangup-btn"
        icononly={true}
        color={color}
        fill={fill}
        disabled={
          this.props.callsIsLoading || isQueueDialAttempt || !this.props.online
        }
        tooltip={tooltipMessage}
        onClick={this.buttonClicked.bind(null, PhoneActionType.hangupCall)}
        track={[TRACK_CATEGORY, TrackAction.callItemHangup]}
      >
        <FontAwesomeIcon icon={icon} />
      </Button>
    );
  }

  private $getTransferBtn(): React.ReactElement {
    return (
      <Button
        key="transfer-btn"
        icononly={true}
        className={
          this.props.isAttendedTransferMainLeg
            ? "button--not-clickable"
            : undefined
        }
        color={
          this.props.isAttendedTransferMainLeg
            ? BridgeColor.gs800
            : BridgeColor.gs300
        }
        disabled={
          this.props.callsIsLoading ||
          !this.props.online ||
          !!this.props.desiredTransferCallId
        }
        onClick={
          this.props.isAttendedTransferMainLeg
            ? undefined
            : this.setAsDesiredTransferCall
        }
        onboardingStep={OnboardingStepId.callTransfer}
        track={[TRACK_CATEGORY, TrackAction.callItemTransfer]}
      >
        <FontAwesomeIcon icon={faShare} />
      </Button>
    );
  }

  private $getDtmfBtn(): React.ReactElement {
    return (
      <Button
        key="dtmf-btn"
        icononly={true}
        track={[TRACK_CATEGORY, TrackAction.callItemDtmf]}
        onClick={this.toggleDtmf}
        color={
          this.props.dtmfDialerOpened ? BridgeColor.gs800 : BridgeColor.gs300
        }
      >
        <FontAwesomeIcon icon={faTh} />
      </Button>
    );
  }

  private toggleDtmf = () => {
    const open = !this.props.dtmfDialerOpened;
    this.props.onNavigationUpdateParams({
      dialerActive: open,
      callIdForDtmf: open ? this.props.callId : undefined,
      detailsOpened: false
    });
  };

  private setAsDesiredTransferCall = () => {
    this.props.onSetAsDesiredTransferCall(this.props.callId);
  };

  private buttonClicked = (action: PhoneActionType) => {
    this.props.onPhoneAction(this.props.callId, action).catch(handleError);
  };
}

export interface ICallItemProps extends IPropsFromState, IPropsFromDispatch {
  callId: Call["id"];
  hideTransferBtn?: boolean;
  hideDtmfBtn?: boolean;
}

interface ICallItemState {
  listenedByCalls: Call[];
}

interface IPropsFromState {
  userId: User["id"];
  callsIsLoading: boolean;
  isHighlighted: boolean;
  online: boolean;
  onboardingMode: boolean;
  dtmfDialerOpened: boolean;
  numberDisplay: string;
  selfSide: Side;
  isListened: boolean;
  isListenIn: boolean;
  listenedCallTooltip?: string;
  isEnded: boolean;
  lastConnectedTitle: string;
  isAttendedTransferConsultationLeg: boolean;
  isAttendedTransferMainLeg: boolean;
  queueId?: Queue["id"];
  queueName?: Queue["name"];
  hasHoldCapability: boolean;
  hasUnHoldCapability: boolean;
  hasAnswerCapability: boolean;
  callState: Call["state"];
  hasParentCall: boolean;
  selfCallPointState: CallPoint["state"];
  otherCallPointState: CallPoint["state"];
  otherCallPointType: CallPoint["type"];
  otherCallPointUserId?: User["id"];
  desiredTransferCallId: string | undefined;
}

interface IPropsFromDispatch {
  onPhoneAction: (callId: Call["id"], action: PhoneActionType) => Promise<any>;
  onSetAsDesiredTransferCall: (id: string) => void;
  onNavigationSet: (
    page: NavigationPage,
    params: Partial<IHomePageParams>
  ) => void;
  onNavigationUpdateParams: (params: Partial<IHomePageParams>) => void;
}

const mapStateToProps = (
  state: IRootState,
  componentProps: ICallItemProps
): IPropsFromState => {
  const user = state.auth.user as User;
  const callMetadata = state.calls.callsMetadata[componentProps.callId];
  const selfSide = getUserSide(callMetadata.call, user) as Side;
  const selfCallPoint = callMetadata.call.getEndpoint(selfSide);
  const otherCallPoint = callMetadata.call.getEndpoint(OtherSide(selfSide));
  let otherCallPointUserId: User["id"] | undefined;
  if (otherCallPoint.type === CallPointType.user) {
    otherCallPointUserId = (otherCallPoint as UserCallPoint).getUser().id;
  }

  if (selfSide === null) {
    throw new Error(
      `CallItem component supports only calls with authenticated user side`
    );
  }

  let numberDisplay = callMetadata.lastConnectedNumber;
  if (callMetadata.listeningToCall) {
    const listeningToCallMetadata =
      state.calls.allCallsMetadata[callMetadata.listeningToCall.id];
    numberDisplay = `calling with ${
      listeningToCallMetadata
        ? listeningToCallMetadata.destinationLastConnectedTitle ||
          listeningToCallMetadata.destinationLastConnectedNumber ||
          "unknown"
        : "unknown"
    }`;
  }

  const listenedByCalls = callMetadata.listenedByCalls;
  let listenedCallTooltip: string | undefined;
  if (listenedByCalls.length) {
    listenedCallTooltip = `${listenedByCalls
      .map(item => getCallPointTitle(item.destination))
      .join(", ")} ${
      listenedByCalls.length === 1 ? "is" : "are"
    } listening in on your call.`;
  }
  const phoneCapabilities = state.auth.phone
    ? state.auth.phone.capabilities
    : [];
  return {
    userId: user.id,
    callsIsLoading: state.calls.actionsInProgress > 0,
    isHighlighted: state.calls.highlightedCallIds.includes(
      componentProps.callId
    ),
    online: state.window.online,
    onboardingMode: state.auth.onboardingMode,
    dtmfDialerOpened:
      !!state.navigation.params &&
      state.navigation.params.dialerActive &&
      state.navigation.params.callIdForDtmf === componentProps.callId,
    numberDisplay,
    selfSide,
    isListened: listenedByCalls.length > 0,
    isListenIn: !!callMetadata.listeningToCall,
    listenedCallTooltip,
    isEnded: callMetadata.isEnded,
    lastConnectedTitle: callMetadata.lastConnectedTitle,
    isAttendedTransferConsultationLeg:
      callMetadata.isAttendedTransferConsultationLeg,
    isAttendedTransferMainLeg: callMetadata.isAttendedTransferMainLeg,
    queueId: callMetadata.queue ? callMetadata.queue.id : undefined,
    queueName: callMetadata.queue ? callMetadata.queue.name : undefined,
    hasHoldCapability: phoneCapabilities.includes(PhoneCapability.hold),
    hasUnHoldCapability: phoneCapabilities.includes(PhoneCapability.unhold),
    hasAnswerCapability: phoneCapabilities.includes(PhoneCapability.answer),
    callState: callMetadata.call.state,
    hasParentCall: !!callMetadata.call.parentCall,
    selfCallPointState: selfCallPoint.state,
    otherCallPointState: otherCallPoint.state,
    otherCallPointType: otherCallPoint.type,
    otherCallPointUserId,
    desiredTransferCallId: state.calls.desiredTransferCallId
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IRootState, void, AnyAction>
): IPropsFromDispatch => {
  return {
    onPhoneAction: (callId: Call["id"], action: PhoneActionType) =>
      dispatch(phoneAction(callId, action)),
    onSetAsDesiredTransferCall: (id: string) =>
      dispatch(setAsDesiredTransferCall(id)),
    onNavigationSet: (page: NavigationPage, params: Partial<IHomePageParams>) =>
      dispatch(navigationSet(page, params)),
    onNavigationUpdateParams: (params: Partial<IHomePageParams>) =>
      dispatch(navigationUpdateParams(params))
  };
};

export default connect<IPropsFromState, IPropsFromDispatch>(
  mapStateToProps,
  mapDispatchToProps
)(CallItem);
