import * as React from "react";
import Input from "src/components/UI/Input/Input";
import Button from "src/components/UI/Button/Button";
import { Loader } from "src/components/UI/Loader/Loader";
import "./Login.scss";
import { connect } from "react-redux";
import { IRootState } from "src/store/reducers";
import {
  intlShape,
  injectIntl,
  FormattedMessage,
  InjectedIntlProps
} from "react-intl";
import { navigationSet } from "src/store/actions/navigation";
import { IHomePageParams, NavigationPage } from "src/store/reducers/navigation";
import { IUserPreferences } from "src/store/preferences";
import logoImg from "src/assets/imgs/bridge-logo-light.svg";
import packageJson from "../../../package.json";
import { notificationsDismissAll, notificationShow } from "src/store/actions";
import { login, beginOnboarding } from "src/store/actions/auth";
import { OnboardingType } from "src/store/reducers/auth";
import { BridgeColor } from "src/utils/consts";

class Login extends React.Component<ILoginProps> {
  public static propTypes: React.ValidationMap<any> = {
    intl: intlShape.isRequired
  };

  public state: ILoginState = {
    loginForm: {
      username: {
        elementType: "input",
        elementConfig: {
          type: "text",
          placeholder: this.props.intl
            .formatMessage({ id: "labels.username" })
            .toString()
        },
        label: this.props.intl
          .formatMessage({ id: "labels.username" })
          .toString(),
        value: "",
        validation: {
          required: true
        },
        valid: false,
        touched: false
      },
      password: {
        elementType: "input",
        elementConfig: {
          type: "password",
          placeholder: this.props.intl
            .formatMessage({ id: "labels.password" })
            .toString()
        },
        label: this.props.intl
          .formatMessage({ id: "labels.password" })
          .toString(),
        value: "",
        validation: {
          required: true
        },
        valid: false,
        touched: false
      }
    },
    formIsValid: false
  };

  public checkValidity(value: any, rules: any) {
    let isValid = true;
    if (!rules) {
      return true;
    }

    if (rules.required) {
      isValid = value.trim() !== "" && isValid;
    }

    return isValid;
  }

  public loginHandler = (event: any) => {
    event.preventDefault();
    this.props.onNotificationsDismissAll();
    const formData: any = {};
    for (const formElementIdentifier of Object.keys(this.state.loginForm)) {
      formData[formElementIdentifier] = this.state.loginForm[
        formElementIdentifier
      ].value;
    }
    if (!this.state.formIsValid) {
      let message: string | undefined;
      if (!formData.username && !formData.password) {
        message = "Enter your username and password to sign in.";
      } else if (!formData.username) {
        message = "Username field cannot be empty.";
      } else if (!formData.password) {
        message = "Enter your password to sign in.";
      }
      if (message) {
        this.props.onNotificationShow({
          message,
          autoDismiss: 3000,
          level: "danger"
        });
      }
      return;
    }
    this.props.onLogin(formData.username, formData.password).then(loggedIn => {
      if (loggedIn) {
        const params: IHomePageParams = {
          list: this.props.preferences.defaultHomeList,
          dialerActive: false,
          detailsOpened: false
        };
        this.props.onNavigationSet(NavigationPage.home, params);
      }
    });
  };

  public inputChangedHandler = (event: any, inputIdentifier: any) => {
    const updatedOrderForm = {
      ...this.state.loginForm
    };
    const updatedFormElement = {
      ...updatedOrderForm[inputIdentifier]
    };
    updatedFormElement.value = event.target.value;
    updatedFormElement.valid = this.checkValidity(
      updatedFormElement.value,
      updatedFormElement.validation
    );
    updatedFormElement.touched = true;
    updatedOrderForm[inputIdentifier] = updatedFormElement;

    let formIsValid = true;
    Object.keys(updatedOrderForm).forEach((key: string) => {
      formIsValid = updatedOrderForm[key].valid && formIsValid;
    });
    this.setState({ loginForm: updatedOrderForm, formIsValid });
  };

  public render() {
    const formElementsArray: any[] = [];
    Object.keys(this.state.loginForm).forEach((key: string) => {
      formElementsArray.push({
        id: key,
        config: this.state.loginForm[key]
      });
    });

    const inputs = formElementsArray.map((formElement, idx) => (
      <Input
        key={idx}
        elementId={formElement.id}
        elementType={formElement.config.elementType}
        elementConfig={formElement.config.elementConfig}
        value={formElement.config.value}
        invalid={!formElement.config.valid}
        shouldValidate={formElement.config.validation}
        touched={formElement.config.touched}
        label={formElement.config.label}
        changed={this.inputChangedHandler}
      />
    ));

    return (
      <div className="login">
        <div className="login__content">
          <div className="login__box">
            <div className="login__header">
              <div className="login__header-logo">
                <img src={logoImg} />
              </div>
              <div className="login__header-version">
                Version {packageJson.version}
              </div>
            </div>
            <form className="br-form login__form" onSubmit={this.loginHandler}>
              <div>{inputs}</div>
              <div className="login__form-submit-btn">
                {this.props.loginInProgress ? (
                  <Loader />
                ) : (
                  <Button
                    color={BridgeColor.prim500}
                    disabled={!this.props.online}
                  >
                    <FormattedMessage id="login.btn" defaultMessage="Sign in" />
                  </Button>
                )}
              </div>
              <div className="login__form-submit-btn">
                <Button
                  color={BridgeColor.gs400}
                  fill={"clear"}
                  disabled={this.props.loginInProgress}
                  onClick={this.onDemoClick}
                >
                  <FormattedMessage
                    id="login.demo-btn"
                    defaultMessage="Show me around"
                  />
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }

  private onDemoClick = (e: React.MouseEvent) => {
    e.preventDefault();
    this.props.onBeginOnboarding(OnboardingType.guest);
  };
}

interface ILoginState {
  // TODO: types for form inputs
  loginForm: any;
  formIsValid: boolean;
}

interface IPropsFromState {
  isAuthenticated: boolean;
  preferences: IUserPreferences;
  loginInProgress: boolean;
  online: boolean;
}

interface IPropsFromDispatch {
  onLogin: (username: string, password: string) => Promise<boolean>;
  onNavigationSet: (page: NavigationPage, params: any) => void;
  onNotificationsDismissAll: () => void;
  onNotificationShow: typeof notificationShow;
  onBeginOnboarding: typeof beginOnboarding;
}

interface ILoginProps
  extends IPropsFromState,
    IPropsFromDispatch,
    InjectedIntlProps {}

const mapStateToProps = (state: IRootState): IPropsFromState => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    preferences: state.preferences.user,
    loginInProgress: state.auth.loginInProgress,
    online: state.window.online
  };
};

const mapDispatchToProps = (dispatch: any): IPropsFromDispatch => {
  return {
    onLogin: (username: string, password: string) =>
      dispatch(login(username, password)),
    onNavigationSet: (page: NavigationPage, params: any) =>
      dispatch(navigationSet(page, params)),
    onNotificationsDismissAll: () => dispatch(notificationsDismissAll()),
    onNotificationShow: notification =>
      dispatch(notificationShow(notification)),
    onBeginOnboarding: type => dispatch(beginOnboarding(type))
  };
};

export default connect<IPropsFromState, IPropsFromDispatch>(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(Login));
