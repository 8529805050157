import * as React from "react";
import "./ContactDetailsCalls.scss";
import { List, ListEmptyMsg } from "src/components/UI/List/";
import { IRootState } from "src/store/reducers";
import { connect } from "react-redux";
import "./ContactDetailsCalls.scss";
import { Call, User } from "compass.js";
import { ContactType } from "src/store/reducers/contacts";
import ContactDetailsCallItem from "../ContactDetailsCallItem/ContactDetailsCallItem";
import { shouldShowCallForUser } from "src/utils/call";
import { isPurePropsEqual } from "src/utils";

class ContactDetailsCalls extends React.Component<IContactsDetailsCallsProps> {
  shouldComponentUpdate(nextProps: IContactsDetailsCallsProps) {
    if (!isPurePropsEqual(this.props, nextProps)) {
      return true;
    }
    return (
      JSON.stringify(nextProps.contactCallsIds) !==
      JSON.stringify(this.props.contactCallsIds)
    );
  }

  public render() {
    if (!this.props.contactCallsIds.length) {
      return (
        <List>
          <ListEmptyMsg>No active calls</ListEmptyMsg>
        </List>
      );
    }
    return (
      <List>
        {this.props.contactCallsIds.map(callId => (
          <ContactDetailsCallItem
            key={callId}
            contactId={this.props.contactId}
            callId={callId}
          />
        ))}
      </List>
    );
  }
}

interface IPropsFromState {
  contactCallsIds: Array<Call["id"]>;
}

interface IContactsDetailsCallsProps extends IPropsFromState {
  contactId: string;
}

const mapStateToProps = (
  state: IRootState,
  componentProps: IContactsDetailsCallsProps
): IPropsFromState => {
  const contact =
    state.contacts.compassItems[componentProps.contactId] ||
    state.contacts.addressBookItems[componentProps.contactId];
  let contactCallsIds: Array<Call["id"]> = [];
  if (contact.type === ContactType.compass && contact.user) {
    contactCallsIds = contact.user
      .getCalls()
      .filter(item => shouldShowCallForUser(item, contact.user as User))
      // NOTE: make sure call have metadata
      .filter(item => !!state.calls.allCallsMetadata[item.id])
      .map(item => item.id);
  }
  return {
    contactCallsIds
  };
};

export default connect<IPropsFromState>(mapStateToProps)(ContactDetailsCalls);
