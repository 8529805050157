import * as React from "react";
import "./Loading.scss";
import { Loader } from "src/components/UI/Loader/Loader";

const loading: React.FC = props => {
  return (
    <div className="loading">
      <Loader size={"large"} color={"primary"} />
    </div>
  );
};

export default loading;
