import * as React from "react";
import { connect } from "react-redux";
import { IRootState } from "src/store/reducers";
import { ContactType, IContact } from "src/store/reducers/contacts";
import { sortByProperty, sortIgnoreCaseComparator } from "src/utils";
import Auxi from "src/hoc/Auxi/Auxi";
import "./ContactsOverviewList.scss";
import ContactItem from "src/components/ContactsOverviewList/ContactsOverviewItem/ContactsOverviewItem";
import { User } from "compass.js";
import { trackEvent, TrackCategory, TrackAction } from "src/utils/track";
import { remove as removeDiacritics } from "diacritics";

const LETTER_BLINK_CSS_CLASS = "contacts-overview-list__letter--blink";
const ALPHABET_ARRAY = "#abcdefghijklmnopqrstuvwxyz".split("");
const TRACK_CATEGORY = TrackCategory.contactsOverview;

class ContactsOverviewList extends React.Component<
  IPropsFromState,
  IContactsOverviewListState
> {
  state: IContactsOverviewListState = {
    scrollToLetter: null
  };
  private $lettersMap: { [key: string]: HTMLDivElement | null } = {};
  private $observedLetter?: HTMLElement;
  private intersectionObserver: IntersectionObserver;

  componentDidMount() {
    // NOTE: highlight letter when user navigation to it
    // only when it getting fully visible
    this.intersectionObserver = new IntersectionObserver(
      entries => {
        if (!this.$observedLetter) {
          return;
        }
        const entry = entries.find(
          item => item.target === this.$observedLetter
        );
        if (!entry || !entry.isIntersecting || entry.intersectionRatio !== 1) {
          return;
        }
        this.blinkLetterElement(this.$observedLetter);
        this.cleanObservedLetter();
      },
      { threshold: [1] }
    );
  }

  componentDidUpdate() {
    if (this.state.scrollToLetter === null) {
      return;
    }
    const $letter = this.$lettersMap[this.state.scrollToLetter];
    if ($letter === null) {
      return;
    }
    if (this.$observedLetter) {
      this.cleanObservedLetter();
    }
    this.setState(
      {
        scrollToLetter: null
      },
      () => {
        $letter.scrollIntoView({
          behavior: "smooth",
          inline: "start"
        });
        this.intersectionObserver.observe($letter);
        this.$observedLetter = $letter;
      }
    );
  }

  componentWillUnmount() {
    this.cleanObservedLetter();
  }

  public render() {
    const lettersObj: { [key: string]: IContact[] } = {};
    this.props.contacts.forEach(contact => {
      let letter =
        contact.name && contact.name.length
          ? removeDiacritics(contact.name)[0].toLowerCase()
          : "#";
      if (!ALPHABET_ARRAY.includes(letter)) {
        letter = "#";
      }
      if (!(letter in lettersObj)) {
        lettersObj[letter] = [];
      }
      lettersObj[letter].push(contact);
    });
    const letters: string[] = Object.keys(lettersObj).sort();
    return (
      <Auxi>
        <div className="contacts-overview-list__navigation">
          {ALPHABET_ARRAY.map(letter => {
            const isActive = letter in lettersObj;
            const cssClasses = ["contacts-overview-list__navigation-letter"];
            if (!isActive) {
              cssClasses.push(
                "contacts-overview-list__navigation-letter--inactive"
              );
            }
            return (
              <div
                className={cssClasses.join(" ")}
                onClick={
                  isActive
                    ? this.navigationLetterClicked.bind(null, letter)
                    : null
                }
                key={`letters-${letter}`}
              >
                {letter}
              </div>
            );
          })}
        </div>
        <div className="contacts-overview-list__wrap">
          <div className="contacts-overview-list">
            {letters.map(letter => (
              <Auxi key={`contacts-items-${letter}`}>
                <div
                  className="contacts-overview-list__letter"
                  ref={ref => (this.$lettersMap[letter] = ref)}
                >
                  <div className="contacts-overview-list__letter-inner">
                    {letter}
                  </div>
                </div>
                {lettersObj[letter].map(contact => (
                  <div
                    className="contacts-overview-list__contact-item-wrap"
                    key={contact.id}
                  >
                    <ContactItem contact={contact} />
                  </div>
                ))}
              </Auxi>
            ))}
          </div>
        </div>
      </Auxi>
    );
  }

  private cleanObservedLetter = () => {
    if (!this.$observedLetter) {
      return;
    }
    this.intersectionObserver.unobserve(this.$observedLetter);
    this.$observedLetter = undefined;
  };

  private navigationLetterClicked = (letter: string) => {
    trackEvent(TRACK_CATEGORY, TrackAction.contactsOverviewLetterClick);
    this.setState({
      scrollToLetter: letter
    });
  };

  private blinkLetterElement($element: HTMLElement) {
    $element.classList.add(LETTER_BLINK_CSS_CLASS);
    setTimeout(() => {
      $element.classList.remove(LETTER_BLINK_CSS_CLASS);
    }, 300);
  }
}

interface IContactsOverviewListState {
  scrollToLetter: null | string;
}

interface IPropsFromState {
  contacts: IContact[];
}

const mapStateToProps = (state: IRootState): IPropsFromState => {
  return {
    contacts: sortByProperty<IContact, string>(
      Object.values(state.contacts.compassItems).filter(
        contact =>
          contact.type === ContactType.compass &&
          contact.id !== (state.auth.user as User).id
      ),
      "name",
      sortIgnoreCaseComparator
    )
  };
};

export default connect<IPropsFromState>(mapStateToProps)(ContactsOverviewList);
