import * as React from "react";
import "./InputLabel.scss";

export const InputLabel: React.FC<IInputLabelProps> = props => {
  return (
    <label className="input-label" htmlFor={props.for}>
      {props.children}
    </label>
  );
};

interface IInputLabelProps {
  for?: string;
}
