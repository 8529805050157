import * as React from "react";
import "./List.scss";

interface IListProps {
  className?: string | null;
}

export const List: React.FC<IListProps> = props => {
  const cssClasses = ["list"];
  if (props.className) {
    cssClasses.push(props.className);
  }
  return <div className={cssClasses.join(" ")}>{props.children}</div>;
};
