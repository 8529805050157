import * as React from "react";
import { List, ListEmptyMsg } from "src/components/UI/List/";
import { IRootState } from "src/store/reducers";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp } from "@fortawesome/pro-solid-svg-icons/faChevronUp";
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons/faChevronDown";
import ContactItem from "src/components/ContactItem/ContactItem";
import { getUserStatusInfo, UserStatus } from "src/utils/user";
import { ListRow } from "src/components/UI/List/";
import { getSortedQueueMembers } from "src/utils/queue";
import "./QueueDetailsAgentsList.scss";
import { UserPermission } from "src/store/reducers/auth";
import { ReceiveCalls } from "compass.js";

class QueueDetailsAgentsList extends React.Component<
  IQueueDetailsAgentsListProps
> {
  state: IQueueDetailsAgentsListComponentState = {
    showInactiveAgents: false
  };

  shouldComponentUpdate(
    nextProps: IQueueDetailsAgentsListProps,
    nextState: IQueueDetailsAgentsListComponentState
  ) {
    return (
      this.props.queueId !== nextProps.queueId ||
      this.props.className !== nextProps.className ||
      JSON.stringify(this.props.agents) !== JSON.stringify(nextProps.agents) ||
      this.state.showInactiveAgents !== nextState.showInactiveAgents
    );
  }

  toggleInactiveAgents = () => {
    this.setState({
      showInactiveAgents: !this.state.showInactiveAgents
    });
  };

  onManageAgentsClick = (event: React.MouseEvent) => {
    event.preventDefault();
    if (this.props.onShowManageAgents) {
      this.props.onShowManageAgents();
    }
  };

  render() {
    const { className, agents } = this.props;
    const filteredAgents = this.state.showInactiveAgents
      ? agents
      : agents.filter(agent => agent.isActive);
    // NOTE: show inactive toggle only for mortal users
    const showLoggedOutToggle =
      !this.props.hasWritePermissions && this.props.queueHasInactiveAgents;
    return (
      <List className={className}>
        {filteredAgents.length || showLoggedOutToggle ? (
          <>
            {filteredAgents.map((agent, idx) => {
              return <ContactItem id={agent.id} key={agent.id} type="agent" />;
            })}
            {showLoggedOutToggle ? (
              <ListRow key="logged-out-agents-toggle">
                <div
                  className="queue-details-agents-list__toggle-inactive-agents-btn"
                  onClick={this.toggleInactiveAgents}
                >
                  <i>
                    {this.state.showInactiveAgents
                      ? "Show only active agents"
                      : `Show all agents (${this.props.agents.length})`}
                  </i>
                  <FontAwesomeIcon
                    icon={
                      this.state.showInactiveAgents
                        ? faChevronUp
                        : faChevronDown
                    }
                  />
                </div>
              </ListRow>
            ) : null}
          </>
        ) : (
          <ListEmptyMsg>
            {this.props.hasWritePermissions ? (
              <>
                No active agents in this queue. You can{" "}
                <a href="#" onClick={this.onManageAgentsClick}>
                  manage agents in queue.
                </a>
              </>
            ) : (
              "No agents in this queue."
            )}
          </ListEmptyMsg>
        )}
      </List>
    );
  }
}

interface IQueueDetailsAgentsListProps
  extends IPropsFromState,
    IQueueDetailsAgentsListComponentProps {}

interface IQueueDetailsAgentsListComponentProps {
  queueId: string;
  className?: string;
  onShowManageAgents?: () => void;
}

interface IQueueDetailsAgentsListComponentState {
  showInactiveAgents: false;
}

interface IPropsFromState {
  agents: Array<{
    id: string;
    isPaused: boolean;
    isActive: boolean;
  }>;
  queueHasInactiveAgents: boolean;
  hasWritePermissions: boolean;
}

const mapStateToProps = (
  state: IRootState,
  componentProps: IQueueDetailsAgentsListComponentProps
): IPropsFromState => {
  let queueHasInactiveAgents = false;
  const agents = getSortedQueueMembers(
    state.queues.items[componentProps.queueId]
  ).map(item => {
    const user = item.getUser();
    const userStatus = getUserStatusInfo(user).userStatus;
    let isActive =
      userStatus === UserStatus.available || userStatus === UserStatus.inCall;
    let isWrappingUp = false;
    if (state.auth.apiVersion && state.auth.apiVersion >= 3) {
      isWrappingUp =
        user.status.receiveCalls === ReceiveCalls.onlyDirect &&
        !!user.status.wrapupState;
      isActive =
        (userStatus === UserStatus.available ||
          (userStatus === UserStatus.inCall &&
            user.status.receiveCalls === ReceiveCalls.all) ||
          isWrappingUp) &&
        userStatus !== UserStatus.loggedOut;
    }
    if (!isActive && !queueHasInactiveAgents) {
      queueHasInactiveAgents = true;
    }
    return {
      id: item.getUser().id,
      isPaused:
        state.auth.apiVersion && state.auth.apiVersion >= 3
          ? user.status.receiveCalls === ReceiveCalls.onlyDirect &&
            !isWrappingUp
          : !!item.pausedSince,
      isActive
    };
  });
  return {
    agents,
    queueHasInactiveAgents,
    hasWritePermissions:
      state.auth.companyPermission === UserPermission.permWrite
  };
};

export default connect<IPropsFromState>(mapStateToProps)(
  QueueDetailsAgentsList
);
