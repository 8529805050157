import { Connection, User } from "compass.js";
import * as React from "react";
import { connect } from "react-redux";
import { IRootState } from "src/store/reducers";
import { IContact, ContactType } from "src/store/reducers/contacts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/pro-solid-svg-icons/faCircle";
import {
  getUserStatusInfo,
  UserStatusInfo,
  UserStatus,
  getUserStatusDisplay
} from "src/utils/user";
import "./ContactsOverviewItem.scss";
import { humanizeApproxDuration } from "src/utils/dateTime";
import Button from "src/components/UI/Button/Button";
import { faPhone } from "@fortawesome/pro-solid-svg-icons/faPhone";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import {
  tickIntervalRegister,
  tickIntervalUnregister
} from "src/store/actions/tickInterval";
import { generateUID } from "src/utils";
import { dialNumber } from "src/store/actions";
import { getUserCallDuration } from "src/utils/call";
import { handleError } from "src/utils/errorHandler";
import { TrackCategory, TrackAction } from "src/utils/track";
import { BridgeColor } from "src/utils/consts";

const TRACK_CATEGORY = TrackCategory.contactsOverview;

class ContactsOverviewItem extends React.Component<IContactsOverviewItemProps> {
  private tickIntervalId: string;
  private lastStatusInfo: UserStatusInfo;

  public componentDidMount() {
    this.tickIntervalId = `${ContactsOverviewItem.name}:${generateUID()}`;
    this.props.onTickIntervalRegister(this.tickIntervalId, () => {
      if (this.props.contact.type !== ContactType.compass) {
        return;
      }
      const statusInfo = getUserStatusInfo(
        this.props.users[this.props.contact.id]
      );
      if (
        statusInfo.call ||
        (this.lastStatusInfo && this.lastStatusInfo.call)
      ) {
        this.forceUpdate();
      }
    });
  }

  public componentWillUnmount() {
    this.props.onTickIntervalUnregister(this.tickIntervalId);
  }

  public render() {
    const contact = this.props.contact;
    if (contact.type !== ContactType.compass) {
      // NOTE: allow only compass contacts in this component
      throw new Error("Only compass contacts supported for this component");
    }
    const user = this.props.users[contact.id];
    const statusInfo = getUserStatusInfo(user);
    this.lastStatusInfo = statusInfo;
    let duration: null | string = null;
    let userExtension: null | string = null;
    if (statusInfo.userStatus === UserStatus.inCall && statusInfo.call) {
      duration = ` (${humanizeApproxDuration(
        getUserCallDuration(statusInfo.call, user)
      )})`;
    }
    if (user.extensions.length > 0) {
      userExtension = user.extensions[0];
    }
    return (
      <div
        className={[
          "contacts-overview-list__contact",
          "contacts-overview-list__contact--" + statusInfo.userStatus
        ].join(" ")}
        key={contact.id}
      >
        <div className="contacts-overview-list__contact-content">
          <div className="contacts-overview-list__contact-label">
            <div className="contacts-overview-list__contact-status">
              <FontAwesomeIcon icon={faCircle} />
            </div>
            <div className="contacts-overview-list__contact-name">
              {contact.name}
            </div>
          </div>
          <div className="contacts-overview-list__contact-right">
            <div className="contacts-overview-list__contact-status-display">
              {this.props.statusDisplay}
              {duration}
            </div>
            <div className="contacts-overview-list__contact-status-btns">
              <Button
                small={true}
                icononly={true}
                color={BridgeColor.gs300}
                disabled={!userExtension || !this.props.online}
                onClick={this.dialNumber.bind(null, userExtension)}
                track={[
                  TRACK_CATEGORY,
                  TrackAction.contactsOverviewDialContact
                ]}
              >
                <FontAwesomeIcon icon={faPhone} />
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  private dialNumber = (destination: string) => {
    this.props.onDialNumber(destination).catch(handleError);
  };
}

interface IPropsFromState {
  users: { [key: string]: User };
  online: boolean;
  statusDisplay: string | null;
}

interface IPropsFromDispatch {
  onTickIntervalRegister: (id: string, func: () => void) => void;
  onTickIntervalUnregister: (id: string) => void;
  onDialNumber: (destination: string | number) => Promise<any>;
}

interface IContactsOverviewItemComponentProps {
  contact: IContact;
}

interface IContactsOverviewItemProps
  extends IPropsFromState,
    IPropsFromDispatch,
    IContactsOverviewItemComponentProps {}

const mapStateToProps = (
  state: IRootState,
  componentProps: IContactsOverviewItemComponentProps
): IPropsFromState => {
  return {
    users: (state.auth.connection as Connection).model.users,
    online: state.window.online,
    statusDisplay: getUserStatusDisplay(componentProps.contact.id)
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IRootState, void, AnyAction>
): IPropsFromDispatch => {
  return {
    onTickIntervalRegister: (id: string, func: () => void) =>
      dispatch(tickIntervalRegister(id, func)),
    onTickIntervalUnregister: (id: string) =>
      dispatch(tickIntervalUnregister(id)),
    onDialNumber: (destination: string) => dispatch(dialNumber(destination))
  };
};

export default connect<IPropsFromState, IPropsFromDispatch>(
  mapStateToProps,
  mapDispatchToProps
)(ContactsOverviewItem);
