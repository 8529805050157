import * as React from "react";
import "./ListHeading.scss";

interface IProps {
  className?: string;
}

export const ListHeading: React.FC<IProps> = props => {
  const cssClasses = ["list__heading"];
  if (props.className) {
    cssClasses.push(props.className);
  }
  return <div className={cssClasses.join(" ")}>{props.children}</div>;
};
