import * as React from "react";
import "./Notification.scss";
import { INotification } from "src/store/reducers/notifications";
import { faTimes } from "@fortawesome/pro-solid-svg-icons/faTimes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons/faExclamationTriangle";
import { faExclamationCircle } from "@fortawesome/pro-solid-svg-icons/faExclamationCircle";
import { faCheck } from "@fortawesome/pro-solid-svg-icons/faCheck";

interface INotificationProps extends INotification {
  onDismiss: () => void;
}

class Notification extends React.Component<INotificationProps> {
  private dismissTimeout: NodeJS.Timer | null;

  componentWillUnmount() {
    if (this.dismissTimeout) {
      clearTimeout(this.dismissTimeout);
    }
  }

  render() {
    if (this.props.autoDismiss && !this.dismissTimeout) {
      this.dismissTimeout = setTimeout(() => {
        this.dismissTimeout = null;
        this.props.onDismiss();
      }, this.props.autoDismiss);
    }
    let icon: IconDefinition | null = null;
    switch (this.props.level) {
      case "danger":
        icon = faExclamationCircle;
        break;
      case "info":
        icon = faExclamationTriangle;
        break;
      case "success":
        icon = faCheck;
        break;
    }
    return (
      <div className={`notification notification--${this.props.level}`}>
        <div className="notification__content">
          {icon ? (
            <div className="notification__content-icon">
              <FontAwesomeIcon icon={icon} />
            </div>
          ) : null}
          <div className="notification__content-message">
            {this.props.message}
          </div>
          {this.props.button ? (
            <div
              className="notification__content-btn"
              onClick={this.props.button.onClick}
            >
              {this.props.button.text}
            </div>
          ) : null}
        </div>
        {this.props.dismissable ? (
          <div className="notification__close">
            <button
              className="notification__close-btn"
              onClick={this.props.onDismiss}
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
        ) : null}
      </div>
    );
  }
}

export default Notification;
