import {
  Queue,
  CallPointType,
  QueueCallPoint,
  Call,
  QueueMember,
  ReceiveCalls
} from "compass.js";
import { UserStatus } from "src/utils/user";
import { getUserStatusInfo } from "src/utils/user";
import { sortIgnoreCaseComparator } from "src/utils";
import { sortBy } from "src/utils";
import * as stable from "stable";
import { store } from "src/store";

/**
 * Get list of all waiting times for queue in seconds
 * @param queue Queue
 */
const getQueueWaitingTimes = (queue: Queue) => {
  const calls = queue.domain.calls;
  const waitingTimes: number[] = [];
  Object.values(calls).forEach((call: Call) => {
    if (
      call.destination.type === CallPointType.queue &&
      (call.destination as QueueCallPoint).queueId === queue.id
    ) {
      waitingTimes.push(+new Date() / 1000 - call.destination.timeCreated);
    }
  });
  return waitingTimes;
};

/**
 * Get avarage waiting time for queue in seconds
 * @param queue Queue
 */
export const getQueueAvgWaitingTime = (queue: Queue) => {
  const waitingTimes = getQueueWaitingTimes(queue);
  if (!waitingTimes.length) {
    return 0;
  }
  return Math.round(waitingTimes.reduce((a, b) => a + b) / waitingTimes.length);
};

/**
 * Get longest waiting time for queue in seconds
 * @param queue Queue
 */
export const getQueueLongestWaitingTime = (queue: Queue) => {
  const waitingTimes = getQueueWaitingTimes(queue);
  if (!waitingTimes.length) {
    return 0;
  }
  return Math.round(Math.max.apply(null, waitingTimes));
};

/**
 * Get list of queue active members
 * @param queue Queue
 */
export const getQueueActiveMembers = (queue: Queue) => {
  return queue.queueMembers.filter(
    item =>
      getUserStatusInfo(item.getUser()).userStatus === UserStatus.available
  );
};

/**
 * Get sorted queue agents in order:
 * 1. Available agents,
 * 2. In call agents,
 * 3. Agents on pause.
 * 4. Logged out agents
 * @param queue Queue
 */
export const getSortedQueueMembers = (queue: Queue) => {
  let queueMembers = stable<QueueMember>(
    sortBy<QueueMember, string>(
      queue.getUsers().map(user => queue.getQueueMember(user.id)),
      item => item.getUser().name,
      sortIgnoreCaseComparator
    )
  );
  const apiVersion = store.getState().auth.apiVersion;
  queueMembers = stable<QueueMember>(queueMembers, (a, b) => {
    const aUserStatus = getUserStatusInfo(a.getUser());
    const bUserStatus = getUserStatusInfo(b.getUser());
    if (
      aUserStatus.userStatus === UserStatus.inCall &&
      bUserStatus.userStatus !== UserStatus.inCall
    ) {
      return 1;
    } else if (
      aUserStatus.userStatus !== UserStatus.inCall &&
      bUserStatus.userStatus === UserStatus.inCall
    ) {
      return -1;
    }
    return 0;
  });
  if (apiVersion && apiVersion >= 3) {
    queueMembers = stable<QueueMember>(queueMembers, (a, b) => {
      const aUser = a.getUser();
      const bUser = b.getUser();
      if (
        aUser.status.receiveCalls !== ReceiveCalls.onlyDirect &&
        bUser.status.receiveCalls === ReceiveCalls.onlyDirect
      ) {
        return 1;
      } else if (
        aUser.status.receiveCalls === ReceiveCalls.onlyDirect &&
        bUser.status.receiveCalls !== ReceiveCalls.onlyDirect
      ) {
        return -1;
      }
      return 0;
    });
    queueMembers = stable<QueueMember>(queueMembers, (a, b) => {
      const aUser = a.getUser();
      const bUser = b.getUser();
      if (
        aUser.status.receiveCalls !== ReceiveCalls.all &&
        bUser.status.receiveCalls === ReceiveCalls.all
      ) {
        return 1;
      } else if (
        aUser.status.receiveCalls === ReceiveCalls.all &&
        bUser.status.receiveCalls !== ReceiveCalls.all
      ) {
        return -1;
      }
      return 0;
    });
  } else {
    queueMembers = stable<QueueMember>(queueMembers, (a, b) => {
      if (!!a.pausedSince && !b.pausedSince) {
        return 1;
      } else if (!a.pausedSince && !!b.pausedSince) {
        return -1;
      }
      return 0;
    });
  }
  queueMembers = stable<QueueMember>(queueMembers, (a, b) => {
    const aUserStatus = getUserStatusInfo(a.getUser());
    const bUserStatus = getUserStatusInfo(b.getUser());
    if (
      aUserStatus.userStatus === UserStatus.loggedOut &&
      bUserStatus.userStatus !== UserStatus.loggedOut
    ) {
      return 1;
    } else if (
      aUserStatus.userStatus !== UserStatus.loggedOut &&
      bUserStatus.userStatus === UserStatus.loggedOut
    ) {
      return -1;
    }
    return 0;
  });
  return queueMembers;
};
