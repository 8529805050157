import * as React from "react";
import Notification from "src/components/Notification/Notification";
import { IRootState } from "src/store/reducers";
import { connect } from "react-redux";
import "./Notifications.scss";
import { INotification } from "src/store/reducers/notifications";
import { notificationDismiss } from "src/store/actions/notifications";

class Notifications extends React.Component<INotificationsProps> {
  public render() {
    return (
      <div className="notifications">
        {this.props.notifications.length
          ? this.props.notifications.map((item, idx) => {
              return (
                <Notification
                  {...item}
                  key={item.uid}
                  onDismiss={this.dismissNotification.bind(this, item.uid)}
                />
              );
            })
          : null}
      </div>
    );
  }

  private dismissNotification = (uid: string) => {
    this.props.onNotificationRemove(uid);
  };
}

interface IPropsFromState {
  notifications: INotification[];
}

interface IPropsFromDispatch {
  onNotificationRemove: (uid: string) => void;
}

const mapStateToProps = (state: IRootState): IPropsFromState => {
  return {
    notifications: state.notifications.items
  };
};

const mapDispatchToProps = (dispatch: any): IPropsFromDispatch => {
  return {
    onNotificationRemove: (uid: string) => dispatch(notificationDismiss(uid))
  };
};

interface INotificationsProps extends IPropsFromState, IPropsFromDispatch {}

export default connect<IPropsFromState, IPropsFromDispatch>(
  mapStateToProps,
  mapDispatchToProps
)(Notifications);
