import * as React from "react";
import "./Segment.scss";

class Segment extends React.Component<ISegmentProps> {
  public render() {
    return (
      <div className="segment">
        {this.props.items.map(item => {
          const cssClasses = ["segment__item"];
          if (item.value === this.props.value) {
            cssClasses.push("segment__item--active");
          }
          return (
            <div
              className={cssClasses.join(" ")}
              onClick={this.props.onChange.bind(this, item.value)}
              key={item.value}
            >
              {item.title}
            </div>
          );
        })}
      </div>
    );
  }
}

export interface ISegmentProps {
  value: string;
  items: Array<{
    title: string;
    value: string;
  }>;
  onChange: (value: string) => void;
}

export default Segment;
