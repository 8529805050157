import { IContact, ContactType } from "src/store/reducers/contacts";
import { parsePhoneNumber } from "src/utils";

export const getWindowsContacts = (): Promise<{ [key: string]: IContact }> => {
  return new Promise((resolve, reject) => {
    window.windowsContacts.ContactManager.requestStoreAsync(
      (storeError: any, store: any) => {
        if (storeError) {
          return reject(storeError);
        }
        store.findContactsAsync((findError: any, windowsContacts: any) => {
          if (findError) {
            return reject(findError);
          }
          const abContacts: { [key: string]: IContact } = {};
          for (let i = 0; i < windowsContacts.size; i++) {
            const windowsContact = windowsContacts[i];
            const id = windowsContact.id;
            const phones: IContact["phones"] = [];
            const emails: IContact["emails"] = [];

            const phoneSources =
              windowsContact.phoneNumbers || windowsContact.phones;
            for (let j = 0; j < phoneSources.size; j++) {
              const rawPhone = phoneSources[j];
              phones.push({
                label: "",
                value: parsePhoneNumber(rawPhone.value || rawPhone.number)
              });
            }

            for (let j = 0; j < windowsContact.emails.size; j++) {
              const rawEmail = windowsContact.emails[j];
              emails.push({
                label: "",
                value: rawEmail.value || rawEmail.address
              });
            }

            abContacts[id] = {
              id,
              name: windowsContact.displayName || windowsContact.name,
              type: ContactType.addressBook,
              phones,
              emails
            };
          }
          resolve(abContacts);
        });
      }
    );
  });
};
