import * as React from "react";
import { connect } from "react-redux";
import { IRootState } from "src/store/reducers";
import { Call, Connection, User } from "compass.js";
import { humanizeApproxDuration, humanizeDuration } from "src/utils/dateTime";
import { getUserCallDuration } from "src/utils/call";

export enum CallDetailCounterType {
  userDuration = "userDuration",
  sourceDuration = "sourceDuration"
}

class CallDetailCounter extends React.Component<
  ICallDetailCounterProps,
  ICallDetailCounterState
> {
  private tickIntervalId: NodeJS.Timer | undefined;

  constructor(props: ICallDetailCounterProps) {
    super(props);
    this.state = {
      value: this.getValue()
    };
  }

  public shouldComponentUpdate(
    nextProps: ICallDetailCounterProps,
    nextState: ICallDetailCounterState
  ) {
    return this.state.value !== nextState.value;
  }

  public componentDidMount() {
    this.tickIntervalId = setInterval(() => {
      const value = this.getValue();
      if (value === this.state.value) {
        return;
      }
      this.setState({
        value
      });
    }, 1000);
  }

  public componentWillUnmount() {
    if (this.tickIntervalId) {
      clearInterval(this.tickIntervalId);
      this.tickIntervalId = undefined;
    }
  }

  public render() {
    return this.state.value || null;
  }

  private getValue = (): string | undefined => {
    if (!this.props.call) {
      return undefined;
    }
    const humanizeDurationFunc = this.props.precise
      ? humanizeDuration
      : humanizeApproxDuration;
    switch (this.props.type) {
      case CallDetailCounterType.userDuration:
        if (!this.props.user) {
          return undefined;
        }
        return humanizeDurationFunc(
          getUserCallDuration(this.props.call, this.props.user)
        );
      case CallDetailCounterType.sourceDuration:
        return humanizeDurationFunc(this.props.call.source.getDuration());
    }
  };
}

interface IPropsFromState {
  call?: Call;
  user?: User;
}

const mapStateToProps = (
  state: IRootState,
  componentProps: ICallDetailCounterComponentProps
): IPropsFromState => {
  const connection = state.auth.connection as Connection;
  return {
    call: connection.model.calls[componentProps.callId],
    user: componentProps.userId
      ? connection.model.users[componentProps.userId]
      : undefined
  };
};

interface ICallDetailCounterComponentProps {
  callId: string;
  userId?: string;
  type: CallDetailCounterType;
  precise?: boolean;
}

interface ICallDetailCounterProps
  extends IPropsFromState,
    ICallDetailCounterComponentProps {}

interface ICallDetailCounterState {
  value?: string;
}

export default connect<IPropsFromState>(mapStateToProps)(CallDetailCounter);
