import * as React from "react";
import "./AttendedTransferModal.scss";
import { IRootState } from "src/store/reducers";
import { Call, User, Side, OtherSide, CallPointState } from "compass.js";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import {
  notificationShow,
  phoneAction,
  PhoneActionType
} from "src/store/actions";
import { mergeCalls, resetDesiredTransferCall } from "src/store/actions/calls";
import { connect } from "react-redux";
import { BridgeColor } from "src/utils/consts";
import CallItem from "../Calls/CallItem/CallItem";
import Button from "../UI/Button/Button";
import { handleError } from "src/utils/errorHandler";
import { OnboardingStepId } from "src/utils/OnboardingStep";
import { getUserSide } from "src/utils/call";

/**
 * This is a modal that's shown during attended transfer:
 * when the consultation call has been set up,
 * but the transfer has not yet been completed.
 */
class AttendedTransferModal extends React.Component<
  IAttendedTransferModalProps
> {
  render() {
    return (
      <div className="attended-transfer-modal">
        <div className="attended-transfer-modal__calls">
          <CallItem
            callId={this.props.mainCallId}
            hideTransferBtn={true}
            hideDtmfBtn={true}
          />
          <CallItem
            callId={this.props.consultationCallId}
            hideTransferBtn={true}
            hideDtmfBtn={true}
          />
        </div>
        <div className="attended-transfer-modal__buttons">
          <Button
            disabled={
              this.props.callsIsLoading || !this.props.canFinishTransfer
            }
            color={BridgeColor.prim500}
            tooltip={
              this.props.canFinishTransfer
                ? undefined
                : "Transfer will be possible when the call is answered"
            }
            onClick={this.mergeCalls}
          >
            Transfer the call
          </Button>
          <Button
            disabled={this.props.callsIsLoading}
            color={BridgeColor.gs800}
            onClick={this.cancelTransfer}
            onboardingStep={OnboardingStepId.callCancelAttendedTransfer}
            onboardingTooltipProps={{ placement: "right" }}
          >
            Cancel transfer
          </Button>
        </div>
      </div>
    );
  }

  private mergeCalls = async () => {
    if (this.props.onboardingMode) {
      return;
    }
    try {
      await this.props.onMergeCalls(
        this.props.consultationCallId,
        this.props.mainCallId
      );
    } catch (error) {
      handleError(error);
    }
  };

  private cancelTransfer = async () => {
    try {
      await this.props.onPhoneAction(
        this.props.consultationCallId,
        PhoneActionType.hangupCall
      );
      this.props.onResetDesiredTransferCall();
    } catch (error) {
      handleError(error);
    }
  };
}

interface IAttendedTransferModalComponentProps {
  mainCallId: Call["id"];
  consultationCallId: Call["id"];
}

interface IAttendedTransferModalProps
  extends IPropsFromState,
    IPropsFromDispatch,
    IAttendedTransferModalComponentProps {}

interface IPropsFromState {
  callsIsLoading: boolean;
  onboardingMode: boolean;
  canFinishTransfer: boolean;
}

interface IPropsFromDispatch {
  onNotificationShow: typeof notificationShow;
  onMergeCalls: (callId1: string, callId2: string) => Promise<any>;
  onPhoneAction: (callId: Call["id"], action: PhoneActionType) => Promise<any>;
  onResetDesiredTransferCall: () => void;
}

const mapStateToProps = (
  state: IRootState,
  componentProps: IAttendedTransferModalComponentProps
): IPropsFromState => {
  const consultationCall = state.calls.items.find(
    call => call.id === componentProps.consultationCallId
  );
  let canFinishTransfer = false;
  if (consultationCall) {
    const selfSide = getUserSide(consultationCall, state.auth
      .user as User) as Side;
    const otherCallPoint = consultationCall.getEndpoint(OtherSide(selfSide));
    canFinishTransfer = [
      CallPointState.answered,
      CallPointState.inactive
    ].includes(otherCallPoint.state);
  }
  return {
    callsIsLoading: !!state.calls.actionsInProgress,
    onboardingMode: state.auth.onboardingMode,
    canFinishTransfer
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IRootState, void, AnyAction>
): IPropsFromDispatch => {
  return {
    onNotificationShow: notification =>
      dispatch(notificationShow(notification)),
    onMergeCalls: (callId1: string, callId2: string) =>
      dispatch(mergeCalls(callId1, callId2)),
    onPhoneAction: (callId: Call["id"], action: PhoneActionType) =>
      dispatch(phoneAction(callId, action)),
    onResetDesiredTransferCall: () => dispatch(resetDesiredTransferCall())
  };
};

export default connect<IPropsFromState, IPropsFromDispatch>(
  mapStateToProps,
  mapDispatchToProps
)(AttendedTransferModal);
