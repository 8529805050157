import * as React from "react";
import { connect } from "react-redux";
import { IRootState } from "src/store/reducers";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { ReceiveCalls } from "compass.js";
import { notificationShow, notificationDismiss } from "src/store/actions";
import { setUserStatus } from "src/store/actions/auth";

const awayNotificationId = "away-status-active";

class AwayStatusNotification extends React.Component<
  IAwayStatusNotificationProps
> {
  componentDidUpdate(prevProps: IAwayStatusNotificationProps) {
    if (!prevProps.loggedIn && this.props.receiveCalls === ReceiveCalls.none) {
      this.props.onNotificationShow({
        uid: awayNotificationId,
        message: `Your status is currently set to Away. Do you want to switch it to Available?`,
        level: "info",
        button: {
          text: "Go Available",
          onClick: () => this.props.onSetUserStatus(ReceiveCalls.all)
        },
        dismissable: true
      });
    } else if (
      this.props.receiveCalls !== ReceiveCalls.none &&
      this.props.awayNotificationVisible
    ) {
      this.props.onNotificationDismiss(awayNotificationId);
    }
  }

  public render() {
    return null;
  }
}

interface IPropsFromState {
  awayNotificationVisible: boolean;
  loggedIn: boolean;
  receiveCalls?: ReceiveCalls;
}

interface IPropsFromDispatch {
  onNotificationShow: typeof notificationShow;
  onSetUserStatus: (
    receiveCalls: ReceiveCalls,
    displayStatus?: string
  ) => Promise<any>;
  onNotificationDismiss: (uid: string) => void;
}

interface IAwayStatusNotificationProps
  extends IPropsFromState,
    IPropsFromDispatch {}

const mapStateToProps = (state: IRootState): IPropsFromState => {
  return {
    awayNotificationVisible: !!state.notifications.items.find(
      item => item.uid === awayNotificationId
    ),
    loggedIn: !!state.auth.user,
    receiveCalls: state.auth.user && state.auth.user.status.receiveCalls
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IRootState, void, AnyAction>
): IPropsFromDispatch => {
  return {
    onSetUserStatus: (receiveCalls: ReceiveCalls, displayStatus?: string) =>
      dispatch(setUserStatus(receiveCalls, displayStatus)),
    onNotificationShow: notification =>
      dispatch(notificationShow(notification)),
    onNotificationDismiss: uid => dispatch(notificationDismiss(uid))
  };
};

export default connect<IPropsFromState, IPropsFromDispatch>(
  mapStateToProps,
  mapDispatchToProps
)(AwayStatusNotification);
