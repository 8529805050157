import * as React from "react";
import { connect } from "react-redux";
import { IRootState } from "src/store/reducers";
import { Queue } from "compass.js";
import { humanizeApproxDuration } from "src/utils/dateTime";
import {
  getQueueAvgWaitingTime,
  getQueueActiveMembers,
  getQueueLongestWaitingTime
} from "src/utils/queue";

export enum QueueDetailCounterType {
  avgWaiting = "awgWaiting",
  longestWaiting = "longestWaiting",
  agents = "agents",
  callers = "callers"
}

class QueueDetailCounter extends React.Component<
  IQueueDetailCounterProps,
  IQueueDetailCounterState
> {
  private tickIntervalId: NodeJS.Timer | undefined;

  constructor(props: IQueueDetailCounterProps) {
    super(props);
    this.state = {
      value: this.getValue()
    };
  }

  public shouldComponentUpdate(
    nextProps: IQueueDetailCounterProps,
    nextState: IQueueDetailCounterState
  ) {
    return this.state.value !== nextState.value;
  }

  public componentDidMount() {
    this.tickIntervalId = setInterval(() => {
      const value = this.getValue();
      if (value === this.state.value) {
        return;
      }
      this.setState({
        value
      });
    }, 1000);
  }

  public componentWillUnmount() {
    if (this.tickIntervalId) {
      clearInterval(this.tickIntervalId);
      this.tickIntervalId = undefined;
    }
  }

  public render() {
    return this.state.value || null;
  }

  private getValue = (): string => {
    switch (this.props.type) {
      case QueueDetailCounterType.agents:
        return getQueueActiveMembers(this.props.queue)
          .filter(item => !item.pausedSince)
          .length.toString();
      case QueueDetailCounterType.callers:
        return this.props.queue.getCalls().length.toString();
      case QueueDetailCounterType.avgWaiting:
        return humanizeApproxDuration(getQueueAvgWaitingTime(this.props.queue));
      case QueueDetailCounterType.longestWaiting:
        return humanizeApproxDuration(
          getQueueLongestWaitingTime(this.props.queue)
        );
    }
  };
}

interface IPropsFromState {
  queue: Queue;
}

const mapStateToProps = (
  state: IRootState,
  componentProps: IQueueDetailCounterComponentProps
): IPropsFromState => {
  return {
    queue: state.queues.items[componentProps.id]
  };
};

interface IQueueDetailCounterComponentProps {
  id: string;
  type: QueueDetailCounterType;
}

interface IQueueDetailCounterProps
  extends IPropsFromState,
    IQueueDetailCounterComponentProps {}

interface IQueueDetailCounterState {
  value?: string;
}

export default connect<IPropsFromState>(mapStateToProps)(QueueDetailCounter);
