import * as React from "react";
import DialerInput from "./DialerInput/DialerInput";
import DialerNumpad from "./DialerNumpad/DialerNumpad";
import Auxi from "src/hoc/Auxi/Auxi";
import Button from "src/components/UI/Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/pro-solid-svg-icons/faPhone";
import { faShare } from "@fortawesome/pro-solid-svg-icons/faShare";
import { faShareAll } from "@fortawesome/pro-solid-svg-icons/faShareAll";
import "./Dialer.scss";
import { AnyAction } from "redux";
import { connect } from "react-redux";
import { IRootState } from "src/store/reducers";
import { dialNumber, notificationShow } from "src/store/actions";
import { ThunkDispatch } from "redux-thunk";
import { sendDtmf } from "src/store/actions/calls";
import { handleError } from "src/utils/errorHandler";
import { notificationsDismissAll } from "src/store/actions/notifications";
import { TrackAction, TrackCategory } from "src/utils/track";
import { BridgeColor } from "src/utils/consts";

const LAST_NUMBER_STORAGE_KEY = "lastNumber";
const TRACK_CATEGORY = TrackCategory.dialer;

export enum DialerType {
  default = "default",
  transfer = "transfer",
  dtmf = "dtmf"
}

class Dialer extends React.Component<IDialerProps, IDialerState> {
  public state = {
    number: "",
    lastNumber: sessionStorage.getItem(LAST_NUMBER_STORAGE_KEY) || "",
    actionButtonsHighlighted: false
  };

  public componentDidUpdate(prevProps: IDialerProps) {
    if (
      this.state.number &&
      (this.props.type !== prevProps.type ||
        this.props.callIdForDtmf !== prevProps.callIdForDtmf)
    ) {
      this.setState({
        number: ""
      });
    }
  }

  public render() {
    const buttonsCssClasses = ["dialer__buttons"];
    if (this.state.actionButtonsHighlighted) {
      buttonsCssClasses.push("dialer__buttons--highlight");
    }
    const disableInput =
      this.props.type === DialerType.dtmf && this.props.callsIsLoading;
    return (
      <Auxi>
        <div className="dialer">
          <DialerInput
            value={this.state.number}
            changed={this.onDialerInputChange}
            keypress={this.onDialerInputKeypress}
            dark={this.props.dark}
            submit={this.onInputSubmit}
            disable={disableInput}
            disablePaste={this.props.type === DialerType.dtmf}
            placeholder={
              this.props.type === DialerType.dtmf ? "" : "+48 123 123 123"
            }
          />
          <DialerNumpad
            onKeypress={this.onDialerKeypressHandler}
            currentValue={this.state.number}
            dark={this.props.dark}
            disabled={disableInput}
          />
          {this.props.type === DialerType.dtmf ? null : (
            <div className={buttonsCssClasses.join(" ")}>
              {this.props.type === DialerType.default
                ? this.$getDialButton()
                : null}
              {this.props.type === DialerType.transfer ? (
                <Auxi>
                  {this.$getDirectTransferBtn()}
                  {this.$getAttendedTransferBtn()}
                </Auxi>
              ) : null}
            </div>
          )}
        </div>
      </Auxi>
    );
  }

  private onInputSubmit = () => {
    switch (this.props.type) {
      case DialerType.default:
        this.onDialClick();
        break;
      case DialerType.transfer:
        this.transferInputSubmitHandler();
        break;
    }
  };

  private $getDialButton = () => {
    return (
      <Button
        className="button--dialpad"
        color={BridgeColor.green500}
        fill={"fade"}
        onClick={this.onDialClick}
        disabled={
          this.props.disabled ||
          (!this.state.number && !this.state.lastNumber) ||
          this.props.callsIsLoading ||
          !this.props.online
        }
        track={[TRACK_CATEGORY, TrackAction.dialerDial]}
      >
        <FontAwesomeIcon icon={faPhone} />
      </Button>
    );
  };

  private $getDirectTransferBtn = () => {
    return (
      <Button
        className="button--dialpad"
        color={this.props.dark ? BridgeColor.gs800 : BridgeColor.gs300}
        onClick={
          this.props.onDirectTransfer
            ? this.props.onDirectTransfer.bind(null, this.state.number)
            : undefined
        }
        disabled={
          this.props.disabled ||
          !this.state.number ||
          !this.props.desiredTransferCallId ||
          this.props.callsIsLoading ||
          !this.props.online
        }
        tooltip={"Direct transfer"}
        track={[TRACK_CATEGORY, TrackAction.dialerTransfer]}
      >
        <FontAwesomeIcon icon={faShare} />
      </Button>
    );
  };

  private $getAttendedTransferBtn = () => {
    return (
      <Button
        className="button--dialpad"
        color={this.props.dark ? BridgeColor.gs800 : BridgeColor.gs300}
        onClick={
          this.props.onAttendedTransfer
            ? this.props.onAttendedTransfer.bind(null, this.state.number)
            : undefined
        }
        tooltip={"Attended transfer"}
        disabled={
          this.props.disabled ||
          !this.state.number ||
          this.props.callsIsLoading ||
          !this.props.online
        }
        track={[TRACK_CATEGORY, TrackAction.dialerAttendedTransfer]}
      >
        <FontAwesomeIcon icon={faShareAll} />
      </Button>
    );
  };

  private transferInputSubmitHandler = () => {
    this.props.onNotificationsDismissAll();
    this.props.onNotificationShow({
      message: "Please choose what type of transfer you want to make.",
      level: "info",
      autoDismiss: 3000
    });
    this.setState(
      {
        actionButtonsHighlighted: true
      },
      () => {
        setTimeout(() => {
          this.setState({
            actionButtonsHighlighted: false
          });
        }, 300);
      }
    );
  };

  private onDialClick = () => {
    if (!this.state.number) {
      return this.setState({
        number: this.state.lastNumber
      });
    }
    this.props
      .onDialNumber(this.state.number)
      .then(this.clearNumberInput, handleError);
  };

  private clearNumberInput = () => {
    sessionStorage.setItem(LAST_NUMBER_STORAGE_KEY, this.state.number);
    this.setState({
      number: "",
      lastNumber: this.state.number
    });
  };

  private onDialerInputChange = (value: string) => {
    this.setState({
      number: value
    });
  };

  private onDialerInputKeypress = (key: string) => {
    if (this.props.type === DialerType.dtmf && this.props.callIdForDtmf) {
      this.props.onSendDtmf(this.props.callIdForDtmf, key).catch(handleError);
    }
  };

  private onDialerKeypressHandler = (value: string) => {
    if (this.props.type === DialerType.dtmf && this.props.callIdForDtmf) {
      this.props.onSendDtmf(this.props.callIdForDtmf, value).catch(handleError);
    }
    this.setState({
      number: this.state.number + value
    });
  };
}

interface IDialerProps extends IPropsFromState, IPropsFromDispatch {
  type: DialerType;
  callIdForDtmf?: string;
  dark?: boolean;
  disabled?: boolean;
  onAttendedTransfer?: (destination: string) => void;
  onDirectTransfer?: (destination: string) => void;
}

interface IDialerState {
  number: string;
  lastNumber: string;
  actionButtonsHighlighted: boolean;
}

interface IPropsFromState {
  callsIsLoading: boolean;
  desiredTransferCallId?: string;
  online: boolean;
}

interface IPropsFromDispatch {
  onNotificationShow: typeof notificationShow;
  onNotificationsDismissAll: () => void;
  onDialNumber: (destination: string | number) => Promise<any>;
  onSendDtmf: (callId: string, dtmf: string) => Promise<any>;
}

const mapStateToProps = (state: IRootState): IPropsFromState => {
  return {
    callsIsLoading: state.calls.actionsInProgress > 0,
    desiredTransferCallId: state.calls.desiredTransferCallId,
    online: state.window.online
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IRootState, void, AnyAction>
): IPropsFromDispatch => {
  return {
    onNotificationShow: notification =>
      dispatch(notificationShow(notification)),
    onNotificationsDismissAll: () => dispatch(notificationsDismissAll()),
    onDialNumber: (destination: string) => dispatch(dialNumber(destination)),
    onSendDtmf: (callId: string, dtmf: string) =>
      dispatch(sendDtmf(callId, dtmf))
  };
};

export default connect<IPropsFromState, IPropsFromDispatch>(
  mapStateToProps,
  mapDispatchToProps
)(Dialer);
