import * as React from "react";
import Auxi from "src/hoc/Auxi/Auxi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp } from "@fortawesome/pro-solid-svg-icons/faChevronUp";
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons/faChevronDown";
import { faThumbtack } from "@fortawesome/pro-solid-svg-icons/faThumbtack";
import { faThumbtack as faThumbtackRegular } from "@fortawesome/pro-regular-svg-icons/faThumbtack";
import Button from "src/components/UI/Button/Button";
import { connect } from "react-redux";
import { IRootState } from "src/store/reducers";
import {
  ListRow,
  ListRowTitle,
  ListRowInfo,
  ListRowInfoItem
} from "src/components/UI/List/";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import {
  loginQueue,
  logoutQueue,
  pinQueue,
  unpinQueue
} from "src/store/actions/queues";
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import "./QueueItem.scss";
import { handleError } from "src/utils/errorHandler";
import { Tooltip } from "../UI/Tooltip/Tooltip";
import { TrackAction, TrackCategory, trackEvent } from "src/utils/track";
import {
  InfiniteItemComponent,
  InfiniteItemComponentProps
} from "src/utils/InfiniteItemComponent";
import { User } from "compass.js";
import QueueDetailCounter, {
  QueueDetailCounterType
} from "src/components/QueueDetailCounter/QueueDetailCounter";
import { BridgeColor } from "src/utils/consts";

const TRACK_CATEGORY = TrackCategory.queueItem;

class QueueItem extends InfiniteItemComponent<
  IQueuesListItemProps,
  IQueuesListItemState
> {
  state = {
    visible: false
  };

  public render() {
    return (
      <Auxi>
        <ContextMenuTrigger id={this.props.elementId}>
          <ListRow
            active={this.props.isActive}
            onClick={this.props.onClick}
            disabled={!this.props.isLoggedInUserInQueue}
            id={`queue-row--${this.props.id}`}
          >
            {this.$getRowContent()}
          </ListRow>
        </ContextMenuTrigger>
        {this.state.visible ? this.$getContextMenu() : null}
      </Auxi>
    );
  }

  private $getRowContent = () => {
    if (!this.state.visible) {
      return (
        <div className="queue-item__invisible">
          <div className="queue-item__invisible-name" />
          <div className="queue-item__invisible-buttons" />
        </div>
      );
    }
    return (
      <Auxi>
        <ListRowTitle>
          <Tooltip
            annotation={true}
            enabled={true}
            content={this.props.name}
            delay={[1000, 0]}
          >
            <span className="list__row-title-text">{this.props.name}</span>
          </Tooltip>
        </ListRowTitle>
        <ListRowInfo>
          <ListRowInfoItem className="queue-item__right-info">
            <Tooltip content={"Longest waiting time"}>
              <span className="queue-item__duration">
                <QueueDetailCounter
                  id={this.props.id}
                  type={QueueDetailCounterType.longestWaiting}
                />
              </span>
            </Tooltip>
          </ListRowInfoItem>
          <ListRowInfoItem className="queue-item__right-info">
            <Tooltip content={"People waiting"}>
              <span>
                {
                  <QueueDetailCounter
                    id={this.props.id}
                    type={QueueDetailCounterType.callers}
                  />
                }
              </span>
            </Tooltip>
          </ListRowInfoItem>
          <ListRowInfoItem isButton={true}>
            <Tooltip
              placement="top"
              content={
                this.props.isPinned
                  ? "Unpin queue from the top"
                  : "Pin queue to the top"
              }
            >
              <Button
                color={BridgeColor.gs800}
                icononly={true}
                fill={"clear"}
                small={true}
                onClick={this.togglePin}
                disabled={this.props.queuesIsLoading}
                track={[TRACK_CATEGORY, TrackAction.queueItemPinToggle]}
              >
                <FontAwesomeIcon
                  icon={this.props.isPinned ? faThumbtack : faThumbtackRegular}
                />
              </Button>
            </Tooltip>
          </ListRowInfoItem>
          <ListRowInfoItem isButton={true} className="br-screen-small">
            <Button
              color={BridgeColor.gs800}
              icononly={true}
              fill={"clear"}
              small={true}
              onClick={this.toggleBtnClicked}
            >
              <FontAwesomeIcon
                icon={this.props.isActive ? faChevronUp : faChevronDown}
              />
            </Button>
          </ListRowInfoItem>
        </ListRowInfo>
      </Auxi>
    );
  };

  private toggleBtnClicked = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (this.props.onDetailsToggle) {
      this.props.onDetailsToggle();
    }
  };

  private $getContextMenu(): React.ReactNode {
    return (
      <ContextMenu id={`queue-${this.props.id}`}>
        <MenuItem
          disabled={!this.props.online || this.props.queuesIsLoading}
          onClick={
            this.props.isLoggedInUserInQueue
              ? this.contextLogout
              : this.contextLogin
          }
        >
          {this.props.isLoggedInUserInQueue ? "Leave queue" : "Join queue"}
        </MenuItem>
        <MenuItem onClick={this.contextPinToggle}>
          {this.props.isPinned ? "Unpin" : "Pin"}
        </MenuItem>
      </ContextMenu>
    );
  }

  private contextPinToggle = () => {
    trackEvent(TRACK_CATEGORY, TrackAction.queueItemContextPinToggle);
    this.togglePin();
  };

  private contextLogin = () => {
    trackEvent(TRACK_CATEGORY, TrackAction.queueItemContextLogin);
    this.props.onLoginQueue(this.props.id);
  };

  private contextLogout = () => {
    trackEvent(TRACK_CATEGORY, TrackAction.queueItemContextLogout);
    this.props.onLogoutQueue(this.props.id);
  };

  private togglePin = (e?: React.MouseEvent<HTMLButtonElement>) => {
    if (e) {
      e.stopPropagation();
    }
    (this.props.isPinned
      ? this.props.onUnpinQueue(this.props.id)
      : this.props.onPinQueue(this.props.id)
    ).catch(handleError);
  };
}

interface IPropsFromState {
  online: boolean;
  isLoggedInUserInQueue: boolean;
  name: string;
  isPinned: boolean;
  queuesIsLoading: boolean;
  elementId: string;
}

interface IPropsFromDispatch {
  onLoginQueue: (queueId: string) => Promise<any>;
  onLogoutQueue: (queueId: string) => Promise<any>;
  onPinQueue: (queueId: string) => Promise<any>;
  onUnpinQueue: (queueId: string) => Promise<any>;
}

const mapStateToProps = (
  state: IRootState,
  componentProps: IQueuesListItemComponentProps
): IPropsFromState => {
  const queue = state.queues.items[componentProps.id];
  return {
    online: state.window.online,
    isLoggedInUserInQueue: queue.isUserInQueue((state.auth.user as User).id),
    name: queue.name,
    isPinned: !!state.queues.pinned.find(
      queueId => queueId === componentProps.id
    ),
    queuesIsLoading: state.queues.actionsInProgress > 0,
    elementId: `queue-row--${componentProps.id}`
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IRootState, void, AnyAction>
): IPropsFromDispatch => {
  return {
    onLoginQueue: (queueId: string) => dispatch(loginQueue(queueId)),
    onLogoutQueue: (queueId: string) => dispatch(logoutQueue(queueId)),
    onPinQueue: (queueId: string) => dispatch(pinQueue(queueId)),
    onUnpinQueue: (queueId: string) => dispatch(unpinQueue(queueId))
  };
};

interface IQueuesListItemComponentProps {
  id: string;
  isActive?: boolean;
  onClick?: () => void;
  onDetailsToggle?: () => void;
}

interface IQueuesListItemProps
  extends IPropsFromState,
    IPropsFromDispatch,
    IQueuesListItemComponentProps,
    InfiniteItemComponentProps {}

interface IQueuesListItemState {
  visible: boolean;
}

export default connect<IPropsFromState, IPropsFromDispatch>(
  mapStateToProps,
  mapDispatchToProps
)(QueueItem);
