import { User } from "compass.js";
import * as actionTypes from "src/store/actions/actionTypes";
import { IRootState } from ".";

export enum ContactType {
  compass = "compass",
  addressBook = "addressBook"
}

export interface IContact {
  id: string;
  type: ContactType;
  name: string;
  phones: Array<{ label: string; value: string }>;
  emails: Array<{ label: string; value: string }>;
  user?: User;
}

export interface IContactsState {
  readonly compassItems: { [key: string]: IContact };
  readonly addressBookItems: { [key: string]: IContact };
  readonly compassItemsNumbersMap: { [key: string]: IContact["id"] };
  readonly addressBookItemsNumbersMap: { [key: string]: IContact["id"] };
  readonly pinned: string[];
  readonly addressBookContactsLoaded: boolean;
  readonly addressBookContactsIsLoading: boolean;
}

const initialState: IContactsState = {
  compassItems: {},
  addressBookItems: {},
  compassItemsNumbersMap: {},
  addressBookItemsNumbersMap: {},
  pinned: [],
  addressBookContactsLoaded: false,
  addressBookContactsIsLoading: false
};

const reducer = (
  state: IContactsState = initialState,
  action: any
): IContactsState => {
  switch (action.type) {
    case actionTypes.CONTACTS_COMPASS_UPDATE:
      return {
        ...state,
        compassItems: action.payload.items,
        compassItemsNumbersMap: action.payload.numbersMap
      };
    case actionTypes.CONTACTS_ADDRESS_BOOK_UPDATE:
      return {
        ...state,
        addressBookItems: action.payload.items,
        addressBookItemsNumbersMap: action.payload.numbersMap
      };
    case actionTypes.CONTACTS_ADDRESS_BOOK_SET_LOADED:
      return {
        ...state,
        addressBookContactsLoaded: action.payload
      };
    case actionTypes.CONTACTS_ADDRESS_BOOK_LOADING_START:
      return {
        ...state,
        addressBookContactsIsLoading: true
      };
    case actionTypes.CONTACTS_ADDRESS_BOOK_LOADING_END:
      return {
        ...state,
        addressBookContactsIsLoading: false
      };
    case actionTypes.CONTACTS_COMPASS_UPDATE_PINNED:
      return {
        ...state,
        pinned: action.payload
      };
    default:
      return state;
  }
};

export const selectAllContacts = ({
  contacts: { compassItems, addressBookItems }
}: IRootState) => ({
  ...compassItems,
  ...addressBookItems
});

export const selectAllContactsNumbersMap = ({
  contacts: { compassItemsNumbersMap, addressBookItemsNumbersMap }
}: IRootState) => ({
  ...compassItemsNumbersMap,
  ...addressBookItemsNumbersMap
});

export default reducer;
