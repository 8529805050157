import * as React from "react";
import CallItem from "./CallItem/CallItem";
import "./Calls.scss";
import { IRootState } from "src/store/reducers";
import { connect } from "react-redux";
import { Call, User } from "compass.js";
import { HorizontalScroll } from "src/hoc/HorizontalScroll/HorizontalScroll";
import Auxi from "../../hoc/Auxi/Auxi";
import CallNotifications from "./CallNotifications/CallNotifications";
import TransferModal from "src/components/TransferModal/TransferModal";
import FlipMove from "react-flip-move";
import { OnboardingStepId } from "src/utils/OnboardingStep";
import { trackEvent, TrackCategory, TrackAction } from "src/utils/track";
import { shouldShowCallForUser } from "src/utils/call";
import { sortBy, sortNumbersComparator } from "src/utils";
import { wrapOnboarding } from "src/utils/onboarding";
import Modal from "src/components/UI/Modal/Modal";
import { resetDesiredTransferCall } from "src/store/actions/calls";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import AttendedTransferModal from "../AttendedTransferModal/AttendedTransferModal";

class Calls extends React.Component<ICallsProps> {
  state = {
    isTransferringModalOpen: false
  };

  componentDidUpdate(prevProps: ICallsProps) {
    if (prevProps.calls.length !== this.props.calls.length) {
      this.trackCallsNumber();
    }
  }

  componentDidMount() {
    this.trackCallsNumber();
  }

  render() {
    const callsItemsCssClasses = ["calls__items"];
    if (!this.props.calls || !this.props.calls.length) {
      callsItemsCssClasses.push("calls__items--no-calls");
    }
    const $calls = (
      <div className="calls">
        <div className="calls__no-calls">
          <p>You currently have no calls</p>
        </div>
        <HorizontalScroll>
          <FlipMove
            enterAnimation="none"
            leaveAnimation="none"
            className={callsItemsCssClasses.join(" ")}
          >
            {this.props.calls.map(call => {
              return <CallItem key={call.id} callId={call.id} />;
            })}
          </FlipMove>
        </HorizontalScroll>
      </div>
    );
    const showTransferModal =
      !!this.props.desiredTransferCallId &&
      !this.props.attendedTransferConsultationCallId;
    const showAttendedTransferModal =
      !!this.props.desiredTransferCallId &&
      !!this.props.attendedTransferConsultationCallId;
    return (
      <Auxi>
        {wrapOnboarding($calls, OnboardingStepId.sectionCalls)}
        <CallNotifications />
        <Modal
          isOpen={showTransferModal}
          // NOTE: #678
          disableAnimation={this.props.onboardingMode}
          showCloseBtn={false}
          disableOverlayClose={this.props.onboardingMode}
          onRequestClose={this.props.onResetDesiredTransferCall}
          className="transfer-modal"
        >
          <TransferModal />
        </Modal>
        {showAttendedTransferModal && (
          <Modal
            isOpen={showAttendedTransferModal}
            showCloseBtn={false}
            showMinimizeBtn={true}
            disableAnimation={true}
            title={`Transfer ${this.props.attendedTransferMainCallTitle}`}
          >
            <AttendedTransferModal
              mainCallId={this.props.desiredTransferCallId!}
              consultationCallId={
                this.props.attendedTransferConsultationCallId!
              }
            />
          </Modal>
        )}
      </Auxi>
    );
  }

  private trackCallsNumber() {
    if (this.props.calls.length) {
      trackEvent(
        TrackCategory.calls,
        TrackAction.callsNumber,
        undefined,
        this.props.calls.length.toString()
      );
    }
  }
}

interface ICallsProps extends IPropsFromState, IPropsFromDispatch {}

interface IPropsFromState {
  calls: Call[];
  desiredTransferCallId?: string;
  attendedTransferConsultationCallId?: string;
  attendedTransferMainCallTitle?: string;
  onboardingMode: boolean;
}

interface IPropsFromDispatch {
  onResetDesiredTransferCall: () => void;
}

const mapStateToProps = (state: IRootState): IPropsFromState => {
  const calls = state.calls.items.filter(item =>
    shouldShowCallForUser(item, state.auth.user as User)
  );
  sortBy(
    calls,
    item => {
      return state.calls.callsMetadata[item.id].created;
    },
    sortNumbersComparator
  );
  let attendedTransferConsultationCallId: string | undefined;
  let attendedTransferMainCallTitle: string | undefined;
  const {
    calls: { callsMetadata, desiredTransferCallId }
  } = state;
  if (desiredTransferCallId) {
    const desiredTransferCallMetadata = callsMetadata[desiredTransferCallId];
    if (
      desiredTransferCallMetadata &&
      desiredTransferCallMetadata.attendedTransferConsultationLegId
    ) {
      attendedTransferConsultationCallId =
        desiredTransferCallMetadata.attendedTransferConsultationLegId;
      attendedTransferMainCallTitle =
        desiredTransferCallMetadata.lastConnectedTitle;
    }
  }
  return {
    calls,
    desiredTransferCallId,
    attendedTransferConsultationCallId,
    attendedTransferMainCallTitle,
    onboardingMode: state.auth.onboardingMode
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IRootState, void, AnyAction>
): IPropsFromDispatch => {
  return {
    onResetDesiredTransferCall: () => dispatch(resetDesiredTransferCall())
  };
};

export default connect<IPropsFromState, IPropsFromDispatch>(
  mapStateToProps,
  mapDispatchToProps
)(Calls);
