import * as React from "react";
import * as ReactDOM from "react-dom";
import App from "./App";
import "./theme/reset.scss";
import "./theme/theme.scss";
import { Provider } from "react-redux";
import { store } from "./store";
import { initTracking } from "./utils/track";

initTracking();

console.log(
  `Compass Bridge\nVersion: ${process.env.REACT_APP_BUILD_SHA}`
);

const app = (
  <Provider store={store}>
    <App />
  </Provider>
);

ReactDOM.render(app, document.getElementById("app") as HTMLElement);
