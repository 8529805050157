import { compassDebouncePipe } from "./../../utils/compassDataMiddleware";
import { wrapAddressBookError, handleError } from "./../../utils/errorHandler";
import { TrackAction, TrackCategory, trackEvent } from "./../../utils/track";
import { AnyAction } from "redux";
import { IRootState } from "src/store/reducers/index";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import * as actionTypes from "./actionTypes";
import { userStorage } from "src/utils/userStorage";
import { getMacOsContacts } from "src/utils/contacts.macos";
import { getWindowsContacts } from "src/utils/contacts.windows";
import { IContact } from "src/store/reducers/contacts";
import { compassUsersToContacts } from "src/utils/contact";
import { store } from "src/store";
import { isElectron } from "src/utils";
import { createRuntimeError } from "src/utils/errorHandler";
import { compassDataMiddleware } from "src/utils/compassDataMiddleware";

const PIN_STORAGE_KEY = "pinnedContacts";
const ADDRESS_BOOK_CONTACTS_KEY = "addressBookContacts:v1";
const ADDRESS_BOOK_TIMEOUT_TIME = 600000;

const trackContactsNumber = (n: string) => {
  trackEvent(TrackCategory.contacts, TrackAction.contactsNumber, undefined, n);
};

export const setupContacts = (
  dispatch: ThunkDispatch<IRootState, void, AnyAction>
) => {
  dispatch(loadPinnedContacts()).then(() => {
    let track = true;
    dispatch(addressBookSetLoaded(false));
    compassDataMiddleware.users$
      .pipe(compassDebouncePipe())
      .subscribe(async users => {
        const compassContacts = compassUsersToContacts(users);
        let addressBookContacts = {};
        dispatch(
          updateCompassContacts(
            compassContacts.items,
            compassContacts.numbersMap
          )
        );
        if (
          !store.getState().contacts.addressBookContactsLoaded &&
          !store.getState().contacts.addressBookContactsIsLoading
        ) {
          try {
            addressBookContacts = await wrapAddressBookError(
              dispatch(syncAddressBookContacts())
            );
          } catch (error) {
            handleError(error);
          }
        }

        if (track) {
          trackContactsNumber(
            (
              Object.keys(compassContacts.items).length +
              Object.keys(addressBookContacts).length
            ).toString()
          );
          track = false;
        }
      });
  });
};

export const loadAddressBookContacts = (
  forceReload?: boolean
): Promise<{
  [key: string]: IContact;
}> => {
  return new Promise((resolve, reject) => {
    if (!isElectron()) {
      return resolve({});
    }
    if (!forceReload) {
      try {
        // TODO: better contacts caching?
        const cachedContactsStr = localStorage.getItem(
          ADDRESS_BOOK_CONTACTS_KEY
        );
        if (cachedContactsStr) {
          return resolve(JSON.parse(cachedContactsStr));
        }
      } catch (error) {
        console.error(error);
      }
    }
    let getContactsFunc: () => Promise<{
      [key: string]: IContact;
    }> = () => Promise.resolve({});
    switch (window.remote.process.platform) {
      case "darwin":
        getContactsFunc = getMacOsContacts;
        break;
      case "win32":
        getContactsFunc = getWindowsContacts;
        break;
    }
    let loadTimeout: NodeJS.Timer | null = setTimeout(() => {
      resolve({});
      loadTimeout = null;
    }, ADDRESS_BOOK_TIMEOUT_TIME);
    getContactsFunc().then(contacts => {
      if (!loadTimeout) {
        return;
      }
      clearTimeout(loadTimeout);
      // NOTE: Cache only if there is some contacts
      if (contacts && Object.keys(contacts).length > 0) {
        localStorage.setItem(
          ADDRESS_BOOK_CONTACTS_KEY,
          JSON.stringify(contacts)
        );
      }
      resolve(contacts);
    }, reject);
  });
};

export const syncAddressBookContacts = (
  force: boolean = false
): ThunkAction<
  Promise<{ [key: string]: IContact }>,
  IRootState,
  void,
  AnyAction
> => {
  return async (dispatch, getState, extraParams) => {
    if (!isElectron()) {
      return {};
    }
    dispatch(addressBookLoadingStart());
    let addressBookContacts: {
      [key: string]: IContact;
    } = {};
    try {
      addressBookContacts = await loadAddressBookContacts(force);
      if (!getState().auth.onboardingMode) {
        const addressBookNumbersMap: { [key: string]: IContact["id"] } = {};
        Object.values(addressBookContacts).forEach(contact => {
          contact.phones.forEach(phone => {
            addressBookNumbersMap[phone.value.replace(/\D/g, "")] = contact.id;
          });
        });
        dispatch(
          updateAddressBookContacts(addressBookContacts, addressBookNumbersMap)
        );
      }
    } catch (error) {
      throw new Error(error);
    } finally {
      dispatch(addressBookLoadingEnd());
    }
    return addressBookContacts;
  };
};

export const updateCompassContacts = (
  items: {
    [key: string]: IContact;
  },
  numbersMap: {
    [key: string]: IContact["id"];
  }
): {
  type: string;
  payload: {
    items: {
      [key: string]: IContact;
    };
    numbersMap: { [key: string]: IContact["id"] };
  };
} => {
  return {
    type: actionTypes.CONTACTS_COMPASS_UPDATE,
    payload: {
      items,
      numbersMap
    }
  };
};

export const updateAddressBookContacts = (
  items: {
    [key: string]: IContact;
  },
  numbersMap: {
    [key: string]: IContact["id"];
  }
): {
  type: string;
  payload: {
    items: {
      [key: string]: IContact;
    };
    numbersMap: { [key: string]: IContact["id"] };
  };
} => {
  return {
    type: actionTypes.CONTACTS_ADDRESS_BOOK_UPDATE,
    payload: {
      items,
      numbersMap
    }
  };
};

export const addressBookSetLoaded = (
  loaded: boolean
): { type: string; payload: boolean } => {
  return {
    type: actionTypes.CONTACTS_ADDRESS_BOOK_SET_LOADED,
    payload: loaded
  };
};

export const addressBookLoadingStart = (): { type: string } => {
  return {
    type: actionTypes.CONTACTS_ADDRESS_BOOK_LOADING_START
  };
};

export const addressBookLoadingEnd = (): { type: string } => {
  return {
    type: actionTypes.CONTACTS_ADDRESS_BOOK_LOADING_END
  };
};

export const updatePinnedContacts = (
  contacts: string[]
): { type: string; payload: string[] } => {
  return {
    type: actionTypes.CONTACTS_COMPASS_UPDATE_PINNED,
    payload: contacts
  };
};

export const loadPinnedContacts = (): ThunkAction<
  Promise<any>,
  IRootState,
  void,
  AnyAction
> => {
  return async (dispatch, getState, extraParams) => {
    try {
      const pinnedContacts = (await userStorage.getItem(
        PIN_STORAGE_KEY
      )) as string[];
      dispatch(updatePinnedContacts(pinnedContacts || []));
      return pinnedContacts;
    } catch (error) {
      throw createRuntimeError(error);
    }
  };
};

export const pinContact = (
  contactId: string
): ThunkAction<Promise<any>, IRootState, void, AnyAction> => {
  return async (dispatch, getState, extraParams) => {
    const pinnedContacts = (getState().contacts.pinned || []).slice();
    pinnedContacts.push(contactId);
    try {
      await userStorage.setItem(PIN_STORAGE_KEY, pinnedContacts);
      dispatch(updatePinnedContacts(pinnedContacts));
      return pinnedContacts;
    } catch (error) {
      throw createRuntimeError(error);
    }
  };
};

export const unpinContact = (
  contactId: string
): ThunkAction<Promise<any>, IRootState, void, AnyAction> => {
  return async (dispatch, getState, extraParams) => {
    const pinnedContacts = (getState().contacts.pinned || []).filter(
      item => item !== contactId
    );
    try {
      await userStorage.setItem(PIN_STORAGE_KEY, pinnedContacts);
      dispatch(updatePinnedContacts(pinnedContacts));
      return pinnedContacts;
    } catch (error) {
      throw createRuntimeError(error);
    }
  };
};
