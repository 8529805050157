import * as React from "react";
import "./Button.scss";
import { TrackCategory, trackEvent, TrackAction } from "src/utils/track";
import { OnboardingStep, OnboardingStepId } from "src/utils/OnboardingStep";
import { IRootState } from "src/store/reducers";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { onboardingNext } from "src/store/actions/auth";
import { wrapOnboarding } from "src/utils/onboarding";
import { notificationShow } from "src/store/actions";
import { UserFeature } from "src/store/reducers/auth";
import { Tooltip } from "../Tooltip/Tooltip";
import { canAuthenticatedUserUseFeature } from "src/utils/user";
import { BridgeColor } from "src/utils/consts";
import { TippyProps } from "@tippy.js/react";

const CUSTOM_BOOLEAN_PROPS = ["icononly", "small", "nohover"];

class ButtonComponent extends React.Component<IConnectedButtonProps> {
  render() {
    const $btn = (
      <button ref={this.props.buttonRef} {...this.getButtonElementProps()}>
        {this.props.children}
      </button>
    );
    if (this.props.onboardingMode) {
      return wrapOnboarding(
        $btn,
        this.props.onboardingStep,
        this.props.onboardingTooltipProps
      );
    }
    let tooltipMessage = this.props.tooltip;
    if (!this.isAllowed()) {
      tooltipMessage = "This is a premium feature";
    }
    if (tooltipMessage) {
      return (
        <Tooltip content={tooltipMessage} enabled={true}>
          {$btn}
        </Tooltip>
      );
    }
    return $btn;
  }

  private getButtonElementProps(): React.ButtonHTMLAttributes<
    HTMLButtonElement
  > {
    const cssClasses = ["button"];
    if (this.props.className) {
      cssClasses.push(this.props.className);
    }
    const buttonProps = {
      ...this.props,
      color: this.props.color || BridgeColor.prim500,
      fill: this.props.fill || "solid",
      className: cssClasses.join(" ")
    };

    // NOTE: remove component's all non-button props
    delete buttonProps.track;
    delete buttonProps.onboardingMode;
    delete buttonProps.buttonRef;
    delete buttonProps.onOnboardingNext;
    delete buttonProps.onboardingStep;
    delete buttonProps.onboardingDoNotBlock;
    delete buttonProps.onboardingCurrentStep;
    delete buttonProps.onboardingDoNotTriggerNext;
    delete buttonProps.onNotificationShow;
    delete buttonProps.tooltip;
    delete buttonProps.nohover;
    delete buttonProps.requiredFeature;

    // NOTE: redux adds this prop automatically
    delete (buttonProps as any).dispatch;

    // NOTE: convert custom props to string, react-dom
    // doesn't support additional properties as boolean type
    CUSTOM_BOOLEAN_PROPS.forEach(prop => {
      if (
        prop in buttonProps &&
        buttonProps[prop] !== null &&
        buttonProps[prop] !== undefined
      ) {
        buttonProps[prop] = buttonProps[prop].toString();
      }
    });

    // NOTE: make sure button is enabled for non available premium feature
    // to show info notification on click
    if (!this.props.onboardingMode && !this.isAllowed()) {
      buttonProps.disabled = false;
      buttonProps.className = `${
        buttonProps.className
      } button--feature-unavailable`;
    }

    if (!this.props.nohover && !this.props.disabled) {
      buttonProps.className = `${buttonProps.className} button--hover-enabled`;
    }

    const onClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (this.props.onboardingMode) {
        if (
          this.props.onboardingCurrentStep &&
          this.props.onboardingStep === this.props.onboardingCurrentStep.id &&
          !this.props.onboardingDoNotTriggerNext
        ) {
          return this.props.onOnboardingNext();
        }
        if (!this.props.onboardingDoNotBlock) {
          return;
        }
      }
      if (!this.isAllowed()) {
        return this.props.onNotificationShow({
          uid: "feature-unavailable",
          level: "info",
          message:
            "Please upgrade to Compass Bridge Premium to have full access.",
          dismissable: true,
          autoDismiss: 3000
        });
      }
      if (this.props.track) {
        trackEvent.apply(null, this.props.track);
      }
      if (buttonProps.onClick) {
        buttonProps.onClick(e);
      }
    };
    return { ...buttonProps, onClick };
  }

  private isAllowed() {
    if (!this.props.requiredFeature || this.props.onboardingMode) {
      return true;
    }
    return canAuthenticatedUserUseFeature(this.props.requiredFeature);
  }
}

export interface IButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  icononly?: boolean;
  fill?: "clear" | "fade" | "solid";
  requiredFeature?: UserFeature;
  small?: boolean;
  color?: BridgeColor;
  track?: [TrackCategory, TrackAction];
  onboardingStep?: OnboardingStepId;
  onboardingDoNotBlock?: boolean;
  onboardingDoNotTriggerNext?: boolean;
  onboardingTooltipProps?: Partial<TippyProps>;
  tooltip?: string;
  nohover?: boolean;
}

export interface IConnectedButtonProps
  extends IButtonProps,
    IPropsFromState,
    IPropsFromDispatch {
  buttonRef?: React.Ref<HTMLButtonElement>;
}

interface IPropsFromState {
  onboardingMode: boolean;
  onboardingCurrentStep?: OnboardingStep;
}

interface IPropsFromDispatch {
  onOnboardingNext: () => void;
  onNotificationShow: typeof notificationShow;
}

const mapStateToProps = (state: IRootState): IPropsFromState => {
  return {
    onboardingMode: state.auth.onboardingMode,
    onboardingCurrentStep: state.auth.onboardingStep
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IRootState, void, AnyAction>
): IPropsFromDispatch => {
  return {
    onOnboardingNext: () => dispatch(onboardingNext()),
    onNotificationShow: notification => dispatch(notificationShow(notification))
  };
};

const ConnectedButton = connect<IPropsFromState, IPropsFromDispatch>(
  mapStateToProps,
  mapDispatchToProps
)(ButtonComponent);

export default React.forwardRef(
  (props: IButtonProps, ref?: React.Ref<HTMLButtonElement>) => (
    <ConnectedButton buttonRef={ref} {...props} />
  )
);
