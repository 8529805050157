import * as React from "react";
import "./NavbarWrapUpBtn.scss";
import { connect } from "react-redux";
import { IRootState } from "src/store/reducers";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { ReceiveCalls, User } from "compass.js";
import { setUserStatus } from "src/store/actions/auth";
import Button from "src/components/UI/Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/pro-solid-svg-icons/faClock";
import { BridgeColor } from "src/utils/consts";
import { faTimes } from "@fortawesome/pro-solid-svg-icons/faTimes";
import { notificationShow } from "src/store/actions";

class NavbarWrapUpBtn extends React.Component<INavbarWrapUpBtnProps> {
  wrapUpManuallyDisabledFlag: boolean = false;

  componentDidMount() {
    if (this.props.isWrapUpEnabled) {
      this.showWrapUpInitiatedNotification();
    }
  }

  componentDidUpdate(prevProps: INavbarWrapUpBtnProps) {
    if (
      this.props.isWrapUpEnabled === prevProps.isWrapUpEnabled &&
      this.props.wrapUpEndTime === prevProps.wrapUpEndTime
    ) {
      return;
    }
    if (this.props.isWrapUpEnabled) {
      this.showWrapUpInitiatedNotification();
    } else {
      if (this.wrapUpManuallyDisabledFlag) {
        this.wrapUpManuallyDisabledFlag = false;
        return;
      }
      this.showInfoNotification("wrap-up-ended", "Wrap-up time has ended.");
    }
  }

  render() {
    if (!this.props.isWrapUpEnabled) {
      return null;
    }

    let wrapUpTime: number | null = null;
    if (this.props.wrapUpEndTime) {
      wrapUpTime = this.props.wrapUpEndTime - Math.round(+new Date() / 1000);
    }

    return (
      <div className="navbar-wrap-up-btn">
        <Button
          disabled={this.props.queuesIsLoading}
          color={BridgeColor.gs800}
          onClick={this.endWrapUp}
          nohover={true}
          tooltip={"Break out of Wrap-up time"}
        >
          {wrapUpTime ? (
            <div
              // NOTE: to re-run animation if wrap up end time only changed
              key={
                this.props.wrapUpEndTime
                  ? this.props.wrapUpEndTime.toString()
                  : undefined
              }
              className="navbar-wrap-up-btn__progress"
              style={{
                animationDuration: `${wrapUpTime}s`
              }}
            />
          ) : null}
          <div className="navbar-wrap-up-btn__content">
            {/* NOTE: CSS is taking care to show only one icon at the time */}
            <span className="navbar-wrap-up-btn__icon navbar-wrap-up-btn__icon--clock">
              <FontAwesomeIcon icon={faClock} />
            </span>
            <span className="navbar-wrap-up-btn__icon navbar-wrap-up-btn__icon--close">
              <FontAwesomeIcon icon={faTimes} />
            </span>
            Wrap-up
          </div>
        </Button>
      </div>
    );
  }

  private showInfoNotification(uid: string, message: string) {
    this.props.onNotificationShow({
      uid,
      level: "info",
      autoDismiss: 3000,
      dismissable: true,
      message
    });
  }

  private showWrapUpInitiatedNotification() {
    this.showInfoNotification("wrap-up-enabled", "Wrap-up time has started.");
  }

  private endWrapUp = () => {
    this.wrapUpManuallyDisabledFlag = true;
    this.props
      .onSetUserStatus(ReceiveCalls.all)
      .then(() => {
        this.showInfoNotification(
          "wrap-up-disabled",
          "You broke out of Wrap-up time."
        );
      })
      .catch(() => {
        this.wrapUpManuallyDisabledFlag = false;
      });
  };
}

interface IPropsFromState {
  wrapUpEndTime?: number | null;
  isWrapUpEnabled?: boolean;
  queuesIsLoading: boolean;
}

interface IPropsFromDispatch {
  onSetUserStatus: (
    receiveCalls: ReceiveCalls,
    displayStatus?: string
  ) => Promise<any>;
  onNotificationShow: typeof notificationShow;
}

interface INavbarWrapUpBtnProps extends IPropsFromState, IPropsFromDispatch {}

const mapStateToProps = (state: IRootState): IPropsFromState => {
  const userStatus = (state.auth.user as User).status;
  return {
    wrapUpEndTime: userStatus.wrapupState
      ? userStatus.wrapupState.endTime
      : undefined,
    isWrapUpEnabled: !!userStatus.wrapupState,
    queuesIsLoading: state.queues.actionsInProgress > 0
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IRootState, void, AnyAction>
): IPropsFromDispatch => {
  return {
    onSetUserStatus: (receiveCalls: ReceiveCalls, displayStatus?: string) =>
      dispatch(setUserStatus(receiveCalls, displayStatus)),
    onNotificationShow: notification => dispatch(notificationShow(notification))
  };
};

export default connect<IPropsFromState, IPropsFromDispatch>(
  mapStateToProps,
  mapDispatchToProps
)(NavbarWrapUpBtn);
